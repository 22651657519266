<williams-ui-platform-stack-layout orientation="vertical" [gap]="'0'">
    <williams-ui-platform-stack-layout class="w-px-8 w-py-4 w-color-compTxt-1">
        <h3 class="w-color-compTxt-1 w-m-0">Downloadable Files</h3>
    </williams-ui-platform-stack-layout>
    <williams-ui-platform-stack-layout orientation="vertical" class="w-px-8 w-py-4 w-bg-white-pt9">
        <williams-ui-platform-stack-layout orientation="vertical">
            <span class="w-py-4 w-color-compTxt-1">You can view or download the following files.</span>
            <a href={{text.url}}  target="_blank" class="w-color-info-pt9 link" *ngFor="let text of data">
                {{text.name}}
            </a>
        </williams-ui-platform-stack-layout>
        <williams-ui-platform-stack-layout orientation="vertical">
            <h6 class="w-pt-8 w-h1-bold w-color-compTxt-1">Current Forms.</h6>
            <a href={{text.url}}  target="_blank" class="w-color-info-pt9 link" *ngFor="let text of downloadableForm">
                {{text.name}}
            </a>
        </williams-ui-platform-stack-layout>
    </williams-ui-platform-stack-layout>
</williams-ui-platform-stack-layout>