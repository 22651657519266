<kendo-textbox
  class="mt-4"
  type="text"
  [formControl]="control"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [clearButton]="true"
  [showErrorIcon]="showErrorIcon"
  [maxlength]="maxlength"
  [rounded]="rounded"
  [disabled]="disabled"
  [size]="size"
  [fillMode]="fillMode"
  (valueChange)="onValueChange($event)"
  [ngStyle]="{backgroundColor:'inherit'}"
>
<ng-template kendoTextBoxPrefixTemplate *ngIf="textInputTemplates?.textInputPrefixTemplate">
  <ng-container *ngTemplateOutlet="textInputTemplates.textInputPrefixTemplate"></ng-container>
</ng-template>

<ng-template kendoTextBoxSuffixTemplate *ngIf="textInputTemplates?.textInputSuffixTemplate">
    <ng-container *ngTemplateOutlet="textInputTemplates?.textInputSuffixTemplate"></ng-container>
</ng-template>
</kendo-textbox>
<span *ngIf="showCharacterCount" class="d-flex flex-justify-end counter">{{ counter }}</span>