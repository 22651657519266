<williams-ui-platform-stack-layout orientation="vertical" [gap]="'0'">
    <williams-ui-platform-stack-layout class="w-px-8 w-py-4 w-color-compTxt-1">
        <h3 class="w-m-0">Site Map</h3>
    </williams-ui-platform-stack-layout>
    <williams-ui-platform-stack-layout class="w-px-8 w-py-4 w-bg-white-pt9">
        <div #linkContainer class="d-flex flex-column" style="gap:16px" >
            <div class="d-flex flex-gap-16" *ngFor="let row of layoutRows">
                <williams-ui-platform-stack-layout [class]="i < row.length -1 ? 'w-flex-basis-7 border' : 'w-flex-basis'" orientation="vertical" *ngFor="let data of row; index as i">
                <span *ngIf="!data.siteMapList;else list;">
                    <h6 *ngIf="data.link=='/site-map';else otherlink" class="w-m-0 w-h1-bold w-color-compTxt-1" (click)="navigateUrl(data.link)">{{data.menu}}</h6>
                    <ng-template #otherlink>
                        <a [routerLink]="data.link" href="{{data.link}}" class="w-m-0 w-base-text-large-bold w-color-info-pt9" (click)="navigateUrl(data.link)">{{data.menu}}</a>
                    </ng-template>
                </span>
                    <ng-template #list>
                        <h6 class="w-m-0 w-h1-bold w-color-compTxt-1">{{data.menu}}</h6>
                        <p class="d-flex flex-column">
                            <a href={{linkName.link}} class="w-color-info-pt9" rel="noreferrer noopener" [target]="linkName.link.startsWith('pdf-viewer') ? '_blank' : ''"
                               *ngFor="let linkName of data.siteMapList">{{linkName.menu}}
                            </a>
                        </p>
                    </ng-template>
                </williams-ui-platform-stack-layout>
            </div>
        </div>
    </williams-ui-platform-stack-layout>
</williams-ui-platform-stack-layout>
