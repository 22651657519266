import { Component } from "@angular/core";
import { DialogContentBase } from "@progress/kendo-angular-dialog";
import {
  DialogSettings as KendoDialogSettings,
} from "@progress/kendo-angular-dialog";

export interface ConfirmationDialogAction {
  text: string;
  value: any;
  cssClass?: string;
  icon?: string;
  imageUrl?: string;
}

export interface ConfirmDialogSettings extends Omit<KendoDialogSettings, 'actions' | 'content'> {
  content: string;
  confirmAction: ConfirmationDialogAction,
  cancelAction?: ConfirmationDialogAction,
}

@Component({
  selector: "williams-ui-platform-confirmation-dialog",
  templateUrl: "./confirm-dialog.component.html",
})
export class ConfirmDialogComponent extends DialogContentBase {
  settings!: ConfirmDialogSettings;

  onActionButtonClick(action: ConfirmationDialogAction) {
    this.dialog.close(action);
  }
}
