<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<div
  class="extended-grid-wrapper"
  [ngClass]="{ 'is-loading': loading }"
  kendoTooltip
  showOn="hover"
  [tooltipTemplate]="template"
  filter=".k-grid td"
  (mouseover)="showTooltip($event)">
  <kendo-grid
    #grid
    [autoSize]="autoSize"
    [columnMenu]="columnMenu"
    [data]="gridView"
    [detailRowHeight]="detailRowHeight"
    [filterable]="filterable"
    [groupable]="isGroupable"
    [height]="height"
    [hideHeader]="hideHeader"
    [loading]="loading"
    [navigable]="navigable"
    [pageable]="pageable"
    [pageSize]="pageSize"
    [reorderable]="reorderable"
    [resizable]="resizable"
    [rowClass]="rowClass"
    [rowHeight]="rowHeight"
    [scrollable]="scrollable"
    [selectable]="selectable"
    [sortable]="sortable"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [filter]="gridState.filter"
    [group]="gridState.group"
    kendoGridSelectBy="rowId"
    [(selectedKeys)]="selectedRows"
    (cellClick)="onCellClick($event)"
    (dataStateChange)="onDataStateChange($event)"
    (selectionChange)="onSelectionChange()"
  >
    <kendo-grid-checkbox-column
      [style]="{ 'text-align': 'center' }"
      [headerStyle]="{ 'text-align': 'center' }"
      [autoSize]="checkboxColumnConfiguration.autoSize"
      [columnMenu]="checkboxColumnConfiguration.columnMenu"
      [class]="checkboxColumnConfiguration.cssClass"
      [footerClass]="checkboxColumnConfiguration.footerClass"
      [headerClass]="checkboxColumnConfiguration.headerClass"
      [hidden]="checkboxColumnConfiguration.hidden"
      [includeInChooser]="checkboxColumnConfiguration.includeInChooser"
      [lockable]="checkboxColumnConfiguration.lockable"
      [locked]="checkboxColumnConfiguration.locked"
      [reorderable]="checkboxColumnConfiguration.reorderable"
      [resizable]="checkboxColumnConfiguration.resizable"
      [stickable]="checkboxColumnConfiguration.stickable"
      [sticky]="checkboxColumnConfiguration.sticky"
      [title]="checkboxColumnConfiguration.title"
      [width]="checkboxColumnConfiguration.width"
      [showSelectAll]="checkboxColumnConfiguration.showSelectAll"
    >
    </kendo-grid-checkbox-column>
    <ng-container *ngFor="let column of columnConfigurations">
      <kendo-grid-column
        [autoSize]="column.autoSize"
        [columnMenu]="column.columnMenu"
        [class]="column.cssClass"
        [editable]="true"
        [field]="column.field"
        [filterable]="isColumnFilterable(column)"
        [footerClass]="column.footerClass"
        [groupable]="column.groupable"
        [headerClass]="column.headerClass"
        [hidden]="column.hidden"
        [includeInChooser]="column.includeInChooser"
        [lockable]="column.lockable"
        [locked]="column.locked"
        [reorderable]="column.reorderable"
        [resizable]="column.resizable"
        [sortable]="isColumnSortable(column)"
        [stickable]="column.stickable"
        [sticky]="column.sticky"
        [title]="column.title"
        [width]="column.width"
        [class]="column.cssClass"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div class="text-ellipsis" show-tooltip *ngIf="column.cellTemplate">
              <ng-container
                *ngTemplateOutlet="column.cellTemplate; context: { dataItem, rowIndex, field: column.field }"
              >
              </ng-container>
          </div>
          <williams-ui-platform-grid-cell
            *ngIf="!column.cellTemplate"
            [dataItem]="dataItem"
            [columnConfiguration]="column"
        ></williams-ui-platform-grid-cell>
        </ng-template>
  
        <ng-template
          kendoGridEditTemplate
          let-formGroup
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <ng-container *ngIf="!isCellEditable(dataItem, column)">
            <div class="text-ellipsis" show-tooltip *ngIf="column.cellTemplate">
              <ng-container
                *ngTemplateOutlet="column.cellTemplate; context: { dataItem, rowIndex, field: column.field }"
              >
              </ng-container>
            </div>
            <williams-ui-platform-grid-cell
              *ngIf="!column.cellTemplate"
              [dataItem]="dataItem"
              [columnConfiguration]="column"
            ></williams-ui-platform-grid-cell>
          </ng-container>
          <ng-container *ngIf="isCellEditable(dataItem, column)">
            <ng-container *ngIf="column.editTemplate">
              <ng-container
                *ngTemplateOutlet="column.editTemplate; context: { dataItem, rowIndex, formControlName: column.formControlName, columnConfiguration: column, formGroup }"
              >
              </ng-container>
            </ng-container>
            <williams-ui-platform-grid-cell-edit
              *ngIf="!column.editTemplate"
              [group]="formGroup"
              [columnConfiguration]="column"
            >
            </williams-ui-platform-grid-cell-edit>
          </ng-container>
        </ng-template>
  
        <ng-template kendoGridGroupFooterTemplate let-group="group" let-aggregates="aggregates" let-field="field">
          <span *ngIf="!gridTemplates?.groupFooterTemplate">
            {{ getGroupAggregateForField(aggregates, field) }}
          </span>
          <ng-container *ngIf="gridTemplates?.groupFooterTemplate">
            <ng-container
              *ngTemplateOutlet="gridTemplates.groupFooterTemplate; context: { group, aggregates, field }"
            >
            </ng-container>
          </ng-container>
        </ng-template>
  
        <ng-template
          kendoGridFooterTemplate
          let-column
          *ngIf="gridTemplates?.footerTemplate || gridState.aggregates.length"
        >
          <div *ngIf="!gridTemplates?.footerTemplate && getAggregatesForColumnFooter(column.field) as aggregateValue;" 
            class="footer-aggregate">
            {{ aggregateValue }}
          </div>
          <ng-container *ngIf="gridTemplates?.footerTemplate">
            <ng-container
              *ngTemplateOutlet="gridTemplates.footerTemplate; context: { column }"
            >
            </ng-container>
          </ng-container>
        </ng-template>
  
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column" *ngIf="gridTemplates?.filterCellTemplate">
          <ng-container
            *ngTemplateOutlet="gridTemplates.filterCellTemplate; context: { filter, column }"
          >
          </ng-container>
        </ng-template>
  
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" *ngIf="gridTemplates?.filterMenuTemplate">
          <ng-container
            *ngTemplateOutlet="gridTemplates.filterMenuTemplate; context: { filter, column }"
          >
          </ng-container>
        </ng-template>
      </kendo-grid-column>
    </ng-container>
    <kendo-grid-command-column
      [autoSize]="commandColumnConfiguration.autoSize"
      [columnMenu]="commandColumnConfiguration.columnMenu"
      [class]="commandColumnConfiguration.cssClass"
      [footerClass]="commandColumnConfiguration.footerClass"
      [headerClass]="commandColumnConfiguration.headerClass"
      [hidden]="commandColumnConfiguration.hidden"
      [includeInChooser]="commandColumnConfiguration.includeInChooser"
      [lockable]="commandColumnConfiguration.lockable"
      [locked]="commandColumnConfiguration.locked"
      [reorderable]="commandColumnConfiguration.reorderable"
      [resizable]="commandColumnConfiguration.resizable"
      [stickable]="commandColumnConfiguration.stickable"
      [sticky]="commandColumnConfiguration.sticky"
      [title]="commandColumnConfiguration.title"
      [width]="commandColumnConfiguration.width"
      [style]="{ 'text-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <ng-container *ngIf="gridTemplates?.commandColumnTemplate">
          <ng-container
            *ngTemplateOutlet="gridTemplates.commandColumnTemplate; context: { dataItem, rowIndex }"
          >
          </ng-container>
        </ng-container>
        <williams-ui-platform-dropdown-button
          *ngIf="!gridTemplates?.commandColumnTemplate"
          icon="more-vertical"
          [data]="commandColumnActionItems"
          [popupSettings]="{ align: 'right' }"
          fillMode="clear"
          [disabled]="!allowGridEditing"
          (itemClick)="handleCommandActionClick($event, dataItem)"
        >
        </williams-ui-platform-dropdown-button>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-pdf
      [allPages]="pdfOptions.allPages"
      [author]="pdfOptions.author"
      [autoPrint]="pdfOptions.autoPrint"
      [avoidLinks]="pdfOptions.avoidLinks"
      [creator]="pdfOptions.creator"
      [fileName]="pdfOptions.fileName"
      [forcePageBreak]="pdfOptions.forcePageBreak"
      [landscape]="pdfOptions.landscape"
      [paperSize]="pdfOptions.paperSize"
      [producer]="pdfOptions.producer"
      [repeatHeaders]="pdfOptions.repeatHeaders"
      [scale]="pdfOptions.scale"
      [subject]="pdfOptions.subject"
      [title]="pdfOptions.title"
      [margin]="pdfOptions.pdfMargin"
    >
      <!-- Adding columns here to tackle issue with sticky columns - (https://www.telerik.com/forums/grid-export-pdf-sticky-column-malformed) -->
      <kendo-grid-column *ngFor="let column of columnConfigurations" [title]="column.title" [field]="column.field">
      </kendo-grid-column>
      <ng-template
        kendoGridPDFTemplate
        let-pageNum="pageNum"
        let-totalPages="totalPages"
        *ngIf="gridTemplates?.pdfTemplate"
      >
        <ng-container
          *ngTemplateOutlet="gridTemplates.pdfTemplate; context: { pageNum, totalPages }"
        >
        </ng-container>
      </ng-template>
    </kendo-grid-pdf>
  
    <kendo-grid-excel
      [collapsible]="excelOptions.collapsible"
      [creator]="excelOptions.creator"
      [fileName]="excelOptions.fileName"
      [filterable]="excelOptions.filterable"
      [headerPaddingCellOptions]="excelOptions.headerPaddingCellOptions"
      [paddingCellOptions]="excelOptions.paddingCellOptions"
    >
    </kendo-grid-excel>

    <ng-template kendoGridLoadingTemplate>
      <div class="grid-loader-container">
        <williams-ui-platform-loader></williams-ui-platform-loader>
      </div>
    </ng-template>
  </kendo-grid>
  <div class="grid-footer w-p-2">
    <span *ngIf="showRowCount" class="w-small-text-bold">
      {{ rowCount + ' ' + rowCountLabel + (rowCount > 1 ? 's' : '') }}
    </span>
  </div>
</div>
