import {
  BadgeAlign as KendoBadgeAlign,
  BadgeFill as KendoBadgeFill,
  BadgePosition as KendoBadgePosition,
  BadgeRounded as KendoBadgeRounded,
  BadgeSize as KendoBadgeSize,
  BadgeThemeColor as KendoBadgeThemeColor
} from "@progress/kendo-angular-indicators";

export interface BadgeAlign extends KendoBadgeAlign {}
export type BadgeFill = KendoBadgeFill;
export type BadgePosition = KendoBadgePosition;
export type BadgeRounded = KendoBadgeRounded;
export type BadgeSize = KendoBadgeSize;
export type BadgeThemeColor = KendoBadgeThemeColor;
