import { Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ItemChangedEvent, ScrollViewComponent as KendoScrollViewComponent } from '@progress/kendo-angular-scrollview';
import { CarouselTemplateDirective } from './directive/carousel-template.directive';
import { ScrollViewPagerOverlay } from './model';

@Component({
  selector: 'williams-ui-platform-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @ViewChild('scrollViewInstance') scrollViewInstance!: KendoScrollViewComponent;
  @ContentChild(
    forwardRef(() => CarouselTemplateDirective)
  ) 
  carouselTemplateDirective!: any;
  /**
   * Represents the current item index
   */
  @Input() activeIndex!:number;

  /**
   * Enables or disables the built-in animations. 
   * By default, animate is set to true and animations are enabled.
   */
  @Input() animate:boolean=true;

  /**
   * Enables or disables the built-in pager. 
   * By default, pageable is set to false and paging is disabled.
   */
  @Input() pageable:boolean= false;

  /**
   * Enables or disables the built-in navigation arrows.
   * By default, arrows is set to false and arrows are disabled.
   */
  @Input() arrows:boolean=true;

  /**
   * Provides the data source for the ScrollView
   */
  @Input() data: Array<any> = [];
  /**
   * Sets pagerOverlay to one of three possible values: dark, light or none. 
   * By default, the pager overlay is set to none.
   */
  @Input() pagerOverlay: ScrollViewPagerOverlay="none";


  /**
   * Enables or disables the endless scrolling mode in which the data source items are endlessly looped. 
   * By default, endless is set to false and the endless scrolling mode is disabled.
   */
  @Input() endless: boolean= false;

  /**
   * Sets the height of the ScrollView. 
   * By default, the height is not set and you have to explicitly define the height value.
   */
  @Input() height:string =  "500px";

  /**
   *  Sets the width of the ScrollView. 
   *  By default, the width is not set and you have to explicitly define the width value.
   */
  @Input() width:string = "100%";

 
  /**
   * Fires after the activeIndex has changed.
   * Allows for two-way binding of the activeIndex property.
   */
  @Output() activeIndexChange: EventEmitter<number>= new EventEmitter<number>();

  /**
   * Fires after the current item is changed.
   */
  @Output() itemChanged: EventEmitter<ItemChangedEvent> = new EventEmitter<ItemChangedEvent>();
  
  constructor() { }

  ngOnInit(): void {
  }

  public next() {
    this.scrollViewInstance.next();
  }

  public prev() {
    this.scrollViewInstance.prev();
  }

  public activeIndexChangedHandler(index:number){
    this.activeIndexChange.emit(index);
  }

  public itemChangedHandler(itemChangedEvent: ItemChangedEvent){
    this.itemChanged.emit(itemChangedEvent);
  }

}
