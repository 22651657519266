import { Component, Input, OnInit, Optional } from "@angular/core";
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, FormGroup } from "@angular/forms";
@Component({
    template:''
})
export class BaseAccessor implements ControlValueAccessor, OnInit{
    @Input()
    formControlName:string='';
    @Input() formGroup!:FormGroup;
    control!:AbstractControl | any;
    constructor(@Optional() public controlContainer: ControlContainer) {
      
    }
    ngOnInit() {
       
      if(this.formControlName){
        console.log(this.formControlName);
         this.control = (<FormGroup>this.controlContainer.control).get(this.formControlName);
      }
  
    }
    registerOnChange(){
       
    }
    registerOnTouched(){
  
    }
    writeValue(){
  
    }
    setDisabledState(){
  
    }
}