<kendo-gridlayout [align]="align" [cols]="cols" [gap]="gap" [rows]="rows" [class]="classList">
    <kendo-gridlayout-item
      *ngFor="let item of items"
      [col]="item.col"
      [colSpan]="item.colSpan"
      [row]="item.row"
      [rowSpan]="item.rowSpan"
      [class]="item.classList"
    >
      <ng-container *ngTemplateOutlet="item.itemTemplate"></ng-container>
    </kendo-gridlayout-item>
</kendo-gridlayout>