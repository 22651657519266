<kendo-dropdownbutton
  [buttonClass]="buttonClass"
  [data]="data"
  [disabled]="disabled"
  [fillMode]="fillMode"
  [icon]="icon"
  [iconClass]="iconClass"
  [imageUrl]="imageUrl"
  [popupSettings]="popupSettings"
  [rounded]="rounded"
  [size]="size"
  [textField]="textField"
  [themeColor]="themeColor"
  (close)="onClose($event)"
  (itemClick)="onItemClick($event)"
  (blur)="onBlur()"
  (focus)="onFocus()"
  (open)="onOpen($event)"
> 
  <ng-content></ng-content>
</kendo-dropdownbutton>