<kendo-scrollview
      #scrollViewInstance="kendoScrollView"
      [(activeIndex)]="activeIndex"
      [data]="data"
      [width]="width"
      [height]="height"
      [arrows]="arrows"
      [animate]="animate"
      [endless]="endless"
      [pageable]="pageable"
      [pagerOverlay]="pagerOverlay"
      (activeIndexChange)="activeIndexChangedHandler($event)"
      (itemChanged)="itemChangedHandler($event)"
    >
      <ng-template let-item="item">
       <ng-container *ngIf="carouselTemplateDirective?.carouselTemplate">
        <ng-container   *ngTemplateOutlet="carouselTemplateDirective?.carouselTemplate;context: { $implicit: item, item }">
        </ng-container>
       </ng-container>   
      </ng-template>
    </kendo-scrollview>