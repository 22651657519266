<form [formGroup]="searchForm" *ngIf="searchForm">
  <williams-ui-platform-text-input 
   formControlName="searchTerm" 
   [placeholder]="placeholder"
   [clearButton]="clearButton" >
  <williams-ui-platform-text-input-template>
    <ng-template #textInputSuffixTemplate>
        <span class="k-input-icon k-icon k-i-search"></span>
      </ng-template>
  </williams-ui-platform-text-input-template>
  </williams-ui-platform-text-input>
</form>
