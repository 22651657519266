import { Component, EventEmitter, forwardRef, Input, Optional, Output } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropDownFillMode, DropDownRounded, DropDownSize } from '@progress/kendo-angular-dropdowns';
import { BaseAccessor } from '../base/base-accessor';


@Component({
  selector: 'williams-ui-platform-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent extends BaseAccessor  {
  @Input() placeholder = "";
  @Input() data: any[] = [];
  @Input() showErrors = false;
  @Input() readonly = false;
  @Input() showErrorMsg = false;
  @Input() textField!: string;
  @Input() value!: any;
  @Input() valueField!: any;
  @Input() fillMode: DropDownFillMode = 'solid';
  @Input() rounded: DropDownRounded = 'full';
  @Input() size: DropDownSize = 'medium';
  @Output() filterValue = new EventEmitter<any>();
  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer)
  }

  handleFilter(value: any) {
    this.filterValue.emit(value);
    }
}
