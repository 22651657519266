import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Orientation } from '@progress/kendo-angular-inputs';

export interface CustomTab {
  title:string;
  routeName:string;
  indicatorText : number;

}

@Component({
  selector: 'williams-ui-platform-tab-layout',
  templateUrl: './tab-layout.component.html',
  styleUrls: ['./tab-layout.component.scss']
})

export class TabLayoutComponent{
  @Input() relativePath!: string;
  @Input() tabs: CustomTab[] = [];
  
  @Output() tabSelect: EventEmitter<any> = new EventEmitter();

  public orientation: Orientation = "vertical";
  selected: boolean = false;

  onSelectButton(){
    this.selected = !this.selected;
    this.tabSelect.emit(this.selected);
  }
  
}
