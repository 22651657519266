import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";

@Injectable()
export class GridEditService {
  private _newlyAddedRows: Map<number, FormGroup> = new Map();
  private _updatedRows: Map<number, FormGroup> = new Map();
  private _deletedRows: any[] = [];

  private _formGroupChange: Subject<void> = new Subject();

  getNewlyAddedItems(): Map<number, FormGroup> {
    return this._newlyAddedRows;
  }

  getUpdatedItems(): Map<number, FormGroup> {
    return this._updatedRows;
  }

  getDeletedItems(): any[] {
    return this._deletedRows;
  }

  addToDeletedItems(dataItem: any): void {
    this._deletedRows.push(dataItem);
    this._emitFormGroupChange();
  }

  addToNewlyAddedItems(rowId: number, group: FormGroup): void {
    this._newlyAddedRows.set(rowId, group);
    this._emitFormGroupChange();
  }

  addToUpdatedItems(rowId: number, group: FormGroup): void {
    this._updatedRows.set(rowId, group);
    this._emitFormGroupChange();
  }

  isNewlyAddedItem(rowId: number): boolean {
    return this._newlyAddedRows.has(rowId);
  }

  isUpdatedItem(rowId: number): boolean {
    return this._updatedRows.has(rowId);
  }

  removeFromNewlyAddedItems(rowId: number): void {
    if(this._newlyAddedRows.has(rowId)) {
      this._newlyAddedRows.delete(rowId);
    }
  }

  removeFromUpdatedItems(rowId: number): void {
    if(this._updatedRows.has(rowId)) {
      this._updatedRows.delete(rowId);
    }
  }

  resetEditState(): void {
    this._newlyAddedRows = new Map();
    this._updatedRows = new Map();
    this._deletedRows = [];
  }

  getFormGroup(rowId: number): FormGroup | undefined {
    return this._newlyAddedRows.get(rowId) || this._updatedRows.get(rowId);
  }

  getRowsInEditMode(): Map<number, FormGroup> {
    return new Map([...this._newlyAddedRows, ...this._updatedRows]); 
  }

  private _emitFormGroupChange(): void {
    this._formGroupChange.next();
  }

  getFormGroupChange(): Observable<any> {
    return this._formGroupChange;
  }
}