import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import {TabStripComponent as KendoTabStripComponent} from "@progress/kendo-angular-layout";
import { TabScrollEvent } from '@progress/kendo-angular-layout/tabstrip/events/tabscroll-event';
import { SelectEvent, SVGIcon, TabAlignment, TabCloseEvent, TabPosition, TabStripScrollableSettings } from './model';
import { TabStripTabComponent } from './tab-strip-tab/tab-strip-tab.component';

@Component({
  selector: 'williams-ui-platform-tab-strip',
  templateUrl: './tab-strip.component.html'
})
export class TabStripComponent implements OnInit {

  @ViewChild('tabStripInstance') tabStripInstance!: KendoTabStripComponent;
  @ContentChildren(TabStripTabComponent)
  tabs!: QueryList<TabStripTabComponent>;
  /**
   * Enables the tab animation.
   */
  @Input() animate: boolean =true; 
  
  /**
   * When set to true, a close button will be rendered inside each tab. 
   * By default, closable is false.
   */
  @Input() closable: boolean =false;

  /**
   * Defines the name for an existing font icon in the Kendo UI theme for the close icon.
   */
  @Input() closeIcon!: string;

  /**
   * Allows defining a custom CSS class,
   * or multiple classes separated by spaces, 
   * which will be applied to the close button.
   */
  @Input() closeIconClass!:string;

  /**
   * Defines an SVGIcon to be rendered for the close icon. 
   * The input can take either an existing Kendo SVG icon or a custom one.
   */
  @Input() closeSVGIcon!: SVGIcon;

  /**
   * Sets the height of the TabStrip.
   */
  @Input() height!: string;

  /**
   * 	When set to true, the component renders all tabs and they are persisted in the DOM. 
   *  By default, keepTabContent is false.
   */
  @Input() keepTabContent: boolean = false;

  /**
   * Enables the scrolling of the tab list.
   * When set to true and the total size of all tabs is greater than the size of the TabStrip container,
   * scroll buttons will be rendered on each end of the tab list.By default, scrollable is false.
   */
  @Input() scrollable: boolean | TabStripScrollableSettings = false;

  /**
   * Sets the alignment of the tabs.
   */
  @Input() tabAlignment!: TabAlignment;

  /**
   * Sets the position of the tabs. Defaults to top.
   */
  @Input() tabPosition!: TabPosition;
  
  /**
   * Fires each time the user closes a tab. 
   * The event data contains the index of the closed tab and its instance.
   */
  @Output() tabClose: EventEmitter<TabCloseEvent> = new EventEmitter<TabCloseEvent>();

  /**
   * Fires each time the user scrolls the TabStrip list.
   * The event is preventable.
   */
  @Output() tabScroll: EventEmitter<TabScrollEvent> = new EventEmitter<TabScrollEvent>();

  /**
   * Fires each time the user selects a tab.
   * The event data contains the index of the selected tab and its title.
   */
  @Output() tabSelect: EventEmitter<SelectEvent> = new EventEmitter<SelectEvent>();

  constructor() { }
  ngOnInit(): void {
  }
  
  tabCloseHandler(event: TabCloseEvent): void{
    this.tabClose.emit(event);
  }

  tabScrollHandler(event: TabScrollEvent): void{
    this.tabScroll.emit(event);
  }

  tabSelectHandler(event: SelectEvent): void {
    this.tabSelect.emit(event);
  }
  /**
   * The index of the tab that will be selected.
   * @param index 
   */
  selectTab(index: number){
     this.tabStripInstance.selectTab(index);
  }
}
