import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { TileLayoutFlowMode, TileLayoutGap, TileLayoutReorderEvent, TileLayoutResizeEvent } from './model';
import { TilelayoutItemComponent } from './tilelayout-item/tilelayout-item.component';

@Component({
  selector: 'williams-ui-platform-tile-layout',
  templateUrl: './tile-layout.component.html'
})
export class TileLayoutComponent implements OnInit {
  @ContentChildren(TilelayoutItemComponent)
  items!: QueryList<TilelayoutItemComponent>;
  /**
   * Controls how the auto-placement algorithm works, 
   * specifying exactly how auto-placed items are flowed in the TileLayout.
   * For further reference, check the grid-auto-flow CSS article.
   * The possible values are: column row , row dense, column dense, none
   */
  @Input() autoFlow: TileLayoutFlowMode = "column";
  
  /**
   * Defines the number of columns
   */
  @Input() columns: number =1;
  
  /**
   * Determines the width of the columns. Numeric values are treated as pixels
   */
  @Input() columnWidth: string | number="1fr";

  /**
   * The numeric values which determine the spacing in pixels between the layout items horizontally and vertically.
   * Properties:
   * rows - the vertical spacing. Numeric values are treated as pixels. Defaults to 16.
   * columns - the horizontal spacing. Numeric values are treated as pixels. Defaults to 
   */
  @Input() gap: number | TileLayoutGap=16;

  /**
   * If set to true, the user can use dedicated shortcuts to interact with the TileLayout.
   * By default, navigation is disabled and focusable TileLayout content is a part of the natural tab sequence of the page.
   */
  @Input() navigable: boolean = false;

  /**
   * Determines whether the reordering functionality will be enabled
   */
  @Input() reorderable: boolean= false;

  /**
   * Determines whether the resizing functionality will be enabled  
   */
  @Input() resizable: boolean= false;

  /**
   * Determines the height of the rows. Numeric values are treated as pixels
   */
  @Input() rowHeight: string | number="1fr";

  /**
   * Fires when the user completes the reordering of the item. 
   * This event is preventable. If you cancel it, the item will not be reordered.
   */
  @Output() reorder: EventEmitter<TileLayoutReorderEvent> = new EventEmitter<TileLayoutReorderEvent>();

  /**
   * Fires when the user completes the resizing of the item. 
   * This event is preventable. If you cancel it, the item will not be resized.
   */
  @Output() resize: EventEmitter<TileLayoutResizeEvent> = new EventEmitter<TileLayoutResizeEvent>();

  reorderHandler(event:TileLayoutReorderEvent){
    this.reorder.emit(event);
  }

  resizeHandler(event: TileLayoutResizeEvent){
    this.resize.emit(event);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
