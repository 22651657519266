<kendo-tabstrip 
      #tabStripInstance="kendoTabStrip"
      [animate]="animate"
      [closable]="closable"
      [closeIcon]="closeIcon"
      [height]="height"
      [keepTabContent]="keepTabContent"
      [scrollable]="scrollable"
      [tabAlignment]="tabAlignment"
      [tabPosition]="tabPosition"
      (tabClose)="tabCloseHandler($event)"
      (tabScroll)="tabScrollHandler($event)"
      (tabSelect)="tabSelectHandler($event)"
      >
    <kendo-tabstrip-tab
      *ngFor="let tab of tabs; let i = index"
      [title]="tab.title"
      [selected]="tab.selected"
      [disabled]="tab.disabled"
      [closable]="tab.closable"
      [closeIcon]="tab.closeIcon"
      [cssClass]="tab.cssClass"
      [cssStyle]="tab.cssStyle"
    >
     <ng-template kendoTabTitle *ngIf="tab?.tabStripTabTemplateDirective?.tabTitleTemplate">
        <ng-container  *ngTemplateOutlet="tab?.tabStripTabTemplateDirective?.tabTitleTemplate">
        </ng-container>
      </ng-template>
      <ng-template kendoTabContent *ngIf="tab?.tabStripTabTemplateDirective?.tabContentTemplate">
        <ng-container  *ngTemplateOutlet="tab?.tabStripTabTemplateDirective?.tabContentTemplate">
        </ng-container>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>