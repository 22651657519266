import { Component,ContentChild,forwardRef, Input, OnInit } from '@angular/core';
import { TilelayoutItemBodyComponent } from '../tilelayout-item-body/tilelayout-item-body.component';
import { TilelayoutItemHeaderComponent } from '../tilelayout-item-header/tilelayout-item-header.component';

@Component({
  selector: 'williams-ui-platform-tilelayout-item',
  templateUrl: './tilelayout-item.component.html'
})
export class TilelayoutItemComponent implements OnInit {
  @ContentChild(
    forwardRef(() => TilelayoutItemBodyComponent)
  ) 
  tilelayoutItemBodyComponent!: any;
  @ContentChild(
    forwardRef(() => TilelayoutItemHeaderComponent)
  ) 
  tilelayoutItemHeaderComponent!: any;

  /**
   * Sets the starting column of the item 
   */
  @Input() col!:number;

  /**
   * Determines how many columns will the tile item span 
   */
  @Input() colSpan!:number;

  /**
   * Determines the order of the tile items within the TileLayout. 
   * If not set, the items will receive increasing sequential order in accordance with their position in the DOM when initially rendered.
   */
  @Input() order!:number;

  /**
   * Determines whether the item can be reordered. 
   * By default all items are reorderable when the reorderable property of the TileLayoutComponent is set).
   */
  @Input() reorderable!:boolean;

  /**
   *  Determines whether the item can be resized. 
   *  By default all items are resizable when the resizable property of the TileLayoutComponent is set).
   */
  @Input() resizable!:boolean;

  /**
   * Sets the starting row of the item.
   */
  @Input() row!:number;

  /**
   * Determines how many rows will the tile item span.
   */
  @Input() rowSpan!:number;

  /**
   * The title that will be rendered in the item header.
   */
  @Input() title!:string;

  constructor() { }

  ngOnInit(): void {
  }

}


