<kendo-dialog-titlebar>
  {{ settings.title }}
</kendo-dialog-titlebar>
<div class="dialog-content">
  <p>
    {{ settings.content }}
  </p>
</div>
<kendo-dialog-actions>
  <williams-ui-platform-button
    *ngIf="settings.cancelAction"
    [classList]="settings.cancelAction.cssClass ?? 'btn-outlined'"
    [icon]="settings.cancelAction.icon ?? 'close'"
    [imageUrl]="settings.cancelAction.imageUrl ?? ''"
    (clicked)="onActionButtonClick(settings.cancelAction)"
    themeColor="primary"
    fillMode="outline"
    width="100%"
  >
    {{ settings.cancelAction.text }}
  </williams-ui-platform-button>
  <williams-ui-platform-button
    [classList]="settings.confirmAction.cssClass ?? 'btn-primary'"
    [icon]="settings.confirmAction.icon ?? 'checkmark'"
    [imageUrl]="settings.confirmAction.imageUrl ?? ''"
    (clicked)="onActionButtonClick(settings.confirmAction)"
    themeColor="primary"
    fillMode="solid"
    width="100%"
  >
    {{ settings.confirmAction.text }}
  </williams-ui-platform-button>
</kendo-dialog-actions>
