<button
    kendoButton
    [disabled]="disabled"
    [icon]="icon"
    [iconClass]="iconClass"
    [imageUrl]="imageUrl"
    [fillMode]="fillMode"
    [themeColor]="themeColor"
    [rounded]="rounded"
    [selected]="selected"
    [togglable]="toggleable"
    (click)="onClick($event)"
    (selectedChange)="onSelectedChange($event)"
    [class]="classList"
    [type]="type"
    [style]="{ width }"
    [size]="size"
>
  <ng-content></ng-content>
</button>
