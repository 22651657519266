import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { CRITICALNOTICES } from '../entity-meta-data/entity-metadata';
import { NoticesInterface } from '../model/notices.interface';

@Injectable({ providedIn: 'root' })
export class CriticalNoticesService extends EntityCollectionServiceBase<NoticesInterface> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(CRITICALNOTICES,serviceElementsFactory);
  }
}