<williams-ui-platform-stack-layout class="homepage-container" themeColor=" flex-grow-1" [gap]="'0px'" *ngIf="showHomePage">
    <williams-ui-platform-stack-layout [gap]="'0px'" class="w-flex-basis-6 w-bg-primary-pt9  flex-grow-0">
        <williams-ui-platform-drawer [width]="260" themeColor="primary" [items]="menus" (select)="onSelect($event)">
            <williams-ui-platform-drawer-templates>
                <ng-template #headerTemplate>
                  <img src="assets/w-ui-angular/images/discovery_logo.png" alt="" class="discoverylogo">
                </ng-template>
            </williams-ui-platform-drawer-templates>
        </williams-ui-platform-drawer>
    </williams-ui-platform-stack-layout>
    <div class="mainContainer d-flex flex-column flex-justify-between">
        <div class="d-flex flex-column flex-gap-0">
            <williams-ui-platform-header></williams-ui-platform-header>
            <router-outlet></router-outlet>
        </div>
        <williams-ui-platform-footer></williams-ui-platform-footer>
    </div>
</williams-ui-platform-stack-layout>
<williams-ui-platform-pdf-viewer *ngIf="!showHomePage"></williams-ui-platform-pdf-viewer>