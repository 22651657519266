/**
 * Flattens grid view to array of grid data items
 * (It will be used when grid groupable option is true)
 * @param gridView 
 * @returns 
 */
export const flattenGridView = (gridView: any[]): any[] => {
    const output = [];
    for(let groupItem of gridView) {
      if(groupItem.items) {
        output.push(...flattenGridView(groupItem.items));
      } else {
        output.push(groupItem);
      }
    }
    return output;
}