<div [ngClass]="{ 'show-errors': showErrorMsg }">
  <kendo-textarea
    resizable="auto"
    [maxlength]="maxlength"
    (valueChange)="onValueChange($event)"
    [resizable]="resizable"
    [rows]="rows"
    [formControl]="control"
  >
  <kendo-textarea-suffix *ngIf="showCharacterCount" class="custom-styling">
    <span class="counter">{{ counter }}</span>
  </kendo-textarea-suffix>
  </kendo-textarea>
  </div>