import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { Observable, tap } from 'rxjs';
import { htmlUrl } from '../../../api-endpoints';
import {  HTML } from '../entity-meta-data/entity-metadata';
import { StaticResponse } from '../model/base-response';

@Injectable({ providedIn: 'root' })
export class HTMLService extends EntityCollectionServiceBase<any> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient) {
    super(HTML, serviceElementsFactory);
  }

  getHTMLData(param?:string): Observable<any> {
   const url = `${htmlUrl}fileName=${param}`;
   return this.http.get(url, {responseType: 'text'})
      .pipe(tap((res: any) => {
       return res;
      }));
  }
}