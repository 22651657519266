import { TemplateRef } from "@angular/core";
import { ColumnSortSettings as KendoColumnSortSettings } from "@progress/kendo-angular-grid";

export interface ColumnSortSettings extends KendoColumnSortSettings {}

export interface ColumnConfigurationBase {
  // Indicates whether the column will be resized during initialization so that it fits its header and row content.
  autoSize: boolean;

  // Specifies if the column menu will be shown for the column.
  columnMenu: boolean;

  // Sets the custom CSS classes to the column cells.
  cssClass: string;

  // Sets the custom CSS classes to the column footer cell.
  footerClass: string;

  // Sets the custom CSS classes to the column header cell.
  headerClass: string;

  // Sets the visibility of the column.
  hidden: boolean;

  // Specifies if the column will be included in the column-chooser list.
  includeInChooser: boolean;

  // Specifies if the column can be locked or unlocked from the column menu or by reordering the columns.
  lockable: boolean;

  // Toggles the locked (frozen) state of the columns
  locked: boolean;

  // Indicates whether the column is reorderable.
  reorderable: boolean;

  // Indicates whether the column is resizable.
  resizable: boolean;

  // Specifies if the column can be stuck or unstuck from the column menu.
  stickable: boolean;

  // Determines whether the column will be always visible when scrolling the Grid horizontally.
  sticky: boolean;

  // The title of the column.
  title: string;

  // The width of the column (in pixels).
  width: number;
}


export interface ColumnConfiguration extends ColumnConfigurationBase {
  // Cell template for the column.
  cellTemplate: TemplateRef<any> | null;

  // Dropdown optons when editor is dropdown
  dropdownEditorSettings: {
    data: any[];
    valuePrimitive: boolean;
    valueField: string;
    textField: string;
  }

  // Defines whether the column is editable.
  editableOn: {
    add: boolean;
    edit: boolean;
  };

  // Defines the editor type
  editor: "text" | "dropdown";

  // Edit template for the column.
  editTemplate: TemplateRef<any> | null;

  // The field to which the column is bound.
  field: string;

  filter: 'boolean' | 'text' | 'numeric' | 'date';

  // Defines if a filter UI will be displayed for this column.
  filterable: boolean;

  // Determines if the column can be dragged to the group panel.
  groupable: boolean;

  // Allows the column headers to be clicked and the sortChange event emitted.
  sortable: boolean | ColumnSortSettings;

  // FormControlName for the column
  formControlName: string;
}

export interface CommandColumnConfiguration extends ColumnConfigurationBase {
}

export interface CheckboxColumnConfiguration extends ColumnConfigurationBase {
  // Determines whether a select-all kendoGridSelectAllCheckbox checkbox will be displayed in the header.
  showSelectAll: boolean;
}