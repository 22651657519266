import { Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateInputFillMode, DateInputRounded, DateInputSize, FormatSettings } from '@progress/kendo-angular-dateinputs';
import { BaseAccessor } from '../base/base-accessor';

@Component({
  selector: 'williams-ui-platform-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent extends BaseAccessor{
 
  @Input() value:Date=new Date();
  @Input() format: string | FormatSettings="MM/dd/yyyy";
  //With some comments
  @Input() min!: Date;
  @Input() placeholder= "MM/DD/YYYY";
  @Input() showErrorMsg=false;
  @Input() max! : Date;
  @Input() fillMode: DateInputFillMode = 'solid';
  @Input() rounded: DateInputRounded = 'full';
  @Input() size: DateInputSize = 'medium';
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() classList = '';
  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }
}
