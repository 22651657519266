import { Component, Input, OnInit } from "@angular/core";

export interface ErrorEntity {
  id: any;
  title: string;
  errors: {
      code?: string;
      id?: string;
      message: string
  }[];
  type?: any;
}

@Component({
  selector: "williams-ui-platform-error-messages",
  templateUrl: "./error-messages.component.html",
})
export class ErrorMessagesComponent {
  @Input() errorEntity!: ErrorEntity;
}
