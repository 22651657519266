<kendo-expansionpanel
  [animation]="animation"
  [expanded]="expanded"
  [expanded]="expanded"
  [collapseIcon]="collapseIcon"
  [expandIcon]="expandIcon"
  [disabled]="disabled"
  [subtitle]="subtitle"
  [title]="title"
  (action)="onAction($event)"
  (collapse)="onCollapse($event)"
  (expand)="onExpand($event)"
  (expandedChange)="onExpandedChange($event)"
>
  <ng-template
    kendoExpansionPanelTitleDirective
    *ngIf="expansionPanelTitleDirective"
  >
    <ng-container
      *ngTemplateOutlet="expansionPanelTitleDirective.titleTemplate"
    >
    </ng-container>
  </ng-template>
  <ng-content></ng-content>
</kendo-expansionpanel>
