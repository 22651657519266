import { ColumnMenuSettings, SingleSortSettings as KendoSingleSortSettings, MultipleSortSettings as KendoMultipleSortSettings, FilterableSettings, ScrollMode } from "@progress/kendo-angular-grid";
import { AggregateDescriptor, GroupDescriptor, State } from "@progress/kendo-data-query";
export type RowClassCallback = (context: { dataItem: any }) => { [key: string]: any }
export interface GridColumnMenuSettings extends ColumnMenuSettings {}
export interface GridAggregateDescriptor extends AggregateDescriptor{
    label?: string;
}

export interface GridGroupDescriptor extends GroupDescriptor {}

export type GridFilterableSettings = FilterableSettings;

export type GridScrollMode = ScrollMode;

export enum ActionType {
    QUICK_ADD = 'quick-add',
    INLINE_EDIT = 'inline-edit',
    BULK_EDIT = 'bulk-edit',
    INLINE_COPY = 'inline-copy',
    BULK_COPY = 'copy',
    NONE = 'none'
  };
  
  export interface HyperLinkEvent {
    field: string;
    dataItem: any;
  }
  
  interface ValidationResult {
      valid: boolean,
      errors: any[]
  }
  export interface SubmitFormEvent {
    validationResult: ValidationResult,
    data: any[]
  }
  
  export interface FormValidationChange {
    validationResult: ValidationResult 
  }

  export interface GridSingleSortSettings extends KendoSingleSortSettings {}

  export interface GridMultipleSortSettings extends KendoMultipleSortSettings {}

  export type GridSortSettings = boolean | GridSingleSortSettings | GridMultipleSortSettings;

  export interface GridState extends State {
    aggregates: GridAggregateDescriptor[]
  }