<div class ="d-flex" [ngClass]="{ 'show-errors': showErrors, 'hide-searchbar': hideSearchBar }" kendoTooltip>
  <kendo-multiselect
    #list
    class="p-2 mt-4"
    [data]="data"
    [textField]="textField"
    [valueField]="valueField"
    [value]="value"
    [valuePrimitive]="valuePrimitive"
    [formControl]="control"
    [placeholder]="placeholder"
    [popupSettings]="popupSettings"
    [size]="size"
    [filterable]="filterable"
    [kendoMultiSelectSummaryTag]="summaryTagCount"
    [fillMode]="fillMode"
    [rounded]="rounded"
    [readonly]="readonly"
    [disabled]="disabled"
    (filterChange)="handleFilter($event)"
    [clearButton]="true"
    (closed)="hideSearchBar = true"
    (opened)="hideSearchBar = false"
    (valueChange)="onValueChange($event)"
  >
  <ng-template kendoMultiSelectHeaderTemplate *ngIf="showSelectAll">
    <span>
        <input type="checkbox" id="chk" kendoCheckBox [checked]="isChecked" (click)="onClick($event)" />
        <label for="chk">{{ toggleAllText }}</label>
    </span>
  </ng-template>
    <ng-template kendoMultiSelectTagTemplate let-dataItem>
      <span [title]="dataItem[textField] ?? dataItem">{{ dataItem[textField] ?? dataItem }}</span>
    </ng-template>
    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
      <span [title]="getTooltipTextForGroupTag(dataItems)">{{dataItems.length}} more</span>
    </ng-template>
  </kendo-multiselect>
  <kendo-icon class="requiredIcon w-icon-color w-mt-2 w-ml-n8" *ngIf="showErrors"  name="warning"></kendo-icon> 
</div>