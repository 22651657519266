import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { TariffPDFMap } from "../constant";
import { IndexOfCustomersService } from "../store/services/index-of-customers.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: "williams-ui-platform-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit {
  pageNumber: number | undefined = 1;
  indexOfCustomer = "IndexofCustomers";
  locations = "Locations";
  destroy$: Subject<boolean> = new Subject<boolean>();
  pdfUrl = "";
  showPdf = false;
  fileName = "";
  constructor(
    private route: ActivatedRoute,
    private customerService: IndexOfCustomersService,
    title: Title
  ) {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length) {
        this.fileName = params["fileName"];
        this.pageNumber = TariffPDFMap.get(params["title"]);
        title.setTitle(this.fileName);
        if (
          this.fileName === this.indexOfCustomer ||
          this.fileName === this.locations
        ) {
          this.showLocationIndexPdf();
        } else {
          this.showPdfFile(this.fileName);
        }
      }
    });
  }

  ngOnInit(): void {}

  showPdfFile(fileName: string) {
    this.customerService
      .fetchPdfFileData(fileName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.showPdf = true;
        this.pdfUrl = res[0];
      });
  }

  showLocationIndexPdf() {
    this.customerService
      .fetchFileData({ fileType: "PDF" }, this.fileName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.showPdf = true;
        this.pdfUrl = res[0];
      });
  }

  /*
  function for destroy angular component with lifycycle-hook
 */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
