<div
  class="text-ellipsis"
  show-tooltip
  [class]="columnClassCallback(dataItem, columnDef.field)"
>
  <williams-ui-platform-link-button
    (linkClick)="onHyperlinkClick()"
    *ngIf="columnDef.isHyperlink; else gridCellText"
  >
    {{ getColumnValue() }}
  </williams-ui-platform-link-button>
  <ng-template #gridCellText>{{ getColumnValue() }}</ng-template>
</div>
