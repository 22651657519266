<williams-ui-platform-stack-layout orientation="vertical" [gap]="'0px'">
  <williams-ui-platform-stack-layout>
    <img class="ebbhomeimage" src="assets/w-ui-angular/images/ebb-home.png" alt="" />
  </williams-ui-platform-stack-layout>
  <williams-ui-platform-stack-layout *ngIf="showCriticalNotices" class="w-px-8 w-py-4 w-bg-compTxt-1" [gap]="'0'">
    <williams-ui-platform-stack-layout  *ngIf="noticeData && noticeData.length > 0; else norecordsfoundforcritical" orientation="vertical">
      <williams-ui-platform-stack-layout orientation="horizontal">
        <williams-ui-platform-stack-layout [align]="{ horizontal: 'start' }">
          <h2 class="w-m-0 w-color-white-pt9">{{ CRITICAL_NOTICES }}</h2>
        </williams-ui-platform-stack-layout>
        <williams-ui-platform-stack-layout [align]="{ horizontal: 'end' }">
          <williams-ui-platform-button classList="btn-outlined d-flex w-h1-bold" fillMode="outline" themeColor="primary"
            [imageUrl]="navIcon" (mouseover)="navIcon = activeNavExpandIcon" (mouseout)="navIcon = hoveredNavExpandIcon"
            (click)="navigateToNotices('C')">
            {{ SHOW_ALL }}
          </williams-ui-platform-button>
        </williams-ui-platform-stack-layout>
      </williams-ui-platform-stack-layout>
      <williams-ui-platform-carousel [pageable]="true" [data]="noticeCard" [width]="width" [height]="height"
        [arrows]="true" [activeIndex]="activeIndexValue" (itemChanged)="onChange($event)">
        <williams-ui-platform-carousel-template #carouselTemplate>
          <ng-template let-item="item" #carouselTemplate>
            <williams-ui-platform-stack-layout orientation="vertical" [gap]="'0px'">
              <williams-ui-platform-stack-layout [gap]="'24px'">
                <williams-ui-platform-notice-card width="100%" *ngFor="let notice of notices"
                  classList="w-mr-8 border-radius-xsm clickable" (click)="navigateToNoticesDetails(notice)">
                  <williams-ui-platform-badge [customStyles]="criticalBadge" badge-content>{{ notice.category }}</williams-ui-platform-badge>
                  <h6 class="w-base-text-large-bold truncateCriticalNotices w-m-0" >{{ notice.title }}</h6>
                  <h6 class="w-color-secondary-pt9 w-small-text-normal w-pl-1 w-m-0" footer>{{transFormDate(notice.effectiveDate)}}</h6>
                </williams-ui-platform-notice-card>
              </williams-ui-platform-stack-layout>
            </williams-ui-platform-stack-layout>
          </ng-template>
        </williams-ui-platform-carousel-template>
      </williams-ui-platform-carousel>
    </williams-ui-platform-stack-layout>
    <ng-template #norecordsfoundforcritical>
      <williams-ui-platform-stack-layout orientation="vertical">
        <williams-ui-platform-stack-layout [align]="{ horizontal: 'start' }" class="w-mb-0">
          <h2 class="w-color-white-pt9 w-mb-0">{{ CRITICAL_NOTICES }}</h2>
        </williams-ui-platform-stack-layout>
        <williams-ui-platform-card width="100%" class="noticeCard" classList="border-radius-xsm emptyNoticeHomePage">
          <h6 class="w-base-text-large-bold w-color-white-1">
            {{ THERE_ARE_NO_CRITICAL_NOTICES }}
          </h6>
        </williams-ui-platform-card>
      </williams-ui-platform-stack-layout>
    </ng-template>
  </williams-ui-platform-stack-layout>
<div #plannedServiceOutageContainer *ngIf="showPlannedOutageNotices" class="w-px-8 w-py-4 w-bg-white-pt9">
  <williams-ui-platform-stack-layout orientation="vertical" *ngIf="
      plannedOutageData && plannedOutageData.length > 0;
      else norecordsfoundforplanned
    ">
    <williams-ui-platform-stack-layout orientation="vertical">
      <williams-ui-platform-stack-layout orientation="horizontal">
        <williams-ui-platform-stack-layout>
          <h2 class="w-color-compTxt-1">{{ PLANNED_SERVICE_OUTAGE }}</h2>
        </williams-ui-platform-stack-layout>
        <williams-ui-platform-stack-layout [align]="{ horizontal: 'end' }">
          <williams-ui-platform-button classList="btn-outlined d-flex w-h1-bold" fillMode="outline" themeColor="primary"
            [imageUrl]="navExpandIcon" (mouseover)="navExpandIcon = activeNavExpandIcon"
            (mouseout)="navExpandIcon = hoveredNavExpandIcon" (click)="navigateToNotices('P')">
            {{ SHOW_ALL }}
          </williams-ui-platform-button>
        </williams-ui-platform-stack-layout>
      </williams-ui-platform-stack-layout>
      <williams-ui-platform-stack-layout orientation="vertical">
        <williams-ui-platform-stack-layout [gap]="'0px'">
          <div class="d-flex flex-wrap" style="gap: 24px">
            <williams-ui-platform-notice-card [width]="plannedServiceOutageCardWidth + 'px'" *ngFor="let data of plannedOutageData"
              classList="border-radius-xsm clickable" (click)="navigateToPlannedNoticesDetails(data)">
              <williams-ui-platform-badge [customStyles]="plannedBadge" badge-content>{{data.category}}</williams-ui-platform-badge>
              <h6 class="w-h1-bold truncatePlannedNotices">{{ data.title }}</h6>
              <span class="w-color-secondary-pt9" footer>{{transFormDate(data.effectiveDate)}}</span>
            </williams-ui-platform-notice-card>
          </div>
        </williams-ui-platform-stack-layout>
      </williams-ui-platform-stack-layout>
    </williams-ui-platform-stack-layout>
  </williams-ui-platform-stack-layout>
  <ng-template #norecordsfoundforplanned>
    <williams-ui-platform-stack-layout orientation="vertical" class="w-px-8 w-py-4 w-bg-white-pt9">
      <williams-ui-platform-stack-layout>
        <h2 class="w-color-compTxt-1">{{ PLANNED_SERVICE_OUTAGE }}</h2>
      </williams-ui-platform-stack-layout>
      <williams-ui-platform-card width="100%" classList="border-radius-xsm">
         <h6 class="w-base-text-large-bold w-color-compTxt-1">{{ THERE_ARE_NO_PLANNED_SERVICE }}</h6>
      </williams-ui-platform-card>
    </williams-ui-platform-stack-layout>
  </ng-template>
</div>
  <williams-ui-platform-stack-layout class="w-px-8" *ngIf="pdfUrl">
    <ngx-extended-pdf-viewer [ignoreKeyboard]="true" [pageViewMode]="'infinite-scroll'" [zoom]="30" [base64Src]="pdfUrl"
      [useBrowserLocale]="true" [showToolbar]="false">
    </ngx-extended-pdf-viewer>
  </williams-ui-platform-stack-layout>
  <williams-ui-platform-stack-layout [gap]="'24px'" class="d-flex w-px-8 w-py-4 flex-column w-bg-compTxt-1 w-color-white-pt9"
    orientation="horizontal">
    <williams-ui-platform-stack-layout orientation="vertical" [align]="{ horizontal: 'center' }" class="w-flex-basis-6 flex-grow-0 w-position-relative">
        <p class="w-my-20 w-display-4">{{ CONTACTS }}</p>
    </williams-ui-platform-stack-layout>
    <div class="flex-grow-0">
      <img src="assets/w-ui-angular/images/Separator.png" alt="" />
    </div>
    <williams-ui-platform-stack-layout orientation="vertical" class="w-position-relative">
      <div class="verticallyCenter">
        <h3 class="w-h1-bold">{{ ADDRESS_1 }}</h3>
        <h6 class="w-m-0">{{ ADDRESS_2 }}</h6>
        <h6 class="w-m-0">{{ ADDRESS_3 }}</h6>
        <h6 class="w-m-0">{{ ADDRESS_4 }}</h6>
      </div>
    </williams-ui-platform-stack-layout>
  <div class="w-bg-white-1 peopleimgcontainer w-position-relative w-mr-13">
      <williams-ui-platform-stack-layout orientation="vertical" class="center">
        <div class="d-flex flex-wrap flex-justify-center">
          <img src="assets/w-ui-angular/images/people.png" alt="" />
        </div>
        <williams-ui-platform-stack-layout>
          <williams-ui-platform-button
            fillMode="outline"
             classList="d-flex w-h1-bold w-mt-2 buttonwithnostyles"
            themeColor="primary" [imageUrl]="navContactIcon" (mouseover)="navContactIcon = activeNavExpandIcon"
            (mouseout)="navContactIcon = hoveredNavExpandIcon" (click)="navigateToContact()">
            {{ VIEW_ALL_CONTACTS }}
          </williams-ui-platform-button>
        </williams-ui-platform-stack-layout>
      </williams-ui-platform-stack-layout>
    </div>
  </williams-ui-platform-stack-layout>
</williams-ui-platform-stack-layout>