  <kendo-fileselect
    [restrictions]="fileRestrictions"
    [multiple]="false"
    [formControl]="control"
  >
  <kendo-fileselect-messages
        [dropFilesHere]="dropCustomText"
        [select] = "select"
        [invalidFileExtension]="fileTypeCustomText"
        >
    </kendo-fileselect-messages>
  </kendo-fileselect>