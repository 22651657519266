import { GridCommandColumnActionItems, GridCommandColumnActionType, GridExportOption } from "../../models/grid";
import { GridColumnMenuSettings, GridState } from "../model";

export const defaultColumnMenuSettings : GridColumnMenuSettings = {
      lock: true
}

export const CANCEL_CONFIRMATION_DIALOG_TITLE = 'Please confirm';
export const CANCEL_CONFIRMATION_DIALOG_CONTENT = 'Canceling will lose the changes in progress. Are you sure you want to continue?';

export const defaultCommandColumnActions: GridCommandColumnActionItems = [
  // {
  //   text: "Edit",
  //   action: GridCommandColumnActionType.EDIT,
  //   disabled: false,
  // },
  {
    text: "Copy",
    action: GridCommandColumnActionType.COPY,
    disabled: false,
  },
  // {
  //   text: "Delete",
  //   action: GridCommandColumnActionType.DELETE,
  //   disabled: false,
  // },
];

export const defaultExportOptions: GridExportOption[] = [
  {
    text: "Excel",
    val: "Excel"
  },
  {
    text: "PDF",
    val: "PDF"
  }
];

export const defaultGridStateSettings: GridState = {
  take: 100,
  skip: 0,
  sort: [],
  group: [],
  filter: {
    logic: 'or',
    filters: []
  },
  aggregates: []
};