import { Injectable } from '@angular/core';
import {
    DefaultDataService,
    HttpUrlGenerator
  } from '@ngrx/data';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { INDEXOFCUSTOMERS } from '../entity-meta-data/entity-metadata';
import { StaticResponse } from '../model/base-response';
import { indexofCustomersUrl } from '../../../api-endpoints';
import { IndexOfCustomersInterface } from '../model/indexofcustomers.interface';

@Injectable()
export class IndexOfCustomersDataService extends DefaultDataService<IndexOfCustomersInterface> {
  constructor(http: HttpClient, 
              httpUrlGenerator: HttpUrlGenerator
              ) {
    super(INDEXOFCUSTOMERS, http, httpUrlGenerator);
  }
}