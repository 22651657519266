<div
  [formGroup]="group"
>
  <williams-ui-platform-text-input
    *ngIf="columnConfiguration.editor == 'text'"
    type="text"
    [formGroup]="group"
    [formControlName]="columnConfiguration.formControlName"
    [clearButton]="true"
    showErrorIcon="initial"
  ></williams-ui-platform-text-input>
  <williams-ui-platform-dropdown
    *ngIf="columnConfiguration.editor == 'dropdown'"
    [data]="columnConfiguration.dropdownEditorSettings.data"
    [textField]="columnConfiguration.dropdownEditorSettings.textField"
    [valueField]="columnConfiguration.dropdownEditorSettings.valueField"
    [valuePrimitive]="columnConfiguration.dropdownEditorSettings.valuePrimitive"
    [defaultItem]="{ label: 'Select', value: null }"
    [formControlName]="columnConfiguration.formControlName"
    [formGroup]="group"
  ></williams-ui-platform-dropdown>
</div>