import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'williams-ui-platform-side-navbar-layout',
  templateUrl: './side-navbar-layout.component.html',
  styleUrls: ['./side-navbar-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideNavbarLayoutComponent {
  @Input() menuItems: Array<any>=[];
  @Input() autoCollapse=true;
  @Input() mode: any = "push";
  @Input() mini= true;
  @Input() width= 175;
  @Input() expanded = false;
  @Input() headerTitle = "";
  @Output() itemSelect: EventEmitter<any> = new EventEmitter();

  onSelect(event: any){
    this.itemSelect.emit(event);
  }
}
