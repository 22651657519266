<span>Contact Information</span>
<form class="k-form" [formGroup]="contactDetails" >
    <fieldset class="k-form-fieldset">
        <kendo-formfield>
            <kendo-label [for]="name" text="Name"></kendo-label>
            <kendo-textbox formControlName="name" [clearButton]="true" #name required></kendo-textbox>
            <kendo-formerror>Error: Username is required</kendo-formerror>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="phone" text="Phone"></kendo-label>
            <kendo-textbox formControlName="phone" [clearButton]="true" #phone required></kendo-textbox>
            <kendo-formerror>Error: Phone Number is required</kendo-formerror>
          </kendo-formfield>
    </fieldset>
</form>
