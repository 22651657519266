import { ContactsList } from "../store/model/contacts.interface"
export const transformContactData = (record:ContactsList)=>{
    return {
       ...record,
       name: record.name ?? '',
       department: record.department ?? '',
       role: record.roles[0]?.name ?? '',
       officePhone: record.officePhone ?
       `${record.officePhone.slice(0,3)}-${record.officePhone.slice(3,6)}-${record.officePhone.slice(6,10)}` :
       '',
       contactNumber: record.contactNumber ?
       `${record.contactNumber.slice(0,3)}-${record.contactNumber.slice(3,6)}-${record.contactNumber.slice(6,10)}` :
       '',
       emailId: record.emailId ?? '',
       fax: record.fax ?
       `${record.fax.slice(0,3)}-${record.fax.slice(3,6)}-${record.fax.slice(6,10)}` :
       '',
    }
}
