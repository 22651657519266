import {
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList
} from "@angular/core";
import {PanelBarExpandMode} from "@progress/kendo-angular-layout";
import {PanelbarItemDirective} from "../../../directives/panelbar-item.directive";


@Component({
    selector: 'williams-ui-platform-panelbar',
    templateUrl: './panelbar.component.html'
})
export class PanelbarComponent {
    @Input() animate: boolean = false;
    @Input() expandMode: PanelBarExpandMode = PanelBarExpandMode.Default;
    @Input() height?: any;
    @Input() items: any[] = [];
    @Input() keepItemContent: boolean = false;
    @Input() selectable: boolean = true;

    @Output() collapse = new EventEmitter<any>;
    @Output() expand = new EventEmitter<any>;
    @Output() select = new EventEmitter<any>;
    @Output() stateChange = new EventEmitter<any>;

    @ContentChildren(PanelbarItemDirective) barsContentChildren: QueryList<PanelbarItemDirective> | undefined;

    onCollapse(event: any) {
        this.collapse.emit(event);
    }

    onExpand(event: any) {
        this.expand.emit(event);
    }

    onSelect(event: any) {
        this.select.emit(event);
    }

    onStateChange(event:any) {
        this.stateChange.emit(event);
    }

}
