import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  ButtonFillMode,
  ButtonRounded,
  ButtonSize,
  ButtonThemeColor,
} from "@progress/kendo-angular-buttons";

@Component({
  selector: "williams-ui-platform-splitbutton",
  templateUrl: "./splitbutton.component.html",
})
export class SplitButtonComponent {
  @Input() text!: string;
  @Input() data: any[] = [];
  @Input() icon!: string;
  @Input() type = "button";
  @Input() imageUrl!: string;
  @Input() arrowButtonIcon: string = "caret-alt-down";
  @Input() size: ButtonSize = "medium";
  @Input() fillMode: ButtonFillMode = "outline";
  @Input() rounded: ButtonRounded = "full";
  @Input() themeColor: ButtonThemeColor = "primary";
  @Output() btnClick: EventEmitter<void> = new EventEmitter();
}
