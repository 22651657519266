<kendo-badge
  [align]="align"
  [cutoutBorder]="cutoutBorder"
  [fill]="fill"
  [position]="position"
  [rounded]="rounded"
  [size]="size"
  [themeColor]="themeColor"
  [class]="classList"
  [style]="customStyles"
>
  <ng-content></ng-content>
</kendo-badge>
