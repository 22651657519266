import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Orientation, HorizontalAlign, VerticalAlign } from '@progress/kendo-angular-layout';

export interface Tab {
  title:string;
  active:boolean;
  routeName:string
}

@Component({
  selector: 'williams-ui-platform-tab-list',
  templateUrl: './tab-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tab-list.component.scss']
})

/**
 * This is my class
 * for TabListComponent
 */
export class TabListComponent  {
  @Input() relativePath: string='/nomination/';
  @Input() tabHeading: string = 'Nomination Type';
  @Input() tabs: Tab[] = [];
  

public orientation: Orientation = "vertical";
  public hAlign: HorizontalAlign = "stretch";
  public vAlign: VerticalAlign = "stretch";
  selected: boolean = false;
  

  @Output() tabSelect: EventEmitter<any> = new EventEmitter();

  

  onTabSelect(){
   
    //this.selected= !this.selected
    //this.tabSelect.emit(e);
  }

  

}
