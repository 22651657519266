import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  forwardRef
} from "@angular/core";
import { ExpansionPanelActionEvent as KendoExpansionPanelActionEvent } from "@progress/kendo-angular-layout";
import { ExpansionPanelTitleDirective } from "../../../directives/expansion-panel-title.directive";

export interface ExpansionPanelActionEvent extends KendoExpansionPanelActionEvent {}

@Component({
  selector: "williams-ui-platform-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
})
export class ExpansionPanelComponent {
  @Input() animation: boolean | number = true;
  @Input() expanded = false;
  @Input() collapseIcon = '';
  @Input() expandIcon = '';
  @Input() disabled = false;
  @Input() subtitle = '';
  @Input() title = '';

  @ContentChild(forwardRef(() => ExpansionPanelTitleDirective)) expansionPanelTitleDirective: any;

  // Fires when the expanded state of the ExpansionPanel is about to change. This event is preventable
  @Output() action: EventEmitter<ExpansionPanelActionEvent> = new EventEmitter();
  // Fires when the ExpansionPanel is collapsed. If there is animation it will fire when the animation is complete
  @Output() collapse: EventEmitter<any> = new EventEmitter();
  // Fires when the ExpansionPanel is expanded. If there is animation it will fire when the animation is complete
  @Output() expand: EventEmitter<any> = new EventEmitter();
  // Fires when the expanded property of the component is updated. Used to provide a two-way binding for the expanded property 
  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter();

  onAction(event: ExpansionPanelActionEvent) {
    this.action.emit(event);
  }

  onCollapse(event: any) {
    this.collapse.emit(event);
  }

  onExpand(event: any) {
    this.expand.emit(event);
  }

  onExpandedChange(event: boolean) {
    this.expandedChange.emit(event);
  }
}
