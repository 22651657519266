import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { map, share, Subject, Subscription, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'williams-ui-platform-digital-clock',
  templateUrl: './digital-clock.component.html',
  styleUrls: ['./digital-clock.component.scss']
})
export class DigitalClockComponent implements OnInit, OnDestroy {
  @Input() cls="";
  @Input() displayTimeInMinutes:number=60;
  @Input() format='hh:mm';
  @Input() locale='CST';
  private clearInterval$ = new Subject<boolean>();
  time = new Date();
  
  ngOnInit() {
    timer(0, 1000 * this.displayTimeInMinutes)
      .pipe(
        takeUntil(this.clearInterval$),
        map(() => new Date()),
        share()
      )
      .subscribe(time => {
        this.time = time;
      });
  }

  ngOnDestroy() {
   this.clearInterval$.next(true);
   this.clearInterval$.complete();
  }

}
