import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { map, Observable } from 'rxjs';
import { noticesURL } from '../../../api-endpoints';
import { PLANNEDSERVICEOUTAGES } from '../entity-meta-data/entity-metadata';
import { StaticResponse } from '../model/base-response';
import { NoticesInterface } from '../model/notices.interface';


@Injectable({ providedIn: 'root' })
export class PlannedOutagesService extends EntityCollectionServiceBase<NoticesInterface> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory,private http:HttpClient) {
    super(PLANNEDSERVICEOUTAGES,serviceElementsFactory);
  }

  fetchPlannedServiceOutages(params: any): Observable<any> {
    return  this.http.get<StaticResponse<Array<NoticesInterface>>>(noticesURL, {params: params})
      .pipe(map((res: StaticResponse<Array<NoticesInterface>>) => {
        this.addManyToCache(res.data);
        return res.data;
    }));
  }
}