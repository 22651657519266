import { ContentChild, Directive, TemplateRef } from '@angular/core';
@Directive({
  selector: 'williams-ui-platform-text-input-template'
})
export class TextInputTemplateDirective {
  @ContentChild('textInputPrefixTemplate') textInputPrefixTemplate!: TemplateRef<any>;
  @ContentChild('textInputSuffixTemplate') textInputSuffixTemplate!: TemplateRef<any>;
  constructor() { 
       
  }

}