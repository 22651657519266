import { Injectable } from '@angular/core';
import {
  DefaultDataService,
  HttpUrlGenerator,
} from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { FLOWINGGAS } from '../entity-meta-data/entity-metadata';

@Injectable()
export class FlowingGasServiceDataService extends DefaultDataService<any> {
  constructor(http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator) {
    super(FLOWINGGAS, http, httpUrlGenerator);
  }
}