import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { GridColumnDef } from "../../../models/grid-column-def";
import { HyperLinkEvent } from "../../model";

@Component({
    selector: 'williams-ui-platform-grid-cell-template',
    templateUrl: './grid-cell-template.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class GridCellTemplate {
    @Input() dataItem: any;
    @Input() columnDef!: GridColumnDef;
    @Input() columnClassCallback!: (dataItem: any, field: string) => string | string[] | { [key: string]: any } | Set<string>;
    @Output() hyperlinkClick = new EventEmitter<HyperLinkEvent>();

    onHyperlinkClick() {
        this.hyperlinkClick.emit({ dataItem: this.dataItem, field: this.columnDef.field });
    }

    getColumnValue(): string {
        const fieldArr = this.columnDef.field.split('.');
        return fieldArr.reduce((result, key) => {
          return result ? result[key] : result;
        }, { ...this.dataItem });
    }
}