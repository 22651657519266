import {
    DrawerItemExpandedFn as KendoDrawerItemExpandedFn,
    DrawerMode as KendoDrawerMode ,
    DrawerPosition as KendoDrawerPosition,
    DrawerItem as KendoDrawerItem,
    DrawerSelectEvent as KendoDrawerSelectEvent
} from "@progress/kendo-angular-layout";

import { DrawerAnimation as KendoDrawerAnimation } from "@progress/kendo-angular-layout/drawer/models/drawer-animation.interface";


export type DrawerItemExpandedFn = KendoDrawerItemExpandedFn;
export type DrawerMode = KendoDrawerMode;
export type DrawerPosition = KendoDrawerPosition;

export interface DrawerItem extends KendoDrawerItem {};

export interface DrawerAnimation extends KendoDrawerAnimation {}

export interface DrawerSelectEvent extends KendoDrawerSelectEvent {}