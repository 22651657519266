import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';
import { debounceTime, Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'williams-ui-platform-search-text',
  templateUrl: './search-text.component.html',
  styleUrls: ['./search-text.component.scss']
})
export class SearchTextComponent implements OnInit, OnDestroy {
  @Input() placeholder: string ='';
  @Input() clearButton: boolean = true;
  @Output() onSearchTermChange: EventEmitter<string> = new EventEmitter<string>();
  destroy$ = new Subject<void>();
  searchForm = this.fb.group({
    searchTerm:['']
  })
  constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
      this.searchForm.valueChanges.pipe(
        takeUntil(this.destroy$),
        debounceTime(500)
        ).subscribe(
          (value:any)=>{
             this.onSearchTermChange.emit(value.searchTerm);
          }
      );
    }

    ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }
}
