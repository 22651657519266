import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewChild
} from "@angular/core";
import { ControlContainer, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ComboBoxComponent as KendoComboBoxComponent, DropDownFillMode, DropDownRounded, DropDownSize} from "@progress/kendo-angular-dropdowns";
import { Subject, takeUntil } from "rxjs";
import { BaseAccessor } from "../base/base-accessor";

@Component({
  selector: "williams-ui-platform-combobox",
  templateUrl: "./combobox.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxComponent),
      multi: true,
    },
  ],
})
export class ComboboxComponent extends BaseAccessor implements AfterViewInit, OnDestroy {
  @ViewChild('combobox', { static: true }) combobox!: KendoComboBoxComponent
  @Input() data: any[] = [];
  @Input() clearButton = true;
  @Input() disabled = false;
  @Input() filterable = false;
  @Input() textField!: string;
  @Input() valueField!: string;
  @Input() valuePrimitive = true;
  @Input() value: any;
  @Input() suggest = true;
  // Hides the arrow down button when set to true
  @Input() hideArrowButton = false;
  @Input() showOptionsOnFocus = true;
  @Input() fillMode: DropDownFillMode = 'solid';
  @Input() rounded: DropDownRounded = 'full';
  @Input() size: DropDownSize = 'medium';
  @Input() showErrors = false ;
  @Output() filterChange = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<any>();

  private destroy$ = new Subject<void>();

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }

  ngAfterViewInit(): void {
    if(this.showOptionsOnFocus) {
      this.combobox.onFocus.pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.combobox.togglePopup(true);
      })
    }
  }

  onFilterChange(value: string): void {
    this.filterChange.emit(value);
  }

  onValueChange(value: any): void {
    this.valueChange.emit(value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
