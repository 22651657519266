import { Component, Input } from "@angular/core";
import { Orientation } from "../../models";


/** 
 * Example Usage
 * 
 * <williams-ui-platform-notice-card>
 *      // Content for badge
 *      <williams-ui-platform-badge badge-content>
 *      </williams-ui-platform-badge>
 *      // Card body content
 *      .....
 *      .....
 *      // Footer content
 *      <p footer>
 *          ....
 *      </p>
 *      </williams-ui-platform-notice-card>
*/

@Component({
    selector: 'williams-ui-platform-notice-card',
    templateUrl: './notice-card.component.html'
})
export class NoticeCardComponent {
    @Input() orientation: Orientation = "vertical";
    @Input() width = "285px";
    @Input() classList = "";
}