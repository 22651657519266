<kendo-treeview
    #kendoTreeViewInstance="kendoTreeView"
    kendoTreeViewExpandable
    kendoTreeViewSelectable
    kendoTreeViewHierarchyBinding 
    [nodes]="nodes"
    [filterable]="filterable"
    [filter]="filter"
    [textField]="textField"
    [hasChildren]="hasChildren"
    [children]="children"
    [childrenField]="childrenField"
    [(expandedKeys)]="expandedKeys"
    [(selectedKeys)]="selectedKeys"
    (addItem)="addItemHandler($event)"
    (checkedChange)="checkedChangeHandler($event)"
    (nodeClick)="onClickHandler($event)"
>
<ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index" *ngIf="treeViewTemplateDirective?.treeNodeTemplate">
        <ng-container   *ngTemplateOutlet="treeViewTemplateDirective?.treeNodeTemplate;context: { $implicit: dataItem, dataItem, index }">
        </ng-container>
 </ng-template>
</kendo-treeview>


