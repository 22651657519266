import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'williams-ui-platform-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  @Input() public contactDetails!: FormGroup;

  constructor() { }

  ngOnInit(): void {
  
    console.log(this.contactDetails)
  }

}
