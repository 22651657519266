import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-tilelayout-item-body',
  templateUrl: './tilelayout-item-body.component.html'
})
export class TilelayoutItemBodyComponent implements OnInit {
  @ViewChild(TemplateRef)
  tileLayoutItemBodyContent!: TemplateRef<any>;
  constructor() { }

  ngOnInit(): void {
  }
}
