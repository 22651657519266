import { ContentChild, Directive, TemplateRef } from '@angular/core';
@Directive({
  selector: 'williams-ui-platform-tab-strip-tab-template'
})
export class TabStripTabTemplateDirective {
  @ContentChild('tabTitleTemplate') tabTitleTemplate!: TemplateRef<any>;
  @ContentChild('tabContentTemplate') tabContentTemplate!: TemplateRef<any>;
  constructor() { 
  }

}