import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { GridCommandColumnActionItem, GridCommandColumnActionType, GridCommandColumnClickEvent } from "../../../models/grid";
import { GridRowDef } from "../../../models/grid-row-def";

@Component({
    selector: 'williams-ui-platform-grid-command-column-template',
    templateUrl: './grid-command-column-template.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridCommandColumnTemplate {
    @Input() gridRowCommands: GridCommandColumnActionItem[] = [];
    @Input() dataItem: any;
    @Input() disabled = false;
    @Input() rowIndex!: number;
    @Input() gridRowDef!: GridRowDef;

    @Output() onClick = new EventEmitter<GridCommandColumnClickEvent>();

    onItemClick(actionItem: GridCommandColumnActionItem) {
        this.onClick.emit({
            actionItem,
            rowIndex: this.rowIndex,
            dataItem: this.dataItem
        });
    }

    onDeleteClick(): void {
      this.onClick.emit({
        actionItem:   {
          text: "Delete",
          action: GridCommandColumnActionType.DELETE,
          disabled: false,
        },
        rowIndex: this.rowIndex,
        dataItem: this.dataItem
      });
    }

    get commandsData() {
        const disableDelete = this.dataItem[this.gridRowDef.isDeletableField] === false;
        const disableEdit =
          this.dataItem[this.gridRowDef.isEditableField] === false;

        return this.gridRowCommands.map(item => {
          let disabled = false;
          if(item.action == 'edit'){
            disabled = disableEdit
          } else if(item.action === 'delete'){
            disabled = disableDelete
          }
          return {...item, disabled}
        })
    }
}