import { Component, ContentChild, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { StepperComponent, StepType } from '@progress/kendo-angular-layout';


@Component({
  selector: 'williams-ui-platform-stepper',
  templateUrl: './williams-stepper.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./williams-stepper.component.scss']
})
export class WilliamsWrapStepperComponent  {
  @Input()
  stepsArr= [
    {label: 'Header'},
    {label: 'Nomination'},
    {label: 'Review'}
  ];
  @Input()
  stepTypeVal:StepType ='full';;
  @Input()
  currentStep= 0;
  @Output()
  currentStepVal = new EventEmitter<any>();
  @Output()
  currentAction = new EventEmitter<any>();
  stepClick(step:any){
    this.currentStepVal.emit(step);
  }
  buttonClick(e: string){
    if(e == 'prev'){
      this.currentStep -= 1;
    }
    else if( e == 'next'){
        this.currentStep += 1;
    }
    else if (e == 'submit'){
      console.log('steps complete')
    }
   
    this.currentAction.emit({action: e, step: this.currentStep});
  }
}
