import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-empty-message',
  templateUrl: './empty-message.component.html',
  styleUrls: ['./empty-message.component.scss']
})
export class EmptyMessageComponent {
  @Input() message ="";
}
