import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { map, Observable } from 'rxjs';
import { CONTACTS } from '../entity-meta-data/entity-metadata';
import { StaticResponse } from '../model/base-response';
import { ContactsInterface } from '../model/contacts.interface';
import { contactsURL } from '../../../api-endpoints';

@Injectable({ providedIn: 'root' })
export class ContactsService extends EntityCollectionServiceBase<ContactsInterface> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient) {
    super(CONTACTS,serviceElementsFactory);
  }

  getContactsData(): Observable<any> {
    return  this.http.get<StaticResponse<Array<ContactsInterface>>>(contactsURL)
      .pipe(map((res: StaticResponse<Array<ContactsInterface>>) => {
        return res;
    }));
  }
}
