import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'williams-ui-platform-criteria-form',
  templateUrl: './criteria-form.component.html',
  styleUrls: ['./criteria-form.component.scss']
})
export class CriteriaFormComponent implements OnInit {

  @Input() public criteriaDetails!: any;
  
  public comanyList: Array<string> = [
    'Amazon', 'Tata Consultancy', 'Sapient', 'Naggro',
];
  public min: Date = new Date(1917, 0, 1);
  public max: Date = new Date(2000, 11, 31);

  constructor() { }

  ngOnInit(): void {
  }

 

}
