import {
  ContentChild,
  Directive,
  forwardRef,
  TemplateRef,
} from "@angular/core";

@Directive({
  selector: "williams-ui-platform-multi-column-combobox-templates",
})
export class MultiColumnComboBoxTemplatesDirective {
  @ContentChild("columnCellTemplate")
  columnCellTemplate!: TemplateRef<any>;

  @ContentChild("columnHeaderTemplate")
  columnHeaderTemplate!: TemplateRef<any>;

  @ContentChild("fixedGroupTemplate")
  fixedGroupTemplate!: TemplateRef<any>;

  @ContentChild("footerTemplate")
  footerTemplate!: TemplateRef<any>;

  @ContentChild("groupTemplate")
  groupTemplate!: TemplateRef<any>;

  @ContentChild("headerTemplate")
  headerTemplate!: TemplateRef<any>;

  @ContentChild("noDataTemplate")
  noDataTemplate!: TemplateRef<any>;
}
