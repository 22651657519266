import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormFieldComponent } from '@progress/kendo-angular-inputs';
import { LocalizationService } from '@progress/kendo-angular-l10n';

@Component({
  selector: 'williams-ui-platform-formfield',
  templateUrl: './formfield.component.html',
  styleUrls: ['./formfield.component.scss']
})
export class FormfieldComponent extends FormFieldComponent  {


  
}
