import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-tilelayout-item-header',
  templateUrl: './tilelayout-item-header.component.html'
})
export class TilelayoutItemHeaderComponent implements OnInit {
  @ViewChild(TemplateRef)
  tileLayoutItemHeaderContent!: TemplateRef<any>;
  

  constructor() { }

  ngOnInit(): void {
  }

}
