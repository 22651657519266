import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'williams-ui-platform-link-button',
  templateUrl: './link-button.component.html'
})
export class LinkButtonComponent {
  @Input() classList = '';

  @Output() linkClick = new EventEmitter<void>();

  onLinkClick(): void {
    this.linkClick.emit();
  }
}