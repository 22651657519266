<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<williams-ui-platform-stack-layout orientation="vertical" [gap]="(showBulkDelete ||
showBulkDuplicate ||
enableRowAddition) ? '24px': ''">
  <williams-ui-platform-stack-layout>
    <williams-ui-platform-stack-layout
      class="w-base-text-slight-large-bold w-color-compTxt-1"
      >{{ tableTitle }}
    </williams-ui-platform-stack-layout>
    <williams-ui-platform-stack-layout
      orientation="vertical"
      *ngIf="
        showBulkDelete ||
        showBulkDuplicate ||
        enableRowAddition ||
        showtableTitle
      "
    >
      <div
        class="toolbar-wrapper d-flex flex-justify-end flex-align-center"
        [style.width]="'100%'"
      >
        <div class="btn-group d-flex flex-align-center">
          <williams-ui-platform-button
            fillMode="clear"
            icon="delete"
            themeColor="primary"
            classList="icon-right"
            [disabled]="selectedRows.length === 0"
            (clicked)="deleteSelectedRows()"
            *ngIf="showBulkDelete"
          >
            Delete
          </williams-ui-platform-button>
          <williams-ui-platform-button
            themeColor="primary"
            fillMode="clear"
            icon="copy"
            classList="icon-right"
            [disabled]="selectedRows.length === 0"
            (clicked)="duplicateSelectedRows()"
            *ngIf="showBulkDuplicate"
          >
            Copy
          </williams-ui-platform-button>
          <div class="w-ml-4 w-mr-2" class="vertical-bar w-ml-4 w-mr-2">
            <hr />
          </div>
          <div class="add-rows-option" *ngIf="enableRowAddition">
            <williams-ui-platform-splitbutton
              themeColor="primary"
              fillMode="outline"
              imageUrl="assets/w-ui-angular/images/quickAdd.svg"
              [data]="splitButtonData"
              arrowButtonIcon="arrow-chevron-down"
              (btnClick)="addRows(1)"
              text="Add"
            >
            </williams-ui-platform-splitbutton>
          </div>
          <ng-container *ngIf="gridHeaderAction">
            <ng-container
              *ngTemplateOutlet="gridHeaderAction.headerActionTemplate"
            >
            </ng-container>
          </ng-container>
        </div>
      </div>
    </williams-ui-platform-stack-layout>
  </williams-ui-platform-stack-layout>
  <div
    class="grid-wrapper grid-card"
    [class]="{ 'groupable-grid-wrapper': groupable }"
    kendoTooltip
    showOn="hover"
    [tooltipTemplate]="template"
    filter=".k-grid td"
    (mouseover)="showTooltip($event)"
  >
    <kendo-grid
      #grid="kendoGrid"
      [data]="gridView"
      [selectable]="selectableSettings"
      [filterable]="filterable"
      [filter]="filter"
      [sortable]="sortable"
      [sort]="sort"
      [columnMenu]="true"
      [height]="height"
      kendoGridSelectBy="rowId"
      [(selectedKeys)]="selectedRows"
      [rowClass]="rowClassCallback"
      [groupable]="groupable"
      [reorderable]="reorderable"
      [group]="groups"
      (filterChange)="filterChange($event)"
      (sortChange)="sortChange($event)"
      (groupChange)="groupChange($event)"
    >
      <kendo-grid-checkbox-column
        [showSelectAll]="true"
        [width]="40"
        [style]="{ 'text-align': 'center' }"
        [headerStyle]="{ 'text-align': 'center' }"
        [columnMenu]="false"
        *ngIf="enableSelection"
      >
      </kendo-grid-checkbox-column>
      <!-- <kendo-grid-column field="rowId" [hidden]="true" [editable]="true">
  </kendo-grid-column> -->
      <ng-container *ngFor="let def of gridColumnDefs">
        <kendo-grid-column
          [field]="def.field"
          [title]="def.title"
          [editable]="def.editable"
          [style]="{ width: def.width ? def.width + 'px' : 'auto' }"
          [headerStyle]="{ width: def.width ? def.width + 'px' : 'auto' }"
          [sortable]="isColumnSortable(def)"
          [filterable]="isColumnFilterable(def)"
          [class]="def.cssClass ?? ''"
          [headerClass]="def?.headerClass ?? ''"
          [footerClass]="def?.footerClass ?? ''"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div
              class="text-ellipsis"
              [class]="columnClassCallback(dataItem, def.field)"
            >
              {{ dataItem[def.field] }}
            </div>
          </ng-template>
          <ng-template
            kendoGridEditTemplate
            let-dataItem="dataItem"
            let-formGroup
          >
            <div
              *ngIf="
                !dataItem.isNewRecord && !def.editConfig?.edit;
                else editView
              "
              class="text-ellipsis"
              show-tooltip
              [class]="columnClassCallback(dataItem, def.field)"
            >
              {{ getColumnValue(dataItem, def.field) }}
            </div>
            <ng-template #editView>
              <div
                *ngIf="!def.editTemplate"
                [formGroup]="formGroup"
                [class]="columnClassCallback(dataItem, def.field)"
              >
                <williams-ui-platform-text-input
                  *ngIf="def.editType == 'text'"
                  type="text"
                  [formControlName]="def.formControlName ?? def.field"
                  [clearButton]="true"
                  showErrorIcon="initial"
                  [formGroup]="formGroup"
                ></williams-ui-platform-text-input>
                <williams-ui-platform-dropdown
                  *ngIf="def.editType == 'dropdown'"
                  [data]="def.dropdownOptions ?? []"
                  [textField]="def.dropdownSettings?.textField ?? 'label'"
                  [valueField]="def.dropdownSettings?.valueField ?? 'value'"
                  [valuePrimitive]="
                    def.dropdownSettings?.valuePrimitive ?? true
                  "
                  [defaultItem]="{ label: 'Select', value: null }"
                  [formControlName]="def.formControlName ?? def.field"
                  [formGroup]="formGroup"
                >
                </williams-ui-platform-dropdown>
              </div>
              <ng-container
                *ngIf="def.editTemplate"
                [ngTemplateOutlet]="def.editTemplate"
                [ngTemplateOutletContext]="{ dataItem, formGroup, formControlName: def.formControlName }"
              >
              </ng-container>
            </ng-template>
          </ng-template>
          <ng-template
            kendoGridGroupHeaderTemplate
            let-group="group"
            let-value="value"
          >
            <span>
              {{ value + ": " + group.items.length }}
            </span>
          </ng-template>
          <ng-template
            kendoGridGroupFooterTemplate
            let-aggregates="aggregates"
            let-group="group"
            let-field="field"
            let-column="column"
          >
            <div
              class="group-footer-content text-right"
              *ngIf="aggregates[field]"
            >
              {{
                column.editable
                  ? calculateGroupAggregateForEditableColumn(group, def)
                  : aggregates[field].sum
              }}
            </div>
          </ng-template>
          <ng-template *ngIf="groupable" kendoGridFooterTemplate let-column>
            <div *ngIf="def.aggregate" class="text-right">
              {{ getAggregateForColumn(def, column) }}
            </div>
          </ng-template>
        </kendo-grid-column>
      </ng-container>
      <kendo-grid-command-column
        [width]="84"
        [columnMenu]="false"
        *ngIf="showCommandColumn"
      >
        <ng-template
          kendoGridCellTemplate
          let-rowIndex="rowIndex"
          let-dataItem="dataItem"
        >
          <williams-ui-platform-stack-layout>
            <williams-ui-platform-button
              imageUrl="assets/w-ui-angular/images/delete_forever.svg"
              themeColor="primary"
              fillMode="clear"
              (clicked)="deleteRow(dataItem, $event)"
            >
            </williams-ui-platform-button>
            <kendo-dropdownbutton
              icon="more-vertical"
              [data]="gridRowCommands"
              [popupSettings]="{ align: 'right' }"
              fillMode="clear"
              (itemClick)="onCommandItemClick($event, dataItem)"
            >
            </kendo-dropdownbutton>
          </williams-ui-platform-stack-layout>
        </ng-template>
      </kendo-grid-command-column>
      <ng-template kendoGridNoRecordsTemplate>
        <p class="grid-no-data-content">{{ emptyRecordsMessage }}</p>
      </ng-template>
    </kendo-grid>
    <div
      class="aggregates w-p-2 d-flex flex-justify-between"
      *ngIf="showAggregate && _gridData.length > 0"
    >
      <div class="row-count">
        {{
          _gridData.length +
            " " +
            rowCountLable +
            (_gridData.length > 1 ? "s" : "")
        }}
      </div>
      <div class="column-aggregates d-flex" *ngIf="!groupable">
        <ng-container *ngFor="let def of gridColumnDefs">
          <div *ngIf="def.aggregate" class="ml-12">
            {{ getColumnAggregate(def) }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</williams-ui-platform-stack-layout>
