import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { map, Observable } from 'rxjs';
import { siteMapurl } from '../../../api-endpoints';
import { SITEMAP } from '../entity-meta-data/entity-metadata';
import { StaticResponse } from '../model/base-response';
import { SiteMap } from '../model/site-map.interface';

@Injectable({ providedIn: 'root' })
export class SiteMapService extends EntityCollectionServiceBase<SiteMap> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient) {
    super(SITEMAP, serviceElementsFactory);
  }

  getSiteMapData(): Observable<any> {
    return  this.http.get<StaticResponse<Array<SiteMap>>>(siteMapurl, {})
      .pipe(map((res: StaticResponse<Array<SiteMap>>) => {
        return res.data;
    }));
  }
}