import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from "@angular/core";
import {
  DropDownFillMode,
  DropDownRounded,
  DropDownSize,
  ItemArgs,
  ItemDisabledFn,
  PopupSettings,
  VirtualizationSettings,
} from "@progress/kendo-angular-dropdowns";
import { Observable } from "rxjs";
import { ComboboxColumnDefinition } from "./combobox-column";
import { MultiColumnComboBoxTemplatesDirective } from "./directives";

@Component({
  selector: "williams-ui-platform-multi-column-combobox",
  templateUrl: "./multi-column-combobox.component.html",
})
export class MultiColumnCombobox {
  @Input() allowCustom = false;
  @Input() clearButton = false;
  @Input() data: any;
  @Input() disabled = false;
  @Input() fillMode: DropDownFillMode = "outline";
  @Input() filterable = false;
  @Input() itemDisabled!: ItemDisabledFn;
  @Input() listHeight: number = 200;
  @Input() loading = false;
  @Input() placeholder = "";
  @Input() popupSettings: PopupSettings = {};
  @Input() readonly = false;
  @Input() rounded: DropDownRounded = "medium";
  @Input() size: DropDownSize = "medium";
  @Input() suggest = false;
  @Input() textField!: string;
  @Input() value: any;
  @Input() valueField!: string;
  @Input() valueNormalizer!: (text: Observable<string>) => Observable<any>;
  @Input() valuePrimitive = false;
  @Input() virtual: boolean | VirtualizationSettings = false;

  @Input() columnDefinitions!: ComboboxColumnDefinition[];

  @ContentChild(
    forwardRef(() => MultiColumnComboBoxTemplatesDirective)
  )
  multiColumnComboBoxTemplates: any;

  // Fires each time an item selection is changed
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  // Fires after the popup has been opened.
  @Input() opened: EventEmitter<any> = new EventEmitter();
  // Fires after the popup has been closed.
  @Input() closed: EventEmitter<any> = new EventEmitter();
  // Fires each time the user types in the input field. You can filter the source based on the passed filtration value
  @Input() filterChange: EventEmitter<any> = new EventEmitter();
  // Fires each time the value is changed
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.itemDisabledCallback = this.itemDisabledCallback.bind(this);
    this.valueNormalizerCallback = this.valueNormalizerCallback.bind(this);
  }

  onSelectionChange(event: any): void {
    this.selectionChange.emit(event);
  }

  onOpened(event: any): void {
    this.opened.emit(event);
  }

  onClosed(event: any): void {
    this.closed.emit(event);
  }

  onFilterChange(event: any): void {
    this.filterChange.emit(event);
  }

  onValueChange(event: any): void {
    this.valueChange.emit(event);
  }

  itemDisabledCallback(event: ItemArgs): boolean {
    if(this.itemDisabled) {
      return this.itemDisabled(event);
    }
    return false;
  }

  valueNormalizerCallback(text$: Observable<string>): Observable<any> {
    if(this.valueNormalizer) {
      return this.valueNormalizer(text$);
    }
    return text$;
  }
}
