import { formatDate } from "@angular/common";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, DEFAULT_LOCALE } from "../constant";
export const tranformDateFormat = (record: any) => {
  return {
    ...record,
    postedDate: dateFormat(record.postedDate,DEFAULT_DATE_FORMAT),
  };
};

export const tranformNoticesDate = (record: any) => {
  return {
    ...record,
    postedDate: dateFormat(record.postedDate),
    effectiveDate: dateFormat(record.effectiveDate),
  };
};

export const dateFormat = (date: string,format=DEFAULT_DATE_TIME_FORMAT,locale=DEFAULT_LOCALE) => {
  return date ? formatDate(date, format, locale):'';
};
