import { ContentChild, Directive, Input, TemplateRef } from "@angular/core";

@Directive({
  selector: 'williams-ui-platform-gridlayout-item'
})
export class GridLayoutItemDirective {
  // Sets the column of the item in the GridLayout.
  @Input() col!: number;

  // Determines how many columns will the item span.
  @Input() colSpan!: number;

  // Sets the row of the item in the GridLayout.
  @Input() row!: number;

  // Determines how many rows will the item span.
  @Input() rowSpan!: number;

  // List of (space separated) classes to be added to gridlayout item.
  @Input() classList: string = '';

  @ContentChild('itemTemplate') itemTemplate!: TemplateRef<any>;
}