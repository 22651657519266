import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { map, Observable } from 'rxjs';
import { INDEXOFCUSTOMERS } from '../entity-meta-data/entity-metadata';
import { StaticResponse } from '../model/base-response';
import { IndexOfCustomersInterface } from '../model/indexofcustomers.interface';
import { indexofCustomersUrl, pdfUrl } from '../../../api-endpoints';

@Injectable({ providedIn: 'root' })
export class IndexOfCustomersService extends EntityCollectionServiceBase<IndexOfCustomersInterface> {
  private headers = new HttpHeaders({
    'accept': 'application/json',
  });
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient) {
    super(INDEXOFCUSTOMERS,serviceElementsFactory);
  }

  fetchFileData(params: any,fileType:string): Observable<any> {
    const url = `${indexofCustomersUrl}/${fileType}`;
    return  this.http.get<any>(url, {params, headers: this.headers})
      .pipe(map((res: any) => {
        return res.data;
    }));
  }

  fetchPdfFileData(params: any): Observable<any> {
   const url = pdfUrl+params;
    return  this.http.get<StaticResponse<Array<any>>>(url,{headers: this.headers})
      .pipe(map((res: StaticResponse<Array<any>>) => {
        return res.data;
    }));
  }
}