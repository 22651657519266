<div class="layout">
    <button
      kendoButton
      (click)="drawer.toggle()"
      class="menu-toggle"
      imageUrl="assets/w-ui-angular/images/menu.svg"
      fillMode="flat"
    ></button>
  <kendo-drawer-container>
    <kendo-drawer
      #drawer
      [items]="menuItems"
      [autoCollapse]="autoCollapse"
      [mode]="mode"
      [mini]="mini"
      [width]="width"
      [(expanded)]="expanded"
      (select)="onSelect($event)"
    >
      <ng-template kendoDrawerItemTemplate let-item>
        <img class="k-icon" [src]="'assets/w-ui-angular/images/' + item['iconFlag'] + '.svg'" alt="">
        <div class="item-descr-wrap" *ngIf="expanded">
          <div>{{ item["title"] }}</div>
          <span class="item-descr">{{ item["description"] }}</span>
        </div>
      </ng-template>
    </kendo-drawer>
    <kendo-drawer-content>
      <router-outlet></router-outlet>
    </kendo-drawer-content>
  </kendo-drawer-container>
</div>
