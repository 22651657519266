import { Component, ContentChildren, forwardRef, Input, QueryList } from "@angular/core";
import { AlignSettings } from "../models";
import { GridLayoutItemDirective } from "./directives/gridlayout-item.directive";
import { GridLayoutGapSettings } from "./models";

@Component({
  selector: 'williams-ui-platform-gridlayout',
  templateUrl: './gridlayout.component.html'
})
export class GridLayoutComponent {
  // Specifies the horizontal and vertical alignment of the inner GridLayout elements.
  @Input() align!: AlignSettings;

  /*
    Specifies the number of columns and their widths.
    Accepts an array, which serves two purposes:
      The number of elements in the array defines the number of columns.
      Each array element defines the size of the corresponding column. The possible array values are:
    number - Defines the size in pixels.
    string - Enables the usage of arbitrary units e.g. 20% or auto.
    GridLayoutColSize - Configuration object, which accepts a width key
  */
  @Input() cols: any[] = [];

  // Specifies the gaps between the elements.
  @Input() gap: string | number | GridLayoutGapSettings = 0;

  /*
    Specifies the number of rows and their height.
    Accepts an array, which serves two purposes:
      The number of elements in the array defines the number of rows.
      Each array element defines the size of the corresponding row. The possible array values are:
    number - Defines the size in pixels.
    string - Enables the usage of arbitrary units e.g. 20% or auto.
    GridLayoutRowSize - Configuration object, which accepts a height key.
  */
  @Input() rows: any[] = [];

  // Class list(space separated)
  @Input() classList = '';

  @ContentChildren(forwardRef(() => GridLayoutItemDirective), { read: GridLayoutItemDirective }) gridLayoutItems!: QueryList<GridLayoutItemDirective>;
  
  get items(): GridLayoutItemDirective[] {
    return this.gridLayoutItems.toArray();
  }
}