import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { GridColumnDef } from "../../../models/grid-column-def";

@Component({
    selector: 'williams-ui-platform-grid-edit-template',
    templateUrl: './grid-edit-template.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridEditTemplate {
    @Input() group!: FormGroup;
    @Input() dataItem: any;
    @Input() columnDef!: GridColumnDef;
    @Input() columnClassCallback!: (dataItem: any, field: string) => string | string[] | { [key: string]: any } | Set<string>;
}