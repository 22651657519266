import { Component, Input } from "@angular/core";
import { BadgeAlign, BadgeFill, BadgePosition, BadgeRounded, BadgeSize, BadgeThemeColor } from "./models";

/**
 * Example usage
 * 
 * <williams-ui-platform-badge
 *      [align]= { vertical: 'top', horizontal: 'start' }
 *      [cutoutBorder]="false"
 *      fill="solid"
 *      position="inside"
 *      rounded="full"
 *      size="medium"
 *      themeColor="theme-color"
 *      classList="class1 class2"
 *      [customStyles]={ key: value }>
 * <williams-ui-platform-badge>
 */

@Component({
    selector: 'williams-ui-platform-badge',
    templateUrl: './badge.component.html'
})
export class BadgeComponent {
    // Specifies the alignment of the Badge
    @Input() align: BadgeAlign = {
        vertical: 'top',
        horizontal: 'start'
    };
    // Specifies whether or not to render additional cutout border around the Badge
    @Input() cutoutBorder = false;
    // Specifies the appearance fill style of the Badge 
    @Input() fill: BadgeFill = 'solid';
    // Specifies the position of the Badge relative to the edge of the parent container element
    @Input() position: BadgePosition = 'inside';
    // Specifies the roundness of the Badge 
    @Input() rounded: BadgeRounded = 'full';
    // Specifies the size of the Badge
    @Input() size: BadgeSize = 'large';
    // Specifies the theme color of the Badge.
    @Input() themeColor: BadgeThemeColor = 'primary';
    @Input() classList = '';
    @Input() customStyles: { [key: string]: string } = {};
}