export enum GridCommandColumnActionType {
  EDIT = "edit",
  COPY =  "copy",
  DELETE = "delete"
}
export interface GridCommandColumnActionItem {
  text: string;
  action: GridCommandColumnActionType;
  disabled: boolean;
}

export type GridCommandColumnActionItems = GridCommandColumnActionItem[];

export interface GridCommandColumnClickEvent {
  actionItem: GridCommandColumnActionItem;
  rowIndex: number;
  dataItem: any;
}

export interface GridExportOption {
  text: string;
  val: 'Excel' | 'PDF' | 'CSV';
}