import { Component, Input } from "@angular/core";
import { AlignSettings, Orientation } from "../models";

export type ThemeColor = 'primary' | 'base';

@Component({
    selector: 'williams-ui-platform-stack-layout',
    templateUrl: './stack-layout.component.html'
})
export class StackLayoutComponent {
    // Specifies the horizontal and vertical alignment of the inner StackLayout elements
    @Input() align: AlignSettings = {};
    // Specifies the gap between the inner StackLayout elements. 
    @Input() gap: string = '8px';
    // Specifies the orientation of the StackLayout.
    @Input() orientation: Orientation = 'horizontal';

    @Input() themeColor: ThemeColor | any = 'base';
}