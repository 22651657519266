<williams-ui-platform-stack-layout orientation="vertical" [gap]="'8'">
  <williams-ui-platform-stack-layout class="w-px-8 w-py-6">
    <h1 class="w-m-0 w-h1-bold w-color-compTxt-1">{{PAGE_ERROR}}</h1>
  </williams-ui-platform-stack-layout>
  <williams-ui-platform-stack-layout class="w-px-8" orientation="horizontal">
    <williams-ui-platform-card classList="border-radius-xsm w-bg-info-1 d-flex w-color-white-1" [width]="'60%'">
      <williams-ui-platform-stack-layout orientation="horizontal">
        <williams-ui-platform-stack-layout class="flex-grow-0" style="margin-top:2px">
          <img src="assets/w-ui-angular/images/info-icon.png" alt="" />
        </williams-ui-platform-stack-layout>
        <williams-ui-platform-stack-layout>
          <div class="w-m-0 w-h1-bold">{{PAGE_NOT_AVAILABLE}}</div>
        </williams-ui-platform-stack-layout>
      </williams-ui-platform-stack-layout>
    </williams-ui-platform-card>
  </williams-ui-platform-stack-layout>
</williams-ui-platform-stack-layout>