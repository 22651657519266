<williams-ui-platform-stack-layout>
  <williams-ui-platform-stack-layout class="w-px-8 w-py-6 w-color-compTxt-1">
    <h1 class="w-m-0">Discovery Gas Transmission LLC</h1>
  </williams-ui-platform-stack-layout>
  <williams-ui-platform-stack-layout [align]="{horizontal:'end'}" class="w-px-8 w-py-6">
    <williams-ui-platform-stack-layout [align]="{horizontal:'start'}">
      <img src="assets/w-ui-angular/images/calendar.svg" alt="">
      <div class="w-color-compTxt-1">
        <h6 class="w-small-text-normal w-m-0">
          {{date | date: "EEEE" }}
        </h6>
        <p class="w-h1-bold w-m-0">
          {{date | date:'longDate'}}
        </p>
      </div>
    </williams-ui-platform-stack-layout>
    <img src="assets/w-ui-angular/images/verticle-line.svg" alt="">
    <williams-ui-platform-stack-layout [align]="{horizontal:'start'}">
      <img src="assets/w-ui-angular/images/phone_red.svg" alt="">
      <div>
        <h6 class="w-small-text-normal w-m-0 w-color-error-pt9">
          24-Hour Pipeline Emergency
        </h6>
        <p class="w-h1-bold w-m-0 w-color-error-pt9">1-800-972-7733</p>
      </div>
    </williams-ui-platform-stack-layout>
  </williams-ui-platform-stack-layout>
</williams-ui-platform-stack-layout>
<williams-ui-platform-stack-layout>
  <williams-ui-platform-bread-crumb #someInput [items]="breadCrumb" [ngStyle]="showBreadCrumb ? {'display': ''} : {'display': 'none'}"  [pathDisplayMap]="pathDisplayMap" class="w-bg-white-pt9 w-px-6 w-py-1"></williams-ui-platform-bread-crumb>
</williams-ui-platform-stack-layout>
