import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonThemeColor,
} from "@progress/kendo-angular-buttons";

@Component({
  selector: 'williams-ui-platform-action-header',
  templateUrl: './action-header.component.html',
  styleUrls: ['./action-header.component.scss']
})
export class ActionHeaderComponent implements OnInit {
  public themeColor: ButtonThemeColor = "primary";
  @Input() menuItems:any= [];
  @Input() headerTitle:string="";
  @Input() headerAction: {
        title: string;
        action: string;
  }= {
      title:'',
      action:''
  };
  @Output() headerActionClick = new EventEmitter<{baseEvent:Event, action:string}>();
  @Input() buttonClass = 'btn-primary';
  @Input() buttonIcon = '';
  constructor(private router:Router, private route:ActivatedRoute){}
  ngOnInit(): void {
  }
  actionClickHandler(baseEvent:Event, action:string):void{ 
     this.headerActionClick.emit({baseEvent, action});
  }
}
