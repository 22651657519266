import { Component, OnInit } from '@angular/core';
import { PAGE_NOT_AVAILABLE, PAGE_ERROR } from '../constant';

@Component({
  selector: 'williams-ui-platform-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  PAGE_NOT_AVAILABLE = PAGE_NOT_AVAILABLE;
  PAGE_ERROR = PAGE_ERROR;
  constructor() { }

  ngOnInit(): void {
    
  }
}
