<williams-ui-platform-stack-layout orientation="vertical" class="w-color-compTxt-pt9" [gap]="'0'">
    <williams-ui-platform-stack-layout class="w-px-8 w-py-4">
        <h3 class="w-m-0">{{INDEX_OF_CUSTOMERS_TITLE}}</h3>
    </williams-ui-platform-stack-layout>
    <williams-ui-platform-stack-layout orientation="vertical" class="d-flex w-bg-white-pt9 w-px-8 w-py-4">
        <span>{{VIEW_DOWNLOAD_IOC_TITLE}}</span>
        <williams-ui-platform-stack-layout orientation="vertical" class="">
            <williams-ui-platform-stack-layout>
                <form [formGroup]="form">
                    <div class="w-pt-8">
                        <williams-ui-platform-label [for]="filetype"
                            text={{FILE_TYPE}}></williams-ui-platform-label>
                        <williams-ui-platform-dropdown textField="name" valueField="value" [valuePrimitive]="true"
                            (selectionChange)="selectionChange($event)" [data]="fileTypeDropdownData" [formGroup]="form"
                            formControlName="filetype" #filetype>
                        </williams-ui-platform-dropdown>
                    </div>
                    <div class="w-pt-8">
                        <williams-ui-platform-button classList="btn-outlined w-h1-bold" class="w-pr-1"
                            themeColor="primary"
                            fillMode="outline"
                            [imageUrl]="eyeIcon"
                            (mouseover)="eyeIcon=activedEyeIcon"
                            (mouseout)="eyeIcon=hoveredEyeIcon"
                            (click)="view()">{{VIEW}}</williams-ui-platform-button>
                        <williams-ui-platform-button classList="btn-outlined w-h1-bold" fillMode="outline"
                            themeColor="primary"
                            [imageUrl]="downLoadIcon"
                            (mouseover)="downLoadIcon=activedownLoadIcon"
                            (mouseout)="downLoadIcon=hovereddownLoadIcon"
                            (click)="download()">
                            {{DOWNLOAD}}</williams-ui-platform-button>
                    </div>
                </form>
            </williams-ui-platform-stack-layout>
        </williams-ui-platform-stack-layout>
    </williams-ui-platform-stack-layout>
</williams-ui-platform-stack-layout>