import { ContentChild, Directive, TemplateRef } from "@angular/core";

@Directive({
    selector: 'williams-ui-platform-drawer-templates'
})
export class DrawerTemplatesDirective {
    @ContentChild('headerTemplate') headerTemplate!: TemplateRef<any>;
    @ContentChild('footerTemplate') footerTemplate!: TemplateRef<any>;
    @ContentChild('itemTemplate') itemTemplate!: TemplateRef<any>;
    @ContentChild('drawerTemplate') drawerTemplate!: TemplateRef<any>;

    constructor() { }
}
