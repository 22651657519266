<kendo-panelbar *ngIf="items.length === 0"
                [animate]="animate"
                [expandMode]="expandMode"
                [height]="height"
                [keepItemContent]="keepItemContent"
                [selectable]="selectable"
                (collapse)="onCollapse($event)"
                (expand)="onExpand($event)"
                (select)="onSelect($event)"
                (stateChange)="onStateChange($event)"
>

    <kendo-panelbar-item *ngFor="let item of barsContentChildren"
                         [content]="item.content"
                         [disabled]="item.disabled"
                         [expanded]="item.expanded"
                         [icon]="item.icon"
                         [iconClass]="item.iconClass"
                         [id]="item.id"
                         [imageUrl]="item.imageUrl"
                         [selected]="item.selected"
                         [title]="item.title"
    >
        <ng-template kendoPanelBarItemTitle *ngIf="item.titleTemplate">
            <ng-container *ngTemplateOutlet="item.titleTemplate"></ng-container>
        </ng-template>
        <kendo-panelbar-item *ngFor="let sub of item.subItems"
                             [content]="sub.content"
                             [disabled]="sub.disabled"
                             [expanded]="sub.expanded"
                             [icon]="sub.icon"
                             [iconClass]="sub.iconClass"
                             [id]="sub.id"
                             [imageUrl]="sub.imageUrl"
                             [selected]="sub.selected"
                             [title]="sub.title">
            <ng-template kendoPanelBarItemTitle *ngIf="sub.titleTemplate">
                <ng-container *ngTemplateOutlet="sub.titleTemplate"></ng-container>
            </ng-template>
            <kendo-panelbar-item *ngFor="let child of sub.subItems"
                                 [content]="child.content"
                                 [disabled]="child.disabled"
                                 [expanded]="child.expanded"
                                 [icon]="child.icon"
                                 [iconClass]="child.iconClass"
                                 [id]="child.id"
                                 [imageUrl]="child.imageUrl"
                                 [selected]="child.selected"
                                 [title]="child.title">
                <ng-template kendoPanelBarItemTitle *ngIf="child.titleTemplate">
                    <ng-container *ngTemplateOutlet="child.titleTemplate"></ng-container>
                </ng-template>
            </kendo-panelbar-item>
        </kendo-panelbar-item>
    </kendo-panelbar-item>

</kendo-panelbar>

<kendo-panelbar *ngIf="items.length !== 0"
                [animate]="animate"
                [expandMode]="expandMode"
                [height]="height"
                [items]="items"
                [keepItemContent]="keepItemContent"
                [selectable]="selectable"
                (collapse)="onCollapse($event)"
                (expand)="onExpand($event)"
                (select)="onSelect($event)"
                (stateChange)="onStateChange($event)"
>
</kendo-panelbar>
