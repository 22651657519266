import { TemplateRef } from "@angular/core";

export interface GridColumnDef {
    field: string;
    title: string;
    editable: boolean;
    // Flags to enable/disable editing columns based on type of action
    editConfig?: {
        add: boolean;
        edit: boolean;
    };
    editType?: 'text' | 'dropdown';
    editTemplate?: TemplateRef<any>;
    dropdownOptions?: { [key: string]: any }[];
    dropdownSettings?: {
        valuePrimitive: boolean;
        valueField: string;
        textField: string;
    },
    aggregate?: {
        show: true;
        label: string;
        total?: number;
    };
    width?: number;
    sticky?:boolean;
    isHyperlink?:boolean;
    formControlName?:string;
    sortable?: boolean;
    filterable?: boolean;
    cssClass?: string;
    columnMenu?:boolean;
    headerClass?: string;
    footerClass?: string;
}