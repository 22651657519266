import { Injectable } from '@angular/core';
import {
    DefaultDataService,
    HttpUrlGenerator
  } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { PLANNEDSERVICEOUTAGES } from '../entity-meta-data/entity-metadata';
import { NoticesInterface } from '../model/notices.interface';

@Injectable()
export class PlannedOutagesDataService extends DefaultDataService<NoticesInterface> {
  constructor(http: HttpClient, 
              httpUrlGenerator: HttpUrlGenerator
              ) {
    super(PLANNEDSERVICEOUTAGES, http, httpUrlGenerator);
  }
}