<williams-ui-platform-card [width]="width" [orientation]="orientation" [classList]="classList">
    <div class="badge-wrapper" header>
        <ng-content select="[badge-content]"></ng-content>
    </div>
    <p class="w-base-text-large-bold">
        <ng-content></ng-content>
    </p>
    <p class="w-m-0" footer>
        <ng-content select="[footer]"></ng-content>
    </p>
</williams-ui-platform-card>