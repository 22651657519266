<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div
  class="grid-card"
  [ngClass]="{ 'is-loading': loading }"
  kendoTooltip
  showOn="hover"
  [tooltipTemplate]="template"
  filter=".k-grid td"
  (mouseover)="showTooltip($event)">
  <kendo-grid
    #grid="kendoGrid"
    [data]="gridView"
    [selectable]="selectableSettings"
    [filterable]="filterable"
    [filter]="gridState.filter"
    [sortable]="sortable"
    [sort]="gridState.sort"
    [columnMenu]="gridColumnMenuSettings"
    [height]="height"
    [loading]="loading"
    [scrollable]="scrollable"
    [rowHeight]="rowHeight"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [resizable]="false"
    [rowClass]="rowClassCallback"
    [reorderable]="columnsReorderable"
    (selectionChange)="onSelectionChange($event)"
    [groupable]="enableGridGrouping"
    [group]="gridState.group"
    (dataStateChange)="onDataStateChange($event)"
  >
    <ng-template kendoGridToolbarTemplate *ngIf="showActionButtons || tableTitle">
      <div class="d-flex flex-align-center toolbar-wrapper" [style.width]="'100%'">
        <h4 class="title flex-1">{{ tableTitle }}</h4>
        <div class="btn-group" *ngIf="showActionButtons">
          <kendo-dropdownbutton
            *ngIf="pdfExport && !showSubmitCancelButtons"
            [data]="exportOptions"
            imageUrl="assets/w-ui-angular/images/export.svg"
            fillMode="clear"
            (itemClick)="exportClickHandler($event, grid)"
          >
            Export
          </kendo-dropdownbutton>
          <williams-ui-platform-button
            *ngIf="showBulkDelete"
            fillMode="clear"
            imageUrl="assets/w-ui-angular/images/delete.svg"
            (clicked)="onBulkDelete()"
            [disabled]="disableBulkDelete"
          >
            Delete
          </williams-ui-platform-button>
          <williams-ui-platform-button
            *ngIf="!showSubmitCancelButtons && showBulkCopy"
            fillMode="clear"
            imageUrl="assets/w-ui-angular/images/copy.svg"
            (clicked)="onBulkCopy()"
            [disabled]="selectedRows.length === 0"
          >
            Copy
          </williams-ui-platform-button>
        </div>
        <div class="quick-actions d-flex" *ngIf="showActionButtons">
          <williams-ui-platform-button
            *ngIf="showBulkEditButton"
            classList="btn-outlined"
            imageUrl="assets/w-ui-angular/images/bulkEdit.svg"
            [disabled]="disableBulkEdit"
            (clicked)="onBulkEdit()"
            >Bulk edit</williams-ui-platform-button
          >
          <williams-ui-platform-button
            *ngIf="showQuickAddButton"
            classList="btn-outlined"
            imageUrl="assets/w-ui-angular/images/quickAdd.svg"
            [disabled]="disableQuickAdd"
            (clicked)="onQuickAdd()"
            >Add</williams-ui-platform-button
          >
          <div class="submit-cancel" *ngIf="showSubmitCancelButtons">
            <williams-ui-platform-button
              classList="btn-primary"
              imageUrl="assets/w-ui-angular/images/checkmark.svg"
              (clicked)="onSubmit()"
              >Submit</williams-ui-platform-button
            >
            <williams-ui-platform-button
              classList="btn-clear"
              imageUrl="assets/w-ui-angular/images/close.svg"
              (clicked)="onCancel()"
              >Cancel</williams-ui-platform-button
            >
          </div>
        </div>
      </div>
    </ng-template>
    <kendo-grid-checkbox-column
      [showSelectAll]="true"
      [width]="50"
      [sticky]="sticky"
      [columnMenu]="false"
      class="text-center"
      headerClass="text-center"
      *ngIf="enableSelection"
    ></kendo-grid-checkbox-column>
    <ng-container *ngFor="let def of gridColumnDefs">
      <kendo-grid-column
        [field]="def.field"
        [title]="def.title"
        [sticky]="def.sticky ?? false"
        [width]="def.width ?? 150"
        [editable]="isEditable(def)"
        [style]="{ width: def.width ? def.width + 'px' : 'auto' }"
        [headerStyle]="{ width: def.width ? def.width + 'px' : 'auto' }"
        [filterable]="!isEditing"
        [sortable]="!isEditing"
        [class]="def.cssClass ?? ''"
        [columnMenu]="def.columnMenu ?? true"
        [headerClass]="def?.headerClass ?? ''"
        [footerClass]="def?.footerClass ?? ''"
      >
    <ng-container *ngIf="isAggregate(def.field)">
      <ng-template kendoGridGroupFooterTemplate  let-aggregates="aggregates">
        <span>
          {{getAggregatesLabel(def.field)}} : {{getAggregatesValue(aggregates,def.field)}}
        </span>
      </ng-template>
    </ng-container>
      

        <ng-template kendoGridCellTemplate let-dataItem>
          <williams-ui-platform-grid-cell-template
            [dataItem]="dataItem"
            [columnDef]="def"
            [columnClassCallback]="columnClassCallback"
            (hyperlinkClick)="hyperlinkClick.emit($event)">
          </williams-ui-platform-grid-cell-template>
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-formGroup
        >
          <ng-container
            *ngIf="def.editTemplate"
            [ngTemplateOutlet]="def.editTemplate"
            [ngTemplateOutletContext]="{ dataItem, formGroup, formControlName: def.formControlName }">
          </ng-container>
          <williams-ui-platform-grid-edit-template
            *ngIf="!def.editTemplate"
            [dataItem]="dataItem"
            [columnDef]="def"
            [group]="formGroup"
            [columnClassCallback]="columnClassCallback">
          </williams-ui-platform-grid-edit-template>
        </ng-template>
      </kendo-grid-column>
    </ng-container>
    <kendo-grid-command-column
      [width]="40"
      [columnMenu]="false"
      *ngIf="showCommandColumn"
      class="command-cell"
    >
      <ng-template
        kendoGridCellTemplate
        let-rowIndex="rowIndex"
        let-dataItem="dataItem"
        let-column="column"
      >
        <williams-ui-platform-grid-command-column-template
          [dataItem]="dataItem"
          [rowIndex]="rowIndex"
          [gridRowCommands]="gridRowCommands"
          [gridRowDef]="gridRowDef"
          [disabled]="disableCommandColumnActions"
          (onClick)="onCommandItemClick($event)">
        </williams-ui-platform-grid-command-column-template>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-excel
      *ngIf="excelExport"
      [fileName]="excelFileName"
    ></kendo-grid-excel>
    <kendo-grid-pdf
      *ngIf="pdfExport"
      [paperSize]="pdfPaperSize"
      [fileName]="pdfFileName"
      [allPages]="true"
      [repeatHeaders]="true"
      [landscape]="true"
    >
    <kendo-grid-pdf-margin
      *ngIf="pdfMargin"
      [top]="pdfMargin.top ?? 0"
      [left]="pdfMargin.left ?? 0"
      [right]="pdfMargin.right ?? 0"
      [bottom]="pdfMargin.bottom ?? 0"
    >
    </kendo-grid-pdf-margin>
    <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages" 
    *ngIf="gridPdfTemplate?.pdfTemplate">
      <ng-container   *ngTemplateOutlet="gridPdfTemplate?.pdfTemplate;context: { $implicit: pageNum,pageNum,totalPages }">
      </ng-container>
    </ng-template>
    </kendo-grid-pdf>
    <ng-template kendoGridLoadingTemplate>
      <div class="loading d-flex flex-align-center">
        <div class="loader"></div>
        <div class="text">Loading</div>
      </div>
    </ng-template>
    <ng-template kendoGridNoRecordsTemplate>
      <p class="grid-no-data-content">{{ emptyRecordsMessage }}</p>
   </ng-template>
   <kendo-grid-messages filterClearButton="Cancel" filterFilterButton="Set"></kendo-grid-messages>
  </kendo-grid>
  <williams-ui-platform-grid-aggregate
    *ngIf="showAggregate && gridView.total > 0"
    [gridColumnDefs]="gridColumnDefs"
    [gridView]="gridView"
    [rowsInEditMode]="rowsInEditMode"
    [rowCountLabel]="rowCountLable"
    [showGridViewTotal]="showGridViewTotal"
    [groupable]="enableGridGrouping"
  >
  </williams-ui-platform-grid-aggregate>
</div>
