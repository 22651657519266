import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { PDF_VIEWER } from "../../constant";

@Component({
  selector: "williams-ui-platform-download",
  templateUrl: "./download.component.html",
  styleUrls: ["./download.component.scss"],
})
export class DownloadComponent implements OnInit {
  data = [
    {
      name: "Current Tariff",
      url: `${PDF_VIEWER}?fileName=discovery_trf.pdf`,
    },
    {
      name: "Current Rate Schedule Text",
      url: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=ratesched`,
    },
    {
      name: "Current General Terms and Conditions",
      url: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=gtc`,
    },
    {
      name: "Credit Application",
      url: `${PDF_VIEWER}?fileName=credit_application.pdf`,
    },
    {
      name: "Offer to Purchase Released Capacity",
      url: `${PDF_VIEWER}?fileName=DiscoveryPurchaseRequestOrderForm.pdf`,
    },
  ];

  downloadableForm = [
    {
      name: "Service Agreement - FT1 Rate Schedule",
      url: `${PDF_VIEWER}?fileName=FT1ServiceAgreement.pdf`,
    },
    {
      name: "Service Agreement - FT2 Rate Schedule",
      url: `${PDF_VIEWER}?fileName=FT2ServiceAgreement.pdf`,
    },
    {
      name: "Service Agreement - IT Rate Schedule",
      url: `${PDF_VIEWER}?fileName=ITServiceAgreement.pdf`,
    },
    {
      name: "Capacity Release Transaction Service Agreement",
      url: `${PDF_VIEWER}?fileName=FormOfService.pdf`,
    },
    {
      name: "Request for Service",
      url: `${PDF_VIEWER}?fileName=RequestForService.pdf`,
    },
  ];
  constructor() {}
  ngOnInit(): void {}
}
