import { Component, forwardRef, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckBoxRounded, InputSize } from '@progress/kendo-angular-inputs';
import { BaseAccessor } from '../base/base-accessor';


@Component({
  selector: 'williams-ui-platform-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent extends BaseAccessor {
  @Input() size: InputSize = 'medium';
  @Input() rounded: CheckBoxRounded = 'medium';
  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }


}
