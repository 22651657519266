<kendo-tilelayout
  [rowHeight]="rowHeight"
  [columns]="columns"
  [columnWidth]="columnWidth"
  [gap]="gap"
  [autoFlow]="autoFlow"
  [resizable]="resizable"
  [reorderable]="reorderable"
  (reorder)="reorderHandler($event)"
  (resize)="resizeHandler($event)"
>
  <kendo-tilelayout-item
    [title]="item.title"
    [col]="item.col"
    [colSpan]="item.colSpan"
    [order]="item.order"
    [reorderable]="item.reorderable"
    [resizable]="item.resizable"
    [row]="item.row"
    [rowSpan]="item.rowSpan"
    *ngFor="let item of items"
  >
    <kendo-tilelayout-item-header>
      <ng-container *ngIf="item?.tilelayoutItemHeaderComponent">
        <ng-container
          *ngTemplateOutlet="
            item?.tilelayoutItemHeaderComponent?.tileLayoutItemHeaderContent
          "
        >
        </ng-container>
      </ng-container>
    </kendo-tilelayout-item-header>
    <kendo-tilelayout-item-body>
      <ng-container *ngIf="item?.tilelayoutItemBodyComponent">
        <ng-container
          *ngTemplateOutlet="
            item?.tilelayoutItemBodyComponent?.tileLayoutItemBodyContent
          "
        >
        </ng-container>
      </ng-container>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>
