import { Component, forwardRef, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropDownFillMode, DropDownRounded, DropDownSize, PopupSettings } from '@progress/kendo-angular-dropdowns';
import { BaseAccessor } from '../base/base-accessor';
@Component({
  selector: 'williams-ui-platform-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true
    }
  ],
})
export class MultiselectComponent extends BaseAccessor  {
  public isChecked = false;
  @Input() showSelectAll: boolean = false;
  @Input() selectAllText! : string;
  @Input() data: any[] = [];
  @Input('data')
  set in(val: any) {
    this.dropDownData = val;
    this.data = val;
  }
  @Input() valueField!: string;
  @Input() filterable:boolean = true;
  @Input() textField!: string;
  @Input() value!:any[];
  @Input() valuePrimitive = true;
  @Input() size:DropDownSize= "medium";
  @Input() popupSettings:PopupSettings={
      width:100
  };
  @Input() summaryTagCount: number = 1;
  dropDownData:any;
  @Input()
  placeholder:string = 'Select';
  @Input() showErrors = false;
  hideSearchBar = false;
  @Input() fillMode: DropDownFillMode = 'solid';
  @Input() rounded: DropDownRounded = 'full';
  @Input() disabled = false;
  @Input() readonly = false;
  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
  }

  handleFilter(value:any) {
     this.data = this.dropDownData.filter(
      (s:any) => this.textField ? s[this.textField].toLowerCase().indexOf(value.toLowerCase()) !== -1 : 
                  s.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  getTooltipTextForGroupTag(items: any[]): string {
    return items.map(item => item[this.textField] ?? item).join(', ')
  }
  
  public onClick(value:any) {
    this.isChecked = !this.isChecked;
    this.value = (this.isChecked) ? this.data.slice() : [];
    this.control.patchValue(this.value)
  }
  public get toggleAllText() {
    return this.selectAllText;
  }
  public onValueChange(value:any) {
    this.isChecked = value.length === this.data.length;
  }
  public isItemSelected(itemText: string): boolean {
    return this.value.some(item => item.text === itemText);
  }

}
