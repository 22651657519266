import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { merge, Subject, takeUntil } from "rxjs";
import { GridColumnDef } from "../../../models/grid-column-def";
import { flattenGridView } from "../../utils";

@Component({
  selector: "williams-ui-platform-grid-aggregate",
  templateUrl: "./grid-aggregate.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridAggregate implements OnChanges, OnDestroy {
  @Input() gridView!: GridDataResult;
  @Input() rowsInEditMode: {
    rowId: number;
    group: FormGroup;
  }[] = [];
  @Input() rowCountLabel!: string;
  @Input() gridColumnDefs!: GridColumnDef[];
  @Input() showGridViewTotal!: boolean;
  @Input() groupable!: boolean;

  private destroyFormValueSubscription$ = new Subject<boolean>();

  constructor(private cd: ChangeDetectorRef) {}

  getColumnAggregate(def: GridColumnDef): string {
    const label = def.aggregate?.label ?? "total" + def.title;
    if (def.aggregate?.total) {
      return `${def.aggregate?.total} ${label}}`;
    }
    let total = 0;

    const gridData: any[] = this.groupable ? flattenGridView(this.gridView.data) : this.gridView.data;
    // Looping through values from rows in view (from variable gridView) and adding field's value to total. If any row is in edit mode then value for that row is taken from formGroup
    gridData.forEach((dataItem) => {
      const valueFromForm = this.rowsInEditMode.find(
        (item) => item.rowId === dataItem.rowId
      )?.group.value;
      if (valueFromForm) {
        // Reading value using 'formControlName' key if it exists or else falling back to fethcing using 'field' key
        total += +valueFromForm[def.formControlName ?? def.field];
      } else {
        total += +dataItem[def.field];
      }
    });

    if (isNaN(total)) {
      total = 0;
    }

    return `${total} ${label}`;
  }

  handleFormValueChange(): void {
    this.destroyFormValueSubscription$.next(true);

    const formGroupValueChanges = this.rowsInEditMode.map(
      (item) => item.group.valueChanges
    );
    merge(...formGroupValueChanges)
    .pipe(takeUntil(this.destroyFormValueSubscription$))
    .subscribe(
      () => {
        this.cd.markForCheck();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["gridView"] || changes["gridColumnDefs"]) {
      this.cd.markForCheck();
    }

    if (changes["rowsInEditMode"] || changes["gridView"]) {
      this.handleFormValueChange();
    }
  }

  ngOnDestroy(): void {
    this.destroyFormValueSubscription$.next(true);
    this.destroyFormValueSubscription$.complete();
  }
}
