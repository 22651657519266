import { ContentChild, Directive, NgModule, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';

@Directive({
  selector: 'williams-ui-platform-grid-header-actions'
})
export class GridHeaderActionsDirective {
  @ContentChild('headerActionTemplate') headerActionTemplate!: TemplateRef<any>;
  constructor() { }

}


