import { Component } from "@angular/core";
/**
 * Example usage
 * 
 * <williams-ui-platform-badge-container>
 *      <williams-ui-platform-badge>
 *      <williams-ui-platform-badge>
 *      // Any other content
 *      .....
 *      .....
 * <williams-ui-platform-badge-container>
 */

@Component({
    selector: 'williams-ui-platform-badge-container',
    templateUrl: './badge-container.component.html'
})
export class BadgeContainerComponent {

}