<kendo-breadcrumb
  #kendoBreadCrumbInstance="kendoBreadCrumb"
  [items]="items"
  [collapseMode]="collapseMode"
  [separatorIcon]="separatorIcon"
  (itemClick)="onItemClick($event)"
>
 
<ng-template kendoBreadCrumbItemTemplate let-item let-index="index" *ngIf="breadCrumbTemplateDirective?.breadCrumbTemplate">
    <ng-container   *ngTemplateOutlet="breadCrumbTemplateDirective?.breadCrumbTemplate;context: { $implicit: item, item, index }">
    </ng-container>
</ng-template>
</kendo-breadcrumb>
