import { CellOptions } from "@progress/kendo-angular-excel-export";

export interface ExcelOptions {
  // Specifies if the groups in the Excel file are collapsible.
  collapsible: boolean;

  // The author of the workbook.
  creator: string;

  // Specifies the file name of the exported Excel file.
  fileName: string;

  // Enables or disables column filtering in the Excel file. This behavior is different from the filtering feature of the Grid.
  filterable: boolean;

  // If the data is grouped, the options of the cells that are inserted before the header cells to align the headers and the column values.
  headerPaddingCellOptions: CellOptions;
  
  // If the data is grouped, the options of the cells that are inserted before the data, group, and footer cells to indicate the group hierarchy.
  paddingCellOptions: CellOptions;
}