import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  CardActionsComponent,
  CardBodyComponent,
  CardFooterComponent,
  CardHeaderComponent,
} from "@progress/kendo-angular-layout";
import { Orientation } from "../models";
import { ActionsLayout, CardAction } from "./models";

/**
 * Example usage
 * 
 * <williams-ui-platform-card classList="w-custom-class">
 *    // Card header content
 *    <div header>
 *      ...
 *    </div>
 *    // Defalut content - it will be rendered inside card body
 *    <p>
 *      ...
 *    </p>
 *    // Card footer content
 *    <p footer>
 *      ...
 *    </p>
 * </williams-ui-platform-card>
 */


@Component({
  selector: "williams-ui-platform-card",
  templateUrl: "./card.component.html",
})
export class CardComponent implements AfterViewInit {
  @Input() orientation: Orientation = "vertical";
  @Input() width = "285px";
  @Input() classList = "";

  // Inputs for card actions component
  @Input() actions!: CardAction[] | TemplateRef<any>;
  @Input() actionsLayout: ActionsLayout = "stretched";
  @Input() actionsOrientation: Orientation = "horizontal";

  @Output() action = new EventEmitter<CardAction>();

  @ViewChild(forwardRef(() => CardHeaderComponent), { read: ElementRef })
  cardHeader!: ElementRef;
  @ViewChild(forwardRef(() => CardBodyComponent), { read: ElementRef })
  cardBody!: ElementRef;
  @ViewChild(forwardRef(() => CardFooterComponent), { read: ElementRef })
  cardFooter!: ElementRef;
  @ViewChild(forwardRef(() => CardActionsComponent), { read: ElementRef })
  cardActions!: ElementRef;

  showHeader = true;
  showFooter = true;
  showBody = true;
  showActions = true;

  ngAfterViewInit() {
    setTimeout(() => {
        this.showHeader = this.cardHeader.nativeElement.childElementCount > 0;
        this.showBody = this.cardBody.nativeElement.childElementCount > 0;
        this.showFooter = this.cardFooter.nativeElement.childElementCount > 0;
        this.showActions =
          !!this.actions || this.cardActions.nativeElement.childElementCount > 0;
          console.log(this.actions)
          console.log(this.cardActions)
    }, 0);
  }

  onAction(event: CardAction): void {
    this.action.emit(event);
  }
}
