import {
  SelectEvent as KendoSelectEvent,
  TabAlignment as KendoTabAlignment,
  TabCloseEvent as KendoTabCloseEvent,
  TabPosition as KendoTabPosition,
  TabStripScrollableSettings as KendoTabStripScrollableSettings,
} from "@progress/kendo-angular-layout";


import { SVGIcon as KendoSVGIcon } from "@progress/kendo-svg-icons";

export interface SVGIcon extends KendoSVGIcon {}

export interface TabStripScrollableSettings
  extends KendoTabStripScrollableSettings {}

export declare type TabAlignment = KendoTabAlignment;

export declare type TabPosition = KendoTabPosition;

export declare class TabCloseEvent extends KendoTabCloseEvent{

}
export declare class SelectEvent extends KendoSelectEvent {

}
