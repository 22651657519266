import {
    ContentChild,
    Directive,
    TemplateRef,
  } from "@angular/core";
  
  @Directive({
    selector: "williams-ui-platform-grid-templates",
  })
  export class GridTemplatesDirective {
    @ContentChild("pdfTemplate") pdfTemplate!: TemplateRef<any>;
  }
  