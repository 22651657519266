import {
    ContentChild,
    ContentChildren,
    Directive,
    Input,
    QueryList,
    TemplateRef
} from "@angular/core";


@Directive({selector: 'williams-ui-platform-panelbar-item', })
export class PanelbarItemDirective {
    @Input() content: any;
    @Input() disabled: boolean = false;
    @Input() expanded: boolean = false;
    @Input() icon: string = '';
    @Input() iconClass: string = '';
    @Input() id: string = '';
    @Input() imageUrl: string = '';
    @Input() selected: boolean = false;
    @Input() title: string = '';

    @ContentChild('titleTemplate') titleTemplate: TemplateRef<any> | undefined;
    @ContentChildren(PanelbarItemDirective) subItems: QueryList<PanelbarItemDirective> | undefined;

    constructor() { }
}
