import { environment } from './environments/environment';
const proxyEBB = `${environment.apiUrlPrefix}/discovery-svc`;
const proxyNotificationEBB = `${environment.apiUrlPrefix}/notification-svc`;
export const contractFail = { errorCode: "6000", message: "api contract fail" };
export const noticesURL = `${proxyNotificationEBB}/api/notification-service/v1/public/notifications`;

export const indexofCustomersUrl = `${proxyEBB}/api/discovery-service/v1/public/discovery`;
export const discoveryOperationallyURL = `${proxyEBB}/api/discovery-service/v1/public/oa`;
export const capacityReportURL = `${proxyEBB}/api/discovery-service/v1/public/oa/details`;
export const flowingGasURL = `${proxyEBB}/api/discovery-service/v1/public/flow-gas`;

export const downloadsURL = `${proxyEBB}/public/downloads`;
export const siteMapurl = `${proxyEBB}/api/discovery-service/v1/public/site-map`;
export const locationsUrl = `${proxyEBB}/api/discovery-service/v1/public/discovery`;
export const contactsURL = `${proxyEBB}/api/discovery-service/v1/public/contact`;
export const htmlUrl = `${proxyEBB}/api/discovery-service/v1/public/discovery/html/document?`;
export const pdfUrl = `${proxyEBB}/api/discovery-service/v1/public/discovery/pdf/document?fileName=`;
