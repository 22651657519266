import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityDataService, EntityDefinitionService } from '@ngrx/data';
import { HttpClientModule } from '@angular/common/http';
import { CAPACITYREPORT, CRITICALNOTICES, DISCOVERYOPERATIONALLYAVAILABLE, entityConfig, FLOWINGGAS, INDEXOFCUSTOMERS, NONCRITICALNOTICES, PLANNEDSERVICEOUTAGES, REQUESTTOPURCHASE } from './entity-meta-data/entity-metadata';
import { CapacityReportDataService } from './services/capacity-report-data.service';
import { DiscoveryOperationallyDataService } from './services/discovery-operationally-data.service';
import { FlowingGasServiceDataService } from './services/flowing-gas-data.service';
import { CriticalNoticesDataService } from './services/critical-notices-data.service';
import { RequestToPurchaseDataService } from './services/request-to-purchase-data.service';
import { PlannedOutagesDataService } from './services/planned-service-outages-data.service';
import { IndexOfCustomersDataService } from './services/index-of-customers-data.service';
import { NonCriticalNoticesDataService } from './services/non-critical-notices-data.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    DiscoveryOperationallyDataService,
    CapacityReportDataService,
    FlowingGasServiceDataService,
    CriticalNoticesDataService,
    RequestToPurchaseDataService,
    PlannedOutagesDataService,
    IndexOfCustomersDataService,
    NonCriticalNoticesDataService
  ]
})

export class AppStoreModule {
  constructor(
    entityDataService: EntityDataService,
    eds: EntityDefinitionService,
    discoveryOperationallyDataService: DiscoveryOperationallyDataService,
    capacityReportDataService:CapacityReportDataService,
    flowingGasServiceDataService:FlowingGasServiceDataService,
    criticalNoticesDataService: CriticalNoticesDataService,
    requestToPurchaseDataService:RequestToPurchaseDataService,
    plannedOutagesDataService:PlannedOutagesDataService,
    indexOfCustomersDataService: IndexOfCustomersDataService,
    nonCriticalNoticesDataService:NonCriticalNoticesDataService
  ) {
    eds.registerMetadataMap(entityConfig);
    entityDataService.registerService(DISCOVERYOPERATIONALLYAVAILABLE, discoveryOperationallyDataService); // <-- register it
    entityDataService.registerService(CAPACITYREPORT, capacityReportDataService); // <-- register it
    entityDataService.registerService(FLOWINGGAS, flowingGasServiceDataService); // <-- register it
    entityDataService.registerService(CRITICALNOTICES, criticalNoticesDataService); // <-- register it
    entityDataService.registerService(REQUESTTOPURCHASE, requestToPurchaseDataService); // <-- register it  
    entityDataService.registerService(PLANNEDSERVICEOUTAGES, plannedOutagesDataService); // <-- register it
    entityDataService.registerService(INDEXOFCUSTOMERS, indexOfCustomersDataService);// <-- register it
    entityDataService.registerService(NONCRITICALNOTICES, nonCriticalNoticesDataService);// <-- register it
  }
}
