<div class="aggregates d-flex flex-justify-between">
  <div class="row-count" *ngIf="showGridViewTotal">
    {{ gridView.total + " " + rowCountLabel + (gridView.total > 1 ? "s" : "") }}
  </div>
  <div class="column-aggregates d-flex">
    <ng-container *ngFor="let def of gridColumnDefs">
      <div *ngIf="def.aggregate" class="column-aggregate">
        {{ getColumnAggregate(def) }}
      </div>
    </ng-container>
  </div>
</div>
