<kendo-splitbutton
  [data]="data"
  buttonClass="main-btn"
  [arrowButtonIcon]="arrowButtonIcon"
  [icon]="icon"
  [imageUrl]="imageUrl"
  [text]="text"
  [type]="type"
  [size]="size"
  [fillMode]="fillMode"
  [rounded]="rounded"
  [themeColor]="themeColor"
  (buttonClick)="btnClick.emit()"
>
<ng-content></ng-content>
</kendo-splitbutton>
