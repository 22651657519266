import { FormGroup } from "@angular/forms";
import {
  ColumnMenuSettings as KendoColumnMenuSettings,
  FilterableSettings as KendoFilterableSettings,
  GridNavigableSection as KendoGridNavigableSection,
  GridNavigableSettings as KendoGridNavigableSettings,
  GroupableSettings as KendoGroupableSettings,
  PagerSettings as KendoPagerSettings,
  RowClassArgs as KendoRowClassArgs,
  ScrollMode as KendoScrollMode,
  SelectableSettings as KendoSelectableSettings,
  SingleSortSettings as KendoSingleSortSettings,
  MultipleSortSettings as KendoMultipleSortSettings,
  SortSettings as KendoSortSettings,
  GridDataResult as KendoGridDataResult
} from "@progress/kendo-angular-grid";
import {
  AggregateDescriptor as KendoAggregateDescriptor,
  CompositeFilterDescriptor as KendoCompositeFilterDescriptor,
  FilterDescriptor as KendoFilterDescriptor,
  GroupDescriptor as KendoGroupDescriptor,
  SortDescriptor as KendoSortDescriptor,
  State as KendoGridState,
} from "@progress/kendo-data-query";

export interface GridDataResult extends KendoGridDataResult {}

export interface ColumnMenuSettings extends KendoColumnMenuSettings {}

export interface AggregateDescriptor extends KendoAggregateDescriptor {
  label: string;
}

export interface State extends KendoGridState {
  aggregates: AggregateDescriptor[]
}

export interface SortDescriptor extends KendoSortDescriptor {}

export interface CompositeFilterDescriptor
  extends KendoCompositeFilterDescriptor {}

export interface GroupDescriptor extends KendoGroupDescriptor {}

export interface FilterDescriptor extends KendoFilterDescriptor {}

export type FilterableSettings = KendoFilterableSettings;

export type GroupableSettings = KendoGroupableSettings;

export type GridNavigableSettings = KendoGridNavigableSettings;

export type GridNavigableSection = KendoGridNavigableSection;

export interface PagerSettings extends KendoPagerSettings {}

export interface RowClassArgs extends KendoRowClassArgs {}

export type RowClassFn = (context: RowClassArgs) => string;

export type ScrollMode = KendoScrollMode;

export interface SelectableSettings extends KendoSelectableSettings {};

export type SingleSortSettings = KendoSingleSortSettings;

export type MultipleSortSettings = KendoMultipleSortSettings;

export type SortSettings = KendoSortSettings;

export type CreateFormGroupFn = (dataItem: any) => FormGroup;

export type QuickAddFn = () => any;

export type CopyFn = (dataItem: any) => any;

export interface GridFormValidationResult {
  isValid: boolean;
  errors: { [key: string]: any }[];
}

export type RowDeletableFn = (dataItem: any) => boolean;

export type RowEditableFn = (dataItem: any) => boolean; 