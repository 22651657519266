<div [ngClass]="{ 'show-errors': showErrors }">
   <kendo-autocomplete
      class="mt-4"
      [value]="value"
      [data]="data"
      [valueField]="valueField"
      [formControl]="control"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [clearButton]="true"
      [filterable]="true"
      [size]="size"
      [fillMode]="fillMode"
      [rounded]="rounded"
      (filterChange)="handleFilter($event)"
    >
    </kendo-autocomplete>
    <kendo-icon class="requiredIcon w-icon-color w-ml-n6" *ngIf="showErrors"  name="warning"></kendo-icon> 
   </div>