<div class="content-display-wrapper mb-12">
  <kendo-stacklayout [orientation]="orientation">
    <kendo-card [style.width.%]="100" class="remove-border-trl">
      <kendo-card-body class="d-flex py-10">
        <div class="flex-self-start flex-1" #contentTitleDiv>
          <h3 class="title">{{ content.contentTitle }}</h3>
        </div>
        <div class="flex-content-end pt-10">
          <williams-ui-platform-button *ngIf="showPrintButton" icon="print" classList="btn-outlined"
            (clicked)="printClickHandler()">
            Print
          </williams-ui-platform-button>
        </div>
      </kendo-card-body>
    </kendo-card>
    <kendo-card [style.width.%]="100" class="add-border">
      <kendo-card-body class="d-flex px-12 py-20">
        <div class="flex-self-start flex-1 content-body" #contentBodyDiv>
          <p class="title pt-5">{{ content.contentText }}</p>
        </div>
      </kendo-card-body>
    </kendo-card>
  </kendo-stacklayout>
</div>