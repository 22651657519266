import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent implements OnInit {
  defaultIndicatorStyle = {'color':'#fff', 'background-color':'#0079C1'};
  @Input() text!: number;
  @Input() backGroundColor: any;
  @Input() textColor: any;
  @Input() addIndicatorStylesCallBackFn! : any;

  constructor() { this.addIndicatorStylesCallBack = this.addIndicatorStylesCallBack.bind(this); }

  ngOnInit(): void {
  }
  addIndicatorStylesCallBack() : {[key: string]: string} {
    if(this.addIndicatorStylesCallBackFn){
      return this.addIndicatorStylesCallBackFn();
    }
    return this.defaultIndicatorStyle;
  }
}
