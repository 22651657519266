import { Component, ContentChild, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectableSettings, SelectionEvent, GridComponent as KendoGrid, GridDataResult, PDFMargin } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { merge, Subscription, take, tap } from 'rxjs';
import { DialogService } from '../../../services/dialog.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { GridColumnDef } from '../models/grid-column-def';
import { GridRowDef } from '../models/grid-row-def';
import { TooltipDirective } from "@progress/kendo-angular-tooltip";

import { CANCEL_CONFIRMATION_DIALOG_CONTENT, CANCEL_CONFIRMATION_DIALOG_TITLE, defaultColumnMenuSettings, defaultCommandColumnActions, defaultExportOptions, defaultGridStateSettings } from './default-configuration/settings';
import { ActionType, FormValidationChange, GridColumnMenuSettings, GridFilterableSettings, GridScrollMode, GridSortSettings, GridState, HyperLinkEvent, RowClassCallback, SubmitFormEvent } from './model';
import { GridCommandColumnActionItem, GridCommandColumnActionType, GridCommandColumnClickEvent, GridExportOption } from '../models/grid';
import { GridTemplatesDirective } from './directives/grid-template.directive';

import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';

@Component({
  selector: 'williams-ui-platform-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnChanges, OnInit {
  @ViewChild('grid') grid!: KendoGrid;
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;
  @Input() sticky:boolean = true;
  @Input() tableTitle: string = '';
  @Input() groupable = true;
  @Input('gridData') set gridData(data: any[]) {
    this.originalData = data.map(item => {
      return { ...item, rowId: this._counter++, isNewRecord: false }
    });
  }
  @Input('gridColumnDefs') set columnDefs(data: GridColumnDef[]) {
    this.gridColumnDefs = data.map(item => {
      return {
        ...item,
        editConfig: item.editConfig ?? {
          add: item.editable,
          edit: item.editable
        },
        formControlName: item.formControlName ?? item.field
      }
    })
  };
  @Input() gridRowDef: GridRowDef = {
    isEditableField: '',
    isDeletableField: ''
  };
  @Input('gridStateSettings') set setGridState(state: GridState) {
    this.gridState = {
      ...state,
      take: state.take ?? this.gridState.take,
      skip: state.skip ?? this.gridState.skip,
      aggregates: state.aggregates ?? this.gridState.aggregates
    };
  };
  @Input() createFormGroupFunction: any;
  @Input() height: number = 350;
  @Input() excelExport = true;
  @Input() excelFileName: string = 'new_excel.xlsx';
  @Input() pdfExport = true;
  @Input() showBulkDelete = true;
  @Input() showBulkCopy = true;
  @Input() pdfFileName: string = 'new.pdf';
  @Input() loading = false;
  @Input() showAggregate = true;
  @Input() rowCountLable: string = 'Row';
  @Input() rowHeight = 36;
  @Input() scrollable: GridScrollMode = 'virtual';
  @Input() sortable: GridSortSettings = {
    mode: 'multiple'
  };
  @Input() filterable: GridFilterableSettings = 'menu';
  @Input() columnsReorderable = true;
  @Input() rowClassCallbackFn!: RowClassCallback;
  @Input() columnClassCallbackFn!: (dataItem: any, field: string,isEditing?: boolean) => string | string[] | { [key: string]: any } | Set<string>;
  @Input() showCommandColumn:boolean = true;
  @Input() enableSelection:boolean=true;
  @Input() showActionButtons:boolean=true;
  @Input() showQuickAdd = true;
  @Input() enableQuickAdd = true;
  @Input() quickAddCallbackFn!: Function;
  @Input() showBulkEdit = true;
  @Input() enableBulkEdit = true;
  @Input() showGridViewTotal = true;
  @Input() hightlightRowsWithError = false;
  @Input() emptyRecordsMessage = 'No records available.'
  @Input() exportOptions = defaultExportOptions;
  @Input() gridRowCommands: GridCommandColumnActionItem[] = defaultCommandColumnActions;
  @Input() csvFileName = 'grid.csv'
  /**
   * Column menu settings
   */
  @Input('columnMenuSettings') set columnMenuSettings(settings: GridColumnMenuSettings) {
    this.gridColumnMenuSettings = {
      ...defaultColumnMenuSettings,
      ...settings
    }
  };
  @Input() pdfMargin!: PDFMargin;
  @Input() pdfPaperSize: string = 'A4';

  @Output() save = new EventEmitter<SubmitFormEvent>();
  @Output() duplicate = new EventEmitter<SubmitFormEvent>();
  @Output() delete = new EventEmitter<any[]>();
  @Output() update = new EventEmitter<SubmitFormEvent>();
  @Output() scrolledToBottom = new EventEmitter<void>();
  @Output() hyperlinkClick = new EventEmitter<HyperLinkEvent>();
  @Output() validationChange = new EventEmitter<FormValidationChange>();
  @Output() cancelAction = new EventEmitter<void>(); // Emitted when any action(quick add, bulk edit ...) is cancelled
  @ContentChild(
    forwardRef(() => GridTemplatesDirective)
  )
 gridPdfTemplate: any;
  private _counter = 0;
  gridColumnDefs: GridColumnDef[] = [];
  originalData: any[] = [];
  gridView!: GridDataResult;

  selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
    mode: 'multiple'
  }
  selectedRows: { index: number, dataItem: any }[] = [];
  currentAction: ActionType = ActionType.NONE;
  rowsInEditMode: {
    rowId: number,
    group: FormGroup
  }[] = [];

  gridState: GridState = defaultGridStateSettings;
  gridColumnMenuSettings: GridColumnMenuSettings = defaultColumnMenuSettings;

  private _valueChangeSubscription!: Subscription;

  constructor(private _dialogService: DialogService) {
    this.rowClassCallback = this.rowClassCallback.bind(this);
    this.columnClassCallback = this.columnClassCallback.bind(this);
  }
  get showBulkEditButton(): boolean {
    return this.showBulkEdit && this.currentAction === ActionType.NONE;
  }

  get showQuickAddButton(): boolean {
    return this.showQuickAdd && this.currentAction !== ActionType.BULK_EDIT && this.currentAction !== ActionType.INLINE_EDIT;
  }

  get showSubmitCancelButtons(): boolean {
    return this.currentAction !== ActionType.NONE;
  }

  get disableCommandColumnActions(): boolean {
    return this.hasGroupingApplied || this.selectedRows.length > 0 || this.currentAction === ActionType.BULK_EDIT || this.currentAction === ActionType.INLINE_EDIT;
  }

  get disableBulkEdit(): boolean {
    return (
      this.hasGroupingApplied ||
      this.selectedRows.length === 0 ||
      this.selectedRows.some(
        item => item.dataItem[this.gridRowDef.isEditableField] === false
      )
    );
  }

  get disableBulkDelete(): boolean {
    return (
      this.hasGroupingApplied ||
      this.selectedRows.length === 0 ||
      this.selectedRows.some(
        item => item.dataItem[this.gridRowDef.isDeletableField] === false
      )
    );
  }

  get disableQuickAdd(): boolean {
    return !this.enableQuickAdd || this.hasGroupingApplied;
  }

  get isEditing(): boolean {
    return this.currentAction !== ActionType.NONE;
  }

  get isLockable(): boolean {
    return true;
  }

  get enableGridGrouping(): boolean {
    return this.groupable && this.currentAction === ActionType.NONE;
  }

  get hasGroupingApplied(): boolean {
    return this.groupable && this.gridState.group != null && this.gridState.group.length > 0;
  }

  ngOnInit(): void {
    this.loadGridView();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['gridData'] && !changes['gridData'].isFirstChange()) {
      this.gridState = defaultGridStateSettings;
      this.selectedRows = [];
      this.rowsInEditMode = [];
      this.currentAction = ActionType.NONE;
      this.loadGridView();
      this.closeAllRows();
    }
  }

  onSelectionChange(event: SelectionEvent) {
    if (event.selectedRows?.length) {
      this.selectedRows.push(...event.selectedRows);
      return;
    }

    if (event.deselectedRows?.length) {
      event.deselectedRows.forEach(item => this.removeFromSelectedRowList(item.index));
    }
  }

  removeFromSelectedRowList(index: number) {
    const removeAtIndex = this.selectedRows.findIndex(item => item.index === index);
    this.selectedRows.splice(removeAtIndex, 1);
  }

  onCommandItemClick(event: GridCommandColumnClickEvent): void {
    const { actionItem, rowIndex, dataItem } = event;
    const { action, disabled } = actionItem;
    if(disabled) {
      return;
    }
    switch (action) {
      case GridCommandColumnActionType.EDIT: this.editRow(rowIndex, dataItem)
        break;
      case GridCommandColumnActionType.COPY: this.copyRow(dataItem);
        break;
      case GridCommandColumnActionType.DELETE: this.deleteRow(dataItem);
    }
  }

  editRow(rowIndex: number, dataItem: any) {
    this.currentAction = ActionType.INLINE_EDIT;
    const group = this.createFormGroupFunction(dataItem);
    this.rowsInEditMode = [{
      rowId: dataItem.rowId,
      group
    }];
    this.grid.editRow(rowIndex, group);
    this.handleFormValueChange();
  }

  copyRow(dataItem: any) {
    this.currentAction = ActionType.INLINE_COPY;
    const newItem = { ...dataItem, rowId: this._counter++, isNewRecord: true };
    this.originalData.unshift(newItem);
    this.loadGridView();
    const group = this.createFormGroupFunction(newItem);
    this.rowsInEditMode.push({
      rowId: newItem.rowId,
      group
    });
    this.gridView.data.forEach((dataItem, index) => {
      this.grid.closeRow(index);
      const groupDetails = this.rowsInEditMode.find(item => item.rowId === dataItem.rowId);
      if(groupDetails) {
        this.grid.editRow(index, groupDetails.group);
      }
    });
    this.handleFormValueChange();
  }

  deleteRow(dataItem: any) {
    this.deleteRows([dataItem]);
  }

  loadGridView() {
    this.gridView = process(this.originalData, this.gridState);
  }

  onScrolledToBottom() {
    this.scrolledToBottom.emit();
  }

  onDataStateChange(event: State) {
    this.gridState = {
      ...event,
      aggregates: this.gridState.aggregates
    };

    if(this.gridState.group) {
      this.gridState.group.forEach(item => item.aggregates = this.gridState.aggregates);
    }

    this.loadGridView();
  }

  exportClickHandler(e: GridExportOption, grid: KendoGrid) {
    switch(e.val) {
      case 'Excel': grid.saveAsExcel();
                    break;
      case 'PDF': grid.saveAsPDF();
                  break;
      case 'CSV': this.saveAsCSV();
                  break;
    }
  }

  saveAsCSV(): void {
    const data = this.hasGroupingApplied ? this._flattenGroupedGridViewData(this.gridView.data) : this.gridView.data;
    const titles = this.gridColumnDefs.map(column => `"${column.title}"`).join(",");
    const processedData = data.map(dataItem =>

      this.gridColumnDefs.map(
        column => {
          return `"${this._getColumnValue(column.field, dataItem)}"`;
        }
      ).join(",")
    );
    processedData.unshift(titles);
    const dataURI = "data:text/plain;base64," + encodeBase64(processedData.join('\n'));
    saveAs(dataURI, this.csvFileName);
  }

  private _getColumnValue(field: string, dataItem: any): string {
    const fieldArr = field.split('.');
    return fieldArr.reduce((result, key) => {
      return result ? result[key] : result;
    }, { ...dataItem });
  }

  private _flattenGroupedGridViewData(gridViewData: any[]): any[] {
    const result = []
    for(let item of gridViewData) {
      if(item.items) {
        result.push(...this._flattenGroupedGridViewData(item.items));
      } else {
        result.push(item);
      }
    }

    return result;
  }

  rowClassCallback(context: { dataItem: any }): { [key: string]: any } {
    let errors = {};
    if(this.hightlightRowsWithError && this.rowsInEditMode.length) {
      const hasError = !!this.rowsInEditMode.find(item => item.rowId === context.dataItem.rowId)?.group.invalid;
      errors = {
        'row-error': hasError
      }
    }
    if (this.rowClassCallbackFn){
      errors = { ...errors, ...this.rowClassCallbackFn(context) };
    }
    return errors;
  }

  columnClassCallback(dataItem: any, field: string): string | string[] | { [key: string]: any } | Set<string> {
    if (this.columnClassCallbackFn) {
      return this.columnClassCallbackFn(dataItem, field,this.isEditing);
    }
    return '';
  }

  onQuickAdd(): void {
    // Item to be added to grid
    const item = { ...this.quickAddCallbackFn(), rowId: this._counter++, isNewRecord: true };
    this.originalData.unshift(item);
    this.selectedRows = [];
    //Form group for the row
    const group = this.createFormGroupFunction(item);
    if (this.currentAction === ActionType.NONE) {
      // When first time quick add is clicked, push new item at start of the grid
      this.rowsInEditMode.push({
        rowId: item.rowId,
        group
      });
      this.currentAction = ActionType.QUICK_ADD;
    } else if (this.currentAction === ActionType.QUICK_ADD || this.currentAction === ActionType.BULK_COPY || this.currentAction === ActionType.INLINE_COPY) {
      // this.rowsInEditMode = this.rowsInEditMode.map(item => ({ ...item, index: item.rowId + 1 }));
      this.rowsInEditMode.unshift({ rowId: item.rowId, group })
    }
    this.loadGridView();
    this.rowsInEditMode.forEach(({ rowId, group }) => {
      const rowIndex = this.gridView.data.findIndex(item => item.rowId === rowId);
      if(rowIndex > -1) {
        this.grid.closeRow(rowIndex);
        this.grid.editRow(rowIndex, group);
      }
    });
    this.handleFormValueChange();
  }

  onBulkEdit(): void {
    this.selectedRows.forEach(({ dataItem, index }) => {
      const group = this.createFormGroupFunction(dataItem);
      this.rowsInEditMode.push({
        rowId: dataItem.rowId,
        group
      });
      this.grid.editRow(index, group);
      this.currentAction = ActionType.BULK_EDIT;
    });
    this.handleFormValueChange();
  }

  onBulkCopy(): void {
    const itemsToAdd = this.selectedRows.map(data => ({ ...data.dataItem, rowId: this._counter++, isNewRecord: true }));
    this.originalData.unshift(...itemsToAdd);
    this.selectedRows = [];
    this.loadGridView();
    itemsToAdd.forEach((item, index) => {
      const group = this.createFormGroupFunction(item);
      this.rowsInEditMode.push({
        rowId: item.rowId,
        group
      });
      // this.grid.editRow(index, group);
    });
    this.gridView.data.forEach((dataItem, index) => {
      this.grid.closeRow(index);
      const groupDetails = this.rowsInEditMode.find(item => item.rowId === dataItem.rowId);
      if(groupDetails) {
        this.grid.editRow(index, groupDetails.group);
      }
    });
    this.currentAction = ActionType.BULK_COPY;
    this.handleFormValueChange();
  }

  onBulkDelete() {
    const data = this.selectedRows.map(data => data.dataItem);
    this.deleteRows(data);
  }

  deleteRows(data: any[]) {
    const filteredRecords: any[] = [];
    // Deleting newly added rows locally and filtering out existing ones
    data.forEach(dataItem => {
      if(dataItem.isNewRecord) {
        const index = this.originalData.findIndex(item => item.rowId === dataItem.rowId);
        this.originalData.splice(index, 1);
        const editmodeIndex = this.rowsInEditMode.findIndex(item => item.rowId === dataItem.rowId);
        this.rowsInEditMode.splice(editmodeIndex, 1);
      } else {
        filteredRecords.push(dataItem);
      }
    });
    this.loadGridView();
    this.closeAllRows();
    if(filteredRecords.length) {
      this.delete.emit(
        filteredRecords.map((item) =>
          this.mergeEditedDataWithOriginalData(item.rowId, item)
        )
      );
    } else {
      this.gridView.data.forEach((item, index) => {
        const groupDetails = this.rowsInEditMode.find(({ rowId }) => item.rowId === rowId);
        if(groupDetails) {
          this.grid.editRow(index, groupDetails.group);
        }
      })
    }
  }

  onSubmit(): void {
    const data = this.rowsInEditMode.map((item) =>
      this.mergeEditedDataWithOriginalData(item.rowId, item.group.value)
    );
    const validationResult = this.validate(this.rowsInEditMode.map(item => item.group));
    const payload = {
      validationResult,
      data
    };
    
    switch (this.currentAction) {
      case ActionType.INLINE_COPY:
      case ActionType.BULK_COPY:
      case ActionType.QUICK_ADD: this.save.emit(payload);
        break;
      case ActionType.INLINE_EDIT:
      case ActionType.BULK_EDIT: this.update.emit(payload);
        break;
    }
  }

  async onCancel(): Promise<void> {
    const confirm = await this.confirmCancel();
    if(!confirm) {
      return;
    }
    switch (this.currentAction) {
      case ActionType.QUICK_ADD:
      case ActionType.INLINE_COPY:
      case ActionType.BULK_COPY: this.handleQuickAddCancel();
        break;
      case ActionType.INLINE_EDIT:
      case ActionType.BULK_EDIT: this.handleBulkEditCancel();
        break;
    }
    this.currentAction = ActionType.NONE;
    this.rowsInEditMode = [];
    this.unSubscribeToValueChanges();
    this.cancelAction.emit();
  }

  confirmCancel(): Promise<boolean> {
    return new Promise(resolve => {
      this._dialogService.openDialog({
        title: CANCEL_CONFIRMATION_DIALOG_TITLE,
        content: CANCEL_CONFIRMATION_DIALOG_CONTENT,
        cancelAction : { text: "Keep working", value: 'no' },
        confirmAction: { text: "Cancel changes", value: "yes" }
      }, ConfirmDialogComponent).result
        .pipe(
          take(1),
          tap((result: any) => {
            resolve(result.value === 'yes');
          }))
        .subscribe()
    });
  }

  handleQuickAddCancel(): void {
    const newUnsavedRowsCount = this.rowsInEditMode.length;
    this.rowsInEditMode.forEach(({ rowId }) => {
      const index = this.gridView.data.findIndex(item => item.rowId === rowId)
      this.grid.closeRow(index);
    });
    this.rowsInEditMode = [];
    this.originalData.splice(0, newUnsavedRowsCount);
    this.loadGridView();
  }

  handleBulkEditCancel(): void {
    this.rowsInEditMode.forEach(({ rowId }) => {
      const index = this.gridView.data.findIndex(item => item.rowId === rowId)
      this.grid.closeRow(index);
    });
  }

  isEditable(columnDef: GridColumnDef): boolean {
    if (this.currentAction === ActionType.BULK_EDIT || this.currentAction === ActionType.INLINE_EDIT) {
      return !!columnDef.editConfig?.edit;
    }
    return !!columnDef.editConfig?.add;
  }

  isFilterable(columnDef: GridColumnDef): boolean {
    if(columnDef.filterable === false) {
      return false;
    }

    if(this.currentAction === ActionType.NONE) {
      return true;
    }
    
    if (this.currentAction === ActionType.BULK_EDIT || this.currentAction === ActionType.INLINE_EDIT) {
      return !columnDef.editConfig?.edit;
    }
    return !columnDef.editConfig?.add;
  }

  isSortable(columnDef: GridColumnDef): boolean {
    if(columnDef.sortable === false) {
      return false;
    }

    if(this.currentAction === ActionType.NONE) {
      return true;
    }

    return !this.isEditable(columnDef);
  }

  closeAllRows(): void {
    this.gridView.data.forEach((item, index) => this.grid.closeRow(index));
  }

  getGridRowCommands(dataItem: any) {
    const disableDelete = dataItem[this.gridRowDef.isDeletableField] === false;
    const disableEdit =
      dataItem[this.gridRowDef.isEditableField] === false ||
      this.currentAction !== ActionType.NONE;
    return this.gridRowCommands.map(item => {
      let disabled = false;
      if(item.action == GridCommandColumnActionType.EDIT){
        disabled = disableEdit
      } else if(item.action === GridCommandColumnActionType.DELETE){
        disabled = disableDelete
      }
      return {...item, disabled}
    })
  }

  mergeEditedDataWithOriginalData(rowId: number, editValue: any): any {
    const originalRowData = this.originalData.find(item => item.rowId === rowId);
    if(!originalRowData) {
      return editValue;
    }
    return {
      ...originalRowData,
      ...editValue
    }
  }

  validate(groups: FormGroup[]) {
    const isValid = groups.every(group => group.valid);
    return {
      valid: isValid,
      errors: isValid ? [] : this.mapFormErrors(groups)
    } 
  }

  mapFormErrors(controls: FormGroup[]): { [key: string]: any }[] {
    const fields = this.getEditableFieldList();

    return controls.map(group => fields.reduce((result, field) => {
      return {
        ...result,
        [field]: group.get(field)?.errors
      }
    }, {}))
  }

  getEditableFieldList(): string[] {
    return this.gridColumnDefs.filter(def => def.editable).map(def => def.formControlName ?? def.field);
  }

  handleFormValueChange(): any {
    if (this._valueChangeSubscription) {
      this._valueChangeSubscription.unsubscribe();
    }

    const groups = this.rowsInEditMode.map(item => item.group);
    this._valueChangeSubscription = merge(...groups.map(item => item.valueChanges)).subscribe(() => {
      const payload = {
        validationResult: this.validate(groups)
      }
      this.validationChange.emit(payload);
    });
  }

  unSubscribeToValueChanges(): void {
    this._valueChangeSubscription.unsubscribe();
    this.validationChange.emit({
      validationResult:{
        valid: true,
        errors: []
      }
    })
  }

  // Interfaces to be used by parent
  isInAction(): boolean {
    return this.currentAction !== ActionType.NONE;
  }


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.classList.contains('k-column-title') ||
      element.hasAttribute('show-tooltip')) &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  isAggregate(field:string) : boolean {
       return this.gridState.aggregates.some(((item)=> {
        return item.field === field}));
  }

  getAggregatesLabel(field:string) : string {
    const item =this.gridState.aggregates.find(((item)=> {
      return item.field === field}));
        return (item?.label)? item?.label : '';
  }

  getAggregatesValue(aggregates: any, field:string) : number {
    const item =this.gridState.aggregates.find(((item)=> {
      return item.field === field}));
    return item? aggregates[item.field][item.aggregate]: null;
  }

  ngOnDestroy() {
    if(this._valueChangeSubscription) {
      this.unSubscribeToValueChanges();
    }
  }
}
