import { Component, OnInit, ViewChild } from '@angular/core';
import { GridColumnDef, GridComponent } from '@williams-ui-platform/w-ui-angular';
import { Subject, takeUntil } from 'rxjs';
import { ContactsInterface, ContactsList} from '../store/model/contacts.interface';
import { ContactsService } from '../store/services/contacts.service';
import { transformContactData } from '../utils/contact.tranform';
import { DISCOVERY_GAS_TRANSMISSION,DISCOVERY_CONTACT_LIST, DISCOVERY_GAS_ADDRESS, DISCOVERY_GAS_ADDRESS1, EXPORT_TO_CSV } from '../constant';

@Component({
  selector: 'williams-ui-platform-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  csvIcon= 'assets/w-ui-angular/images/csv.svg';
  hoveredCsvIcon = 'assets/w-ui-angular/images/csv.svg';
  activedCsvIcon ='assets/w-ui-angular/images/csv-white.svg';
  discoveryTitle=DISCOVERY_CONTACT_LIST;
  gasTitle=DISCOVERY_GAS_TRANSMISSION;
  discoveryAddress = DISCOVERY_GAS_ADDRESS;
  discoveryAddress1= DISCOVERY_GAS_ADDRESS1
  downloadCsv=EXPORT_TO_CSV;
  gridData:ContactsList[] = [];
  contactData!:ContactsInterface;
  destroy$: Subject<boolean> = new Subject<boolean>();
  gridColumnDef: GridColumnDef[] = [
    {
      field: 'department',
      title: 'Group',
      editable: false,
      columnMenu:false
    },
    {
      field: 'name',
      title: 'Name',
      editable: false,
      columnMenu:false
    },
    {
      field: 'role',
      title: 'Title',
      editable: false,
      columnMenu:false
    },
    {
      field: "officePhone",
      title: "Office Phone",
      editable: false,
      columnMenu:false
    },
    {
      field: "contactNumber",
      title: "Cell Phone",
      editable: false,
      columnMenu:false
    },
    {
      field: "emailId",
      title: "Email",
      editable: false,
      columnMenu:false,
      isHyperlink: true
    },
    {
      field: "fax",
      title: "Fax",
      editable: false,
      columnMenu:false
    },
  ]
  @ViewChild('contactsData') gridComponentRef!:GridComponent;
  constructor(private contactsService: ContactsService) { }

  ngOnInit(): void {
    this.fetchContactsData();
  }

  fetchContactsData() {
    this.contactsService.getContactsData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: ContactsInterface) => {
        this.contactData = res;
        this.gridData = res.departmentContacts.map(transformContactData); 
      })
  }

  onHyperlinkClick(event: any) {
    const mailText = "mailto:" + event.dataItem.emailId;
    window.location.href = mailText;
  }

  linkColor(item: object, field: string) {
    return { 'link-color': (field === 'emailId') };
  }

  export(){
    this.gridComponentRef.saveAsCSV();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
