import { Component, ContentChild, forwardRef, Input, OnInit } from '@angular/core';
import { SVGIcon } from '../model';

import { TabStripTabTemplateDirective } from './directive/tab-strip-tab-template.directive';

@Component({
  selector: 'williams-ui-platform-tab-strip-tab',
  templateUrl: './tab-strip-tab.component.html'
})
export class TabStripTabComponent implements OnInit {
   
  @ContentChild(
    forwardRef(() => TabStripTabTemplateDirective)
  ) 
  tabStripTabTemplateDirective!: any;
  /**
   * When set to true, 
   * a close button will be rendered inside the tab. 
   * This option overrides the value of the TabStrip closable option.
   */
  @Input() closable: boolean = false;
  
  /**
   * Defines the name for an existing font icon in the Kendo UI theme for the close icon. 
   * This option overrides the value of the TabStrip closeIcon option.
   */
  @Input() closeIcon!:string;

  /**
   * Allows the usage of custom icons by defining a custom CSS class, 
   * or multiple classes separated by spaces. 
   * This option overrides the value of the TabStrip closeIcon option.
   */
  @Input() closeIconClass!:string;

  /**
   * Defines an SVGIcon to be rendered for the close icon. 
   * The input can take either an existing Kendo SVG icon or a custom one.
   */
  @Input() closeSVGIcon!:SVGIcon;

  /**
   * The CSS classes that will be rendered on the tab element. 
   * Supports the type of values that are supported by ngClass.
   */
  @Input() cssClass!:any;

  /**
   * The CSS styles that will be rendered on the tab element. 
   * Supports the type of values that are supported by ngStyle.
   */
  @Input() cssStyle!:any;

  /**
   * Used to disable a tab.
   * Defaults to false
   */
  @Input() disabled= false;

  /**
   * Determines which tab will be selected upon the initial loading of the TabStrip
   */
  @Input() selected:boolean = false;

  /**
   * Sets the tab title 
   */
  @Input() title!:string;



  constructor() { }

  ngOnInit(): void {
  }

}
