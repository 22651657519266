import { EntityMetadataMap } from "@ngrx/data";
import { getAppEntityName } from "../../config/configUtil";
export const DISCOVERY = getAppEntityName("Discovery");
export const DISCOVERYOPERATIONALLYAVAILABLE = getAppEntityName(
  "Operationally Available"
);
export const CAPACITYREPORT = getAppEntityName("Capacity Report");
export const FLOWINGGAS = getAppEntityName("Flowing Gas");
export const LOCATIONS = getAppEntityName('Locations');
export const CONTACTS = getAppEntityName('Contacts');
//notices
export const INDEXOFCUSTOMERS = getAppEntityName('IndexOfCustomers');
export const CRITICALNOTICES = getAppEntityName('CriticalNotices');
export const PLANNEDSERVICEOUTAGES = getAppEntityName('PlannedServiceOutages');
export const REQUESTTOPURCHASE = getAppEntityName('RequestToPurchase');
export const NONCRITICALNOTICES = getAppEntityName('NonCriticalNotices');
export const DOWNLOADS = getAppEntityName('Downloads');
export const SITEMAP = getAppEntityName('Site Map');
export const HTML = getAppEntityName('HTML');


export const entityMetadata: EntityMetadataMap = {
  [DISCOVERYOPERATIONALLYAVAILABLE]: {
    selectId: (item: any) => item.cycleId
  },
  [CAPACITYREPORT]: {
    selectId: (item: any) => item.segmentAbbr,
  },
  [FLOWINGGAS]: {
    selectId: (item: any) => item.heatingValue
  },
  [CRITICALNOTICES] : {},
  [INDEXOFCUSTOMERS] : {},
  [PLANNEDSERVICEOUTAGES] : {},
  [REQUESTTOPURCHASE] : {},
  [LOCATIONS] : {},
  [NONCRITICALNOTICES] : {},
  [CONTACTS] : {},
  [INDEXOFCUSTOMERS] : {},
  [DOWNLOADS]:{},
  [HTML]:{},
  [SITEMAP]:{}
};


export const entityConfig = {
  ...entityMetadata,
};
