import { PDFMargin } from "@progress/kendo-angular-grid";
import { PaperSize } from "@progress/kendo-angular-pdf-export/pdf-export.component";

export interface PDFOptions {
  // Exports all Grid pages, starting from the first one.
  allPages: boolean;

  // The author (metadata) of the PDF document.
  author: string;

  // Specifies if the Print dialog should be opened immediately after loading the document.
  autoPrint: boolean;

  // A flag that indicates whether to produce actual hyperlinks in the exported PDF file. It is also possible to set a CSS selector. All matching links will be ignored.
  avoidLinks: string | boolean;

  // The creator of the PDF document.
  creator: string;

  // Specifies the name of the exported PDF file.
  fileName: string;

  // An optional CSS selector that specifies the elements which cause the page breaks 
  forcePageBreak: string;

  // A flag that indicates if the page will be in a landscape orientation.
  landscape: boolean;

  // Specifies the paper size of the PDF document
  paperSize: PaperSize;

  // The producer (metadata) of the PDF document.
  producer: string;

  // Specifies if the <thead> elements of the tables will be repeated on each page
  repeatHeaders: boolean;

  // A scale factor. The text size on the screen might be too big for printing. To scale down the output in PDF, use this option.
  scale: number;

  // The subject (metadata) of the PDF document.
  subject: string;

  // The title (metadata) of the PDF document.
  title: string;

    // Specifies the margins of the page (Numbers are treated as points ("pt")). Supported units 'mm', 'cm', 'in', 'pt'(default)
  pdfMargin: PDFMargin;
}