import { Injectable } from '@angular/core';
import {
  DefaultDataService,
  HttpUrlGenerator,
} from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { CAPACITYREPORT } from '../entity-meta-data/entity-metadata';

@Injectable()
export class CapacityReportDataService extends DefaultDataService<any> {
  constructor(http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator) {
    super(CAPACITYREPORT, http, httpUrlGenerator);
  }
}