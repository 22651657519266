import {
    AfterViewInit,
    Component,
    ContentChild,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    ViewChild
} from "@angular/core";
import { DrawerComponent as KendoDrawer } from "@progress/kendo-angular-layout";
import { DrawerTemplatesDirective } from "./directives/drawer-templates.directive";
import { DrawerAnimation, DrawerItemExpandedFn, DrawerMode, DrawerPosition, DrawerSelectEvent } from "./models";


@Component({
    selector: 'williams-ui-platform-drawer',
    templateUrl: "./drawer.component.html"
})
export class DrawerComponent implements AfterViewInit {
    @Input() animation: boolean | DrawerAnimation = true;
    @Input() autoCollapse: boolean = false;
    @Input() expanded: boolean = true;
    @Input() isItemExpanded: DrawerItemExpandedFn = (item) => {
        return this.expandedItemIds.includes(item.id)
    }
    @Input() items: any[] = [];
    @Input() mini: boolean = true;
    @Input() miniWidth: number = 64;
    @Input() mode: DrawerMode = "push";
    @Input() position: DrawerPosition = "start";
    @Input() width: number = 250;
    @Input('themeColor') set theme(themeName: 'base' | 'primary') {
        this.themeClass = `k-drawer-${themeName}`
    }

    @Output() collapse = new EventEmitter<any>();
    @Output() expand = new EventEmitter<any>();
    @Output() expandedChange = new EventEmitter<boolean>();
    @Output() select = new EventEmitter<DrawerSelectEvent>();

    @ContentChild(forwardRef(() => DrawerTemplatesDirective)) drawerTemplates!: DrawerTemplatesDirective;

    @ViewChild(KendoDrawer) drawer!: KendoDrawer;

    public themeClass = '';

    expandedItemIds: any [] = [];

    ngAfterViewInit() {
    }

    onCollapse(event: any):void {
        this.collapse.emit(event);
    }

    onExpand(event: any): void {
        this.expand.emit(event);
    }

    onExpandedChange(event: boolean): void {
        this.expandedChange.emit(event);
    }

    onSelect(event: DrawerSelectEvent): void {
        if(this.expandedItemIds.includes(event.item.id)) {
            this.expandedItemIds = this.expandedItemIds.filter(id => id != event.item.id);
        } else {
            this.expandedItemIds.push(event.item.id);
        }
        this.select.emit(event);
    }

    public toggle() {
        this.drawer.toggle();
    }

}
