export interface Nomination {
    volType: number;
    volUsage: number;
    deliveryPoint: string;
    agreement: string;
    package: string;
    id: number;
    cycle: string;
    rank: number;
    company: string;
    createdBy: string;
    creationTime: string;
    date: string;
    downStreamAgreement: string;
    downStreamId: string;
    downStreamPackage: string;
    MMBtu: number;
}