import { Component, forwardRef, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputFillMode, InputRounded, InputSize } from '@progress/kendo-angular-inputs';
import { BaseAccessor } from '../base/base-accessor';

@Component({
  selector: 'williams-ui-platform-numeric-text',
  templateUrl: './numeric-text.component.html',
  styleUrls: ['./numeric-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericTextComponent),
      multi: true
    }
  ]
})

export class NumericTextComponent extends BaseAccessor {
  @Input() decimals:number = 2;
  @Input() format:string = '';
  @Input() value:number = 0;
  @Input() fillMode: InputFillMode = 'solid';
  @Input() rounded: InputRounded = 'full';
  @Input() size: InputSize = 'medium';


  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer)
  }

  override ngOnInit(): void {
    super.ngOnInit();
     }

}
