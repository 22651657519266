import { Component, ContentChild, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CellClickEvent, DataStateChangeEvent, GridComponent as KendoGridComponent, SelectionEvent } from "@progress/kendo-angular-grid";
import { TooltipDirective } from "@progress/kendo-angular-tooltip";
import { aggregateBy, process } from "@progress/kendo-data-query";
import { encodeBase64, saveAs } from "@progress/kendo-file-saver";
import { merge, Subscription, take, tap } from "rxjs";
import { DialogService } from "../../../services/dialog.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { COMMAND_COLUMN_ACTION_ITEMS, DEFAULT_CHECKBOX_COLUMN_CONFIGURATION, DEFAULT_COLUMN_CONFIGURATION, DEFAULT_COMMAND_COLUMN_CONFIGURATION, DEFAULT_EXCEL_OPTIONS, DEFAULT_GRID_STATE, DEFAULT_PDF_OPTIONS } from "./constants/default-values";
import { CANCEL_CONFIRMATION_DIALOG_CONTENT, CANCEL_CONFIRMATION_DIALOG_TITLE } from "./constants/messages";
import { GridTemplatesDirective } from "./directives/grid-templates.directive";
import {
  ColumnMenuSettings,
  CommandColumnActionItem,
  CopyFn,
  CreateFormGroupFn,
  ExcelOptions,
  FilterableSettings,
  GridDataResult,
  GridFormValidationResult,
  GridNavigableSettings,
  GroupableSettings,
  PagerSettings,
  PDFOptions,
  QuickAddFn,
  RowClassFn,
  RowDeletableFn,
  RowEditableFn,
  ScrollMode,
  SelectableSettings,
  SortSettings,
  State,
} from "./models";
import { CheckboxColumnConfiguration, ColumnConfiguration, CommandColumnConfiguration } from "./models/column";
import { GridEditService } from "./services/grid-edit.service";
import { getFieldValueFromDataItemRecursively } from "./utils";

@Component({
  selector: "williams-ui-platform-extended-grid",
  templateUrl: "./extended-grid.component.html",
  providers: [ GridEditService ]
})
export class ExtendedGridComponent implements OnInit, OnChanges {
  // Indicates whether the Grid columns will be resized during initialization so that they fit their headers and row content.
  @Input() autoSize = true;

  // Specifies if the column menu of the columns will be displayed.
  @Input() columnMenu: boolean | ColumnMenuSettings = true;

  // Specifies the data for the grid.
  @Input('data') set _data(data: any) {
    this._originalData = data.map((item: any) => {
      return {
        ...item,
        isNewItem: false,
        rowId: this._counter++
      }
    });
    this._originalDataBackup = this._originalData.map(dataItem => ({ ...dataItem }));
  };

  // Defines the detail row height that is used when the scrollable option of the Grid is set to virtual. Required by the virtual scrolling functionality.
  @Input() detailRowHeight = 100;

  // State of the grid.
  @Input('gridState') set _gridState(state: State) {
    this.gridState = {
      ...DEFAULT_GRID_STATE,
      ...state
    }
  };

  // Enables the filtering of the Grid columns that have their field option set.
  @Input() filterable: FilterableSettings = "menu";

  // If set to true, the user can group the Grid by dragging the column header cells.
  @Input() groupable: boolean | GroupableSettings = true;

  // Defines the height (in pixels) that is used when the scrollable option of the Grid is set.
  @Input() height = 500;

  // Specifies if the header of the grid will be hidden.
  @Input() hideHeader = false;

  // Specifies if the loading indicator of the Grid will be displayed.
  @Input() loading = false;

  /**
   * If set to true, the user can use dedicated shortcuts to interact with the Grid.
   * By default, navigation is disabled and the Grid content is accessible in the normal tab sequence.
   */
  @Input() navigable: GridNavigableSettings = true;

  // Configures the pager of the Grid
  @Input() pageable: boolean | PagerSettings = false;

  // Defines the page size used by the Grid pager.
  @Input() pageSize = 100;

  // If set to true, the user can reorder columns by dragging their header cells.
  @Input() reorderable = true;

  // If set to true, the user can resize columns by dragging the edges (resize handles) of their header cells.
  @Input() resizable = false;

  // Defines a function that is executed for every data row in the component.
  @Input() rowClass: RowClassFn = () => '';

  // Defines the row height that is used when the scrollable option of the Grid is set to virtual. Required by the virtual scrolling functionality.
  @Input() rowHeight = 36;

  // Defines the scroll mode used by the Grid.
  @Input() scrollable: ScrollMode = 'virtual';

  // Enables the row selection of the Grid.
  @Input() selectable: boolean | SelectableSettings = {
    enabled: true,
    mode: 'multiple',
    checkboxOnly: true
  };

  // Enables the sorting of the Grid columns that have their field option set.
  @Input() sortable: SortSettings = {
    mode: 'multiple',
  };

  @Input('columnConfigurations') set _columnConfigurations(configurations: Partial<ColumnConfiguration>[]) {
    this.columnConfigurations = configurations.map(
        item => ({
            ...DEFAULT_COLUMN_CONFIGURATION,
            ...item,
        })
    )
  }

  @Input('commandColumnConfiguration') set _commandColumnConfiguration(configuration: Partial<CommandColumnConfiguration>) {
    this.commandColumnConfiguration = {
      ...DEFAULT_COMMAND_COLUMN_CONFIGURATION,
      ...configuration
    }
  }

  @Input('checkboxColumnConfiguration') set _checkboxColumnConfiguration(configuration: Partial<CheckboxColumnConfiguration>) {
    this.checkboxColumnConfiguration = {
      ...DEFAULT_CHECKBOX_COLUMN_CONFIGURATION,
      ...configuration
    }
  }

  @Input() createFormGroupFn!: CreateFormGroupFn;

  @Input() quickAddFn: QuickAddFn = () => ({});

  @Input() copyFn: CopyFn = (dataItem: any) => {
    return { ...dataItem }
  };

  @Input('pdfOptions') set _pdfOptions(options: Partial<PDFOptions>) {
    this.pdfOptions = {
      ...DEFAULT_PDF_OPTIONS,
      ...options
    };
  };

  @Input('excelOptions') set _excelOptions(options: Partial<ExcelOptions>) {
    this.excelOptions = {
      ...DEFAULT_EXCEL_OPTIONS,
      ...options
    };
  };

  @Input() enableCellClickEdit = true;

  @Input() allowMultiRowEditing = true;

  @Input() commandColumnActionItems: CommandColumnActionItem[] = COMMAND_COLUMN_ACTION_ITEMS;

  // Whether to show count of rows at the grid footer
  @Input() showRowCount = true;

  // The row count label, adding "'s" in case of more than one row is handled in grid
  @Input() rowCountLabel = '';

  @Input() rowDeletableFn: RowDeletableFn = () => true;

  @Input() rowEditableFn: RowEditableFn = () => true;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  @Output() validationChange: EventEmitter<GridFormValidationResult> = new EventEmitter();

  @ViewChild('grid') grid!: KendoGridComponent;

  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  @ContentChild(forwardRef(() => GridTemplatesDirective)) gridTemplates!: GridTemplatesDirective;

  columnConfigurations: ColumnConfiguration[] = [];
  
  gridView: GridDataResult = {
    total: 0,
    data: []
  };

  selectedRows: number[] = [];

  pdfOptions: PDFOptions = DEFAULT_PDF_OPTIONS;
  excelOptions: ExcelOptions = DEFAULT_EXCEL_OPTIONS;

  checkboxColumnConfiguration = DEFAULT_CHECKBOX_COLUMN_CONFIGURATION;
  commandColumnConfiguration = DEFAULT_COMMAND_COLUMN_CONFIGURATION;

  gridState = DEFAULT_GRID_STATE;
    
  private _originalDataBackup: any[] = [];
  private _originalData: any[] = [];
  private _counter = 0;
  private _formValueChangeSubscription!: Subscription;

  constructor(private _editService: GridEditService, private _dialogService: DialogService) {}

  ngOnInit(): void {
    this._initGridState();
    this._loadGridView(this.gridState);
    this._handleFormGroupChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const gridDataChange = changes['_data'];
    if(gridDataChange && !gridDataChange.isFirstChange()) {
      this._resetGrid();
      this._initGridState();
      this._loadGridView(this.gridState);
    }
  }

  private _initGridState() {
    if(this.scrollable === 'virtual') {
      this.gridState = {
        ...this.gridState,
        skip: this.gridState.skip ?? 0,
        take: this.gridState.take ?? this.pageSize
      }
    }
  }

  // Returns count of rows currently displayed (after applying filter & data operations)
  get rowCount(): number {
    return this.gridView.total;
  }

  get isGroupable(): boolean {
    return this.groupable && !this._isGridInEditMode;
  }

  private get _isGroupingApplied(): boolean {
    return this.groupable && this.gridState.group != undefined && this.gridState.group.length > 0;
  }

  get allowGridEditing(): boolean {
    return !this._isGroupingApplied;
  }

  private get _isGridInEditMode(): boolean {
    return !!this.grid && this.grid.isEditing();
  }

  private get _isGridHasDeletedItems(): boolean {
    return this._editService.getDeletedItems().length > 0;
  }

  get isGridEditing(): boolean {
    return this._isGridInEditMode || this._isGridHasDeletedItems;
  }

  isRowDeletable(dataItem: any): boolean {
    return this.allowGridEditing && this.rowDeletableFn(dataItem);
  }

  isColumnFilterable(columnConfiguration: ColumnConfiguration): boolean {
    return columnConfiguration.filterable && !this._isGridInEditMode;
  }

  isColumnSortable(columnConfiguration: ColumnConfiguration): boolean {
    return columnConfiguration.sortable && !this._isGridInEditMode;
  }

  private _loadGridView(gridState: State): void {
    this.gridView = process(this._originalData, gridState );
  }

  isCellEditable(dataItem: any, columnConfiguration: ColumnConfiguration): boolean {
    if(dataItem.isNewItem) {
        return columnConfiguration.editableOn.add;
    }

    return columnConfiguration.editableOn.edit;
  }

  onCellClick({ dataItem, isEdited, rowIndex }: CellClickEvent) {
    if(!this.enableCellClickEdit || isEdited || this._isGroupingApplied || !this.rowEditableFn(dataItem)) {
        return;
    }

    if(this._isGridInEditMode && !this.allowMultiRowEditing) {
      return;
    }

    this._editGridRow(dataItem, rowIndex);
  }

  // Opens the grid row at given rowIndex in edit mode (Only if rowEditableFn for the given dataItem returns true)
  public editRow(dataItem: any, rowIndex: number): void {
    if(this.rowEditableFn(dataItem)) {
      this._editGridRow(dataItem, rowIndex);
    }
  }

  private _editGridRow(dataItem: any, rowIndex: number) {
    const formGroup = this.createFormGroupFn(dataItem);

    this._editService.addToUpdatedItems(dataItem.rowId, formGroup);

    this.grid.editRow(rowIndex, formGroup);
  }

  private _getGridViewData(): any[] {
    if(!this.groupable) {
      return this.gridView.data;
    }

    return this._flattenGroupedGridViewData(this.gridView.data);
  }

  private _flattenGroupedGridViewData(gridViewData: any[]): any[] {
    const result = []
    for(let item of gridViewData) {
      if(item.items) {
        result.push(...this._flattenGroupedGridViewData(item.items));
      } else {
        result.push(item);
      }
    }

    return result;
  }

  private _closeAllRows(): void {
    this._getGridViewData().forEach((item, index) => {
      this.grid.closeRow(index);
    })
  }

  private _reopenGridEditor(): void {
    this._closeAllRows();

    const gridData = this._getGridViewData();

    this._editService.getRowsInEditMode().forEach((group, rowId) => {
      const rowIndex = gridData.findIndex(item => item.rowId === rowId);
      this.grid.editRow(rowIndex, group);
    });
  }

  onDataStateChange(event: DataStateChangeEvent): void {
    this.gridState = {
      ...event,
      aggregates: this.gridState.aggregates
    };
    this.gridState.group = this.gridState.group?.map((group) => {
      return {
        ...group,
        aggregates: this.gridState.aggregates
      }
    });
    this._loadGridView(this.gridState);
    this._reopenGridEditor();
  }

  /* Quick add functionality */

  private _getQuickAddItem(): any {
    return {
      ...this.quickAddFn(),
      isNewItem: true,
      rowId: this._counter++
    }
  }

  quickAdd(count = 1): void {
    for(let i = 0; i < count; i++) {
      const item = this._getQuickAddItem();

      this._originalData.unshift(item);

      const formGroup = this.createFormGroupFn(item);

      this._editService.addToNewlyAddedItems(item.rowId, formGroup);
    }

    this._loadGridView(this.gridState);
    this._reopenGridEditor();
  }

  /* End - Quick add functionality */

  /* Copy functionality */

  private _getCopiedDataItem(dataItem: any): any {
    const formGroup = this._editService.getFormGroup(dataItem.rowId);

    let newItem = formGroup ? { ...dataItem, ...formGroup.value } : dataItem;

    newItem = this.copyFn(newItem);

    return {
      ...newItem,
      isNewItem: true,
      rowId: this._counter++
    }
  }

  copySelectedRows(): void {
    this._copyRows(this._getSelectedDataItems());
  }

  copyRow(dataItem: any) {
    this._copyRows([dataItem]);
  }

  private _copyRows(dataItems: any[]) {
    let itemsToCopy = dataItems.map(dataItem => this._getCopiedDataItem(dataItem));

    this._originalData.unshift(...itemsToCopy);
    
    itemsToCopy.forEach(dataItem => {
      const group = this.createFormGroupFn(dataItem);
      this._editService.addToNewlyAddedItems(dataItem.rowId, group);
    });

    this._loadGridView(this.gridState);
    this._reopenGridEditor();

    this.selectedRows = [];
  }

  /* End - Copy functionality */

  /* Delete Functionality */

  deleteSelectedRows(): void {
    this._deleteRows(this._getSelectedDataItems());
  }

  private _deleteRows(dataItems: any[]): void {
    dataItems.forEach(({rowId}) => {
      const index = this._originalData.findIndex(dataItem => dataItem.rowId === rowId);
      const deletedItem = this._originalData.splice(index, 1);

      if(this._editService.isNewlyAddedItem(rowId)) {
        this._editService.removeFromNewlyAddedItems(rowId);
        return;
      }

      this._editService.removeFromUpdatedItems(rowId);
      this._editService.addToDeletedItems(deletedItem[0]);
    });

    this._loadGridView(this.gridState);
    this._reopenGridEditor();
  }

  deleteRow(dataItem: any) {
    this._deleteRows([dataItem]);
  }

  /* End - Delete Functionality */

  async cancelEdit(): Promise<void> {
    if(await this._askCancelConfirmation()) {
      this._originalData = this._originalDataBackup.map((dataItem) => ({ ...dataItem }));
      this._editService.resetEditState();
      this._loadGridView(this.gridState);
      this._reopenGridEditor();
    }
  }

  onSelectionChange(): void {
    const selectedDataItems = this._getSelectedDataItems();
    this.selectionChange.emit(selectedDataItems);
  }

  exportAsPDF(): void {
    this.grid.saveAsPDF();
  }

  exportAsExcel(): void {
    this.grid.saveAsExcel();
  }

  exportAsCSV(csvFileName = 'grid.csv'): void {
    const data = this._getGridViewData();
    const titles = this.columnConfigurations.map(column => `"${column.title}"`).join(",");
    const processedData = data.map(dataItem =>

      this.columnConfigurations.map(
        column => {
          return `"${getFieldValueFromDataItemRecursively(dataItem, column.field)}"`;
        }
      ).join(",")
    );
    processedData.unshift(titles);
    const dataURI = "data:text/plain;base64," + encodeBase64(processedData.join('\n'));
    saveAs(dataURI, csvFileName);
  }

  getGroupAggregateForField(aggregates: any, fieldName: string): string {
    const item = this.gridState.aggregates.find(({ field }) => field === fieldName);;
    if(aggregates[fieldName] && item?.aggregate) {
      return `${item.label}${aggregates[fieldName][item.aggregate]}`;
    }
    return '';
  }

  getAggregatesForColumnFooter(fieldName: string): string {
    const aggregateDescriptor = this.gridState.aggregates.find(({ field }) => field === fieldName);
    if(!aggregateDescriptor) {
      return '';
    }

    // Compute aggregate for the column
    const gridData = this._getGridViewData();
    if(gridData.length === 0) {
      return '';
    }

    const latestData = gridData.map(dataItem => {
      let group = this._editService.getFormGroup(dataItem.rowId);
      if(group) {
        let mergedItem = {
          ...dataItem,
          ...group.value,
        }

        mergedItem[fieldName] = +mergedItem[fieldName];
        
        return mergedItem;
      }

      return dataItem;
    });

    const aggregates = aggregateBy(latestData, [aggregateDescriptor]);
    return `${aggregateDescriptor.label}${aggregates[fieldName][aggregateDescriptor.aggregate]}`;
  }

  // Returns column aggregates based on aggregate descriptor provided (after applying filter & data operations)
  getColumnAggregates(): any {
    // Compute aggregate for the column
    const gridData = this._getGridViewData();
    
    const latestData = gridData.map(dataItem => {
      let group = this._editService.getFormGroup(dataItem.rowId);
      let result = dataItem;
      if(group) {
        result = {
          ...dataItem,
          ...group.value,
        }        
      }

      // Converting fields to number
      this.gridState.aggregates.forEach(aggregate => {
        result[aggregate.field] = +result[aggregate.field];
      });

      return result;
    });

    return aggregateBy(latestData, this.gridState.aggregates);
  }
  
  private _getSelectedDataItems(): any[] {
    return this._originalData.filter(dataItem => this.selectedRows.includes(dataItem.rowId));
  }

  private _askCancelConfirmation(): Promise<boolean> {
    return new Promise(resolve => {
      this._dialogService.openDialog({
        title: CANCEL_CONFIRMATION_DIALOG_TITLE,
        content: CANCEL_CONFIRMATION_DIALOG_CONTENT,
        cancelAction : { text: "Keep working", value: 'no' },
        confirmAction: { text: "Cancel changes", value: "yes" }
      }, ConfirmDialogComponent).result
        .pipe(
          take(1),
          tap((result: any) => {
            resolve(result.value === 'yes');
          }))
        .subscribe()
    });
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.classList.contains('k-column-title') ||
      element.hasAttribute('show-tooltip')) &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  handleCommandActionClick(event: CommandColumnActionItem, dataItem: any) {
    switch(event.action) {
      case 'copy': this.copyRow(dataItem);
                    break;
      case 'delete': this.deleteRow(dataItem);
                      break;
    }
  }

  getEditedData() {
    const addedItems = Array.from(this._editService.getNewlyAddedItems().values()).map(group => group.value);
    const updatedItemsMap = this._editService.getUpdatedItems();
    const updatedItems: any[] = [];
    updatedItemsMap.forEach((group, rowId) => {
      updatedItems.push(this._mergeFormValuesWithOriginalData(rowId, group));
    });

    const validationResult = this.getValidationResult();

    this._subscribeToFormValueChanges();

    return {
      addedItems,
      updatedItems,
      deletedItems: this._editService.getDeletedItems(),
      validationResult
    }
  }

  private _mergeFormValuesWithOriginalData(rowId: number, formGroup: FormGroup): any {
    const dataItem = this._originalData.find(item => item.rowId === rowId);
    return {
      ...dataItem,
      ...formGroup.value
    };
  }

  editAllRows(): void {
    const data =  this._getGridViewData();

    data.forEach((dataItem, rowIndex) => {
      if(this.rowEditableFn(dataItem)) {
        this._editGridRow(dataItem, rowIndex);
      }
    });
  }

  private _resetGrid(): void {
    this._closeAllRows();
    this._editService.resetEditState();
    this.selectedRows = [];
  }

  getValidationResult(): GridFormValidationResult {
    const formGroupMaps = this._editService.getRowsInEditMode();
    const formGroups = Array.from(formGroupMaps.values());

    const isValid = formGroups.every(group => group.valid);
    if(!isValid){
      formGroups.every(group => group.markAllAsTouched())
    }
    return {
      isValid,
      errors: isValid ? [] : this._mapFormErrors(formGroups, this.columnConfigurations)
    }
  }

  private _mapFormErrors(formGroups: FormGroup[], columnConfiguratins: ColumnConfiguration[]): any {
    const fields = this._getEditableFieldList(columnConfiguratins);

    return formGroups.map(group => fields.reduce((result: any, controlName: string) => {
      return {
        ...result,
        [controlName]: group.get(controlName)?.errors
      }
    }, {}))
  }

  private _getEditableFieldList(columnConfiguratins: ColumnConfiguration[]): any {
    return columnConfiguratins
            .filter(column => (column.editableOn.add || column.editableOn.edit))
            .map(column => column.formControlName);
  }

  private _handleFormGroupChange(): void {
    this._editService.getFormGroupChange().subscribe(() => {
      this._subscribeToFormValueChanges();
    });
  }

  private _subscribeToFormValueChanges() {
    if(this._formValueChangeSubscription) {
      this._formValueChangeSubscription.unsubscribe();
    }

    const valueChanges = Array.from(this._editService.getRowsInEditMode().values()).map(
      group => group.valueChanges);
      
    this._formValueChangeSubscription = merge(...valueChanges).subscribe(() => {
      this.validationChange.emit(this.getValidationResult());
    });
  }

  ngOnDestroy(): void {
    if(this._formValueChangeSubscription) {
      this._formValueChangeSubscription.unsubscribe();
    }
  }
}
