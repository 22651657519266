<kendo-stacklayout [gap]="'24px'" [orientation]="orientation">
  <div [style.width.%]="100"> 
        <div class="tabs">
          <kendo-buttongroup selection="single">
            <button *ngFor="let tab of tabs; index as i" kendoButton [toggleable]="true" [routerLink]="tab.routeName"
              routerLinkActive="k-selected" class="k-button-outline-primary">  
                <williams-ui-platform-badge-container>
                    {{ tab.title }}
                  <williams-ui-platform-badge classList="w-position-initial" *ngIf="tab.indicatorText" [size]="'small'" [align]="{horizontal:'end'}">{{tab.indicatorText}}</williams-ui-platform-badge>
                </williams-ui-platform-badge-container>
            </button>
          </kendo-buttongroup>
        </div>
  </div>
  <div [style.width.%]="100">
      <router-outlet></router-outlet>
  </div>
</kendo-stacklayout>