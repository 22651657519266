import { Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInfo, FileRestrictions, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { BaseAccessor } from '../base/base-accessor';

@Component({
  selector: 'williams-ui-platform-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent extends BaseAccessor  {
  @Input() dropCustomText!: string;
  @Input() fileTypeCustomText!: string;
  @Input() select: string ="Choose File"
  @Output() fileSelect= new EventEmitter<any>;

  @Input() fileRestrictions: FileRestrictions = {
    allowedExtensions: [".csv"],
  };
  
  constructor(@Optional() public override controlContainer: ControlContainer) {
    
    super(controlContainer);

  }
  ngAfterViewInit() {
    this.insertIcon();
  }

  public insertIcon() {
      let selectFile = document.querySelector(".k-upload-button");

      let icon = document.createElement("span");
      icon.setAttribute("class", "k-icon k-i-upload");

      selectFile?.insertBefore(icon, selectFile?.childNodes[0]);
  }

  }