import {
  TileLayoutFlowMode as KendoTileLayoutFlowMode,
  TileLayoutGap as KendoTileLayoutGap,
  TileLayoutReorderEvent as KendoTileLayoutReorderEvent,
  TileLayoutResizeEvent as KendoTileLayoutResizeEvent
} from "@progress/kendo-angular-layout";

export declare type TileLayoutFlowMode = KendoTileLayoutFlowMode;
export declare type TileLayoutGap = KendoTileLayoutGap;
export class TileLayoutReorderEvent extends KendoTileLayoutReorderEvent{}
export class TileLayoutResizeEvent extends KendoTileLayoutResizeEvent {}
