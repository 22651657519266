import { Component } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss']
})
export class DialogContainerComponent {
  constructor() { }
}
