<kendo-card [orientation]="orientation" [width]="width" [class]="classList">
  <kendo-card-header *ngIf="showHeader">
    <ng-content select="[header]"></ng-content>
  </kendo-card-header>
  <kendo-card-body *ngIf="showBody">
    <ng-content></ng-content>
  </kendo-card-body>
  <kendo-card-footer *ngIf="showFooter">
    <ng-content select="[footer]"></ng-content>
  </kendo-card-footer>
  <kendo-card-actions
    *ngIf="showActions"
    [actions]="actions"
    [layout]="actionsLayout"
    [orientation]="actionsOrientation"
    (action)="onAction($event)"
  >
    <ng-content select="[actions]"></ng-content>
  </kendo-card-actions>
</kendo-card>
