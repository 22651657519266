import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'williams-ui-platform-list-box',
  templateUrl: './list-box.component.html',
  styleUrls: ['./list-box.component.scss']
})

export class ListComponent {
  @Input() list: any;
  @Output() listValue = new EventEmitter<string>();

  public getName(item: any): string {
    return item.title;
  }

  public handleActionClick(val: any): void {
    this.listValue.emit(val);
  }
}