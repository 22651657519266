import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ColumnConfiguration } from "../models/column";
import { getFieldValueFromDataItemRecursively } from "../utils";

@Component({
  selector: "williams-ui-platform-grid-cell",
  templateUrl: "./grid-cell.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridCellComponent {
  @Input() columnConfiguration!: ColumnConfiguration;
  @Input() dataItem: any;

  get fieldValue(): string {
    return getFieldValueFromDataItemRecursively(this.dataItem, this.columnConfiguration.field);
  }
}
