<p>Criteria</p>
<form class="k-form" [formGroup]="criteriaDetails">
    <kendo-formfield>
        <kendo-label for="company" text="Company"></kendo-label>
        <kendo-dropdownlist #company formControlName="company" [data]="comanyList" value="Sapient">
        </kendo-dropdownlist>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label for="selectDate" text="Date"></kendo-label>
        <kendo-datepicker #selectDate formControlName="selectDate" [min]="min" [max]="max" required>
        </kendo-datepicker>
    </kendo-formfield>
</form>

               
  